export const dtoWishCreate = (data: any) => {
  console.log("!!!!!!!!!!!!!!!!! CITY", data);

  const formData: any = {
    title: data.wishName,
    description: JSON.stringify(data.wishDesc),
    category: data.wishCategory,
    tags: data.tags,
    cities: data.city,
    max_performer_amount: data.memberAmount,
  };

  if (data.wishSubCategory) formData.sub_category = data.wishSubCategory;

  return formData;
};

export const dtoWishlists = (param: any) => {
  // console.log("DTO WISHLISTS IN", param);
  const obj: any = {};
  if (param.city) obj.city = param.city;
  if (param.name) obj.q = param.name;
  return obj;
};

export const dtoWishPaginator = (param: any) => {
  // console.log("DTO WISHLISTS IN", param);
  const obj: any = {};
  if (param.city) obj.city = param.city;
  if (param.name) obj.q = param.name;
  if (param.perPage) obj.per_page = param.perPage;
  if (param.page) obj.page = param.page || 1;
  return obj;
};

export const dtoWishEdit = (data: any) => {
  console.log("!!!!!!!!!!!!!! dtoWishEdit", data);
  const formData: any = {
    title: data.wishName,
    description: JSON.stringify(data.wishDesc),
    category: data.wishCategory.value,
    tags: data.tags,
    cities: data.city,
    max_performer_amount: data.memberAmount.value,
  };

  if (data.wishSubCategory && data.wishSubCategory.value)
    formData.sub_category = data.wishSubCategory.value;

  return formData;
};
