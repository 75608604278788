import React from "react";
import { FormProfileAvatar } from "components/form";
import { action, useDsp } from "app/store";
import { messages, avatarTypes, avatarExts } from "app/constants";
import { useUrl, convertHeif } from "libheif-web";
import Resizer from "react-image-file-resizer";

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const WrapperProfileAvatar = () => {
  const dispatch = useDsp();
  const inputHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const name = file.name.toLowerCase() || "file.file";
      const type = file.type.toLowerCase() || "unknown";
      const ext = `.${name.split(".").pop()}` || "unknown";
      const size = file.size || 0;

      let isValid = true;
      if (!avatarTypes.includes(type)) isValid = false;
      if (!avatarExts.includes(ext)) isValid = false;
      if (size > 5000000) isValid = false;
      if (isValid) {
        if (ext === ".heic") {
          // PRELOADER
          dispatch(
            action.mProcessSetData({
              title: "Обработка",
              message: "Конвертация HIEC в JPG",
            })
          );
          dispatch(action.mProcessShow());
          // HIEC
          useUrl("assets/scripts/libheif.min.js");
          const imageJPG = await convertHeif(file, "filename.jpg", "image/jpg");
          const imageMin: any = await resizeFile(imageJPG);
          if (imageMin) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              dispatch(action.mAvaCropSetData(reader.result?.toString() || ""));
              dispatch(action.mProcessHide());
              dispatch(action.mAvaCropShow());
            });
            reader.readAsDataURL(imageMin);
          }
        } else {
          // JPG PNG
          const imageMin: any = await resizeFile(file);
          if (imageMin) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              dispatch(action.mAvaCropSetData(reader.result?.toString() || ""));
              dispatch(action.mAvaCropShow());
            });
            reader.readAsDataURL(imageMin);
          }
        }
      } else {
        dispatch(action.mInfoShow());
        dispatch(
          action.mInfoSetData({ message: messages.avatar.modalMessage })
        );
      }
    }
  };
  return <FormProfileAvatar onInput={inputHandler} />;
};
