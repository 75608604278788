import axios from "axios";
import { urls } from "app/constants";

declare global {
  interface Window {
    tokenAcc: string;
  }
}

const baseurl = process.env.REACT_APP_API_BASE_URL;

// AXIOS BASE
export const axiosBase = axios.create({
  baseURL: baseurl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// AXIOS SECURE
const axiosSecure = axios.create({
  baseURL: baseurl,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${window.tokenAcc}`,
    // Authorization: `Bearer ${localStorage.getItem("tokenAcc")}`,
  },
});

// const axiosSecureMultipart = axios.create({
//   baseURL: baseurl,
//   withCredentials: true,
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "multipart-form-data",
//     Authorization: `Bearer ${window.tokenAcc}`,
//     // Authorization: `Bearer ${localStorage.getItem("tokenAcc")}`,
//   },
// });

axiosSecure.interceptors.request.use(
  (config: any) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${window.tokenAcc}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosSecure.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;

    if (response.status !== 401) return Promise.reject(error);

    try {
      // console.log("ERROR 401 - TRY UPDATE ACCESS TOKEN");
      const { data, status } = await axiosBase({
        url: urls.token,
        method: "post",
        data: { refresh: localStorage.getItem("tokenRef") || "" },
      });

      if (status === 200 && data.access && data.refresh) {
        // console.log("UPDATE TOKEN", data);

        localStorage.setItem("tokenRef", data.refresh);
        window.tokenAcc = data.access;
        // localStorage.setItem("tokenAcc", data.access);

        config.headers.Authorization = `Bearer ${data.access}`;
        const resp = await axios(config);
        return resp;
      }
      return Promise.reject(error);
    } catch (err) {
      return Promise.reject(error);
    }
  }
);

export { axiosSecure };
