import React from "react";
import type { RootState } from "app/store";
import { useSlc } from "app/store";
import RedirectTo from "components/RedirectTo/RedirectTo";
import { WrapperMain } from "app/wrappers";

export const Main = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);
  if (isAuth) return <WrapperMain />;
  return <RedirectTo path="/sign-in" />;
};
