import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { ReactComponent as Logo } from "assets/images/logo-site.svg";
import { Button } from "elements/button";
import { profileLinks } from "app/constants";
import avatarDefault from "assets/images/avatar-default.svg";
import { HeaderNotify } from "components/headerNotify";
import iconNotify from "assets/images/icon-exit.svg";
// import logoBrand from "assets/images/logo-brand.png";

const baseurl = process.env.REACT_APP_API_BASE_URL;

export const HeaderMiddle = () => {
  const dispatch = useDsp();
  const currRoute = useLocation().pathname;
  const isAuth = useSlc((state: RootState) => state.user.isAuth);
  const user = useSlc((state: RootState) => state.user);
  const count = useSlc((state: RootState) => state.dataProfileWishes);
  const avatar = user.image;

  const logout = () => {
    dispatch(
      action.userSet({
        isAuth: false,
        name: "",
        email: "",
        number: "",
        phone: "",
      })
    );
    // localStorage.removeItem("tokenAcc");
    localStorage.removeItem("tokenRef");
  };

  return (
    <header className="header--middle" role="headerMiddle">
      <div className="header--middle__container container">
        {profileLinks.map((el: any) => el.route).includes(currRoute) && (
          <h2 className="header--middle__title">Личный кабинет</h2>
        )}
        {!profileLinks.map((el: any) => el.route).includes(currRoute) && (
          <div className="header--middle__logo" role="headerMiddleLogo">
            <NavLink className="header--middle__logo--link" to="./">
              <Logo />
            </NavLink>
          </div>
        )}

        {isAuth && (
          <>
            <div className="header--middle__stat header--middle__stat--done">
              Исполнено мной: <span>{user.balls || 0}</span>
            </div>
            <div className="header--middle__stat header--middle__stat--published">
              Опубликовано мной: <span>{count.length}</span>
            </div>
          </>
        )}

        {isAuth && (
          <Button
            className="header--middle__action"
            type="button"
            onClick={() => dispatch(action.mWishCreateShow())}
            role="headerMiddleWishCreateButton"
          >
            Начать
          </Button>
        )}

        <NavLink
          className="header--top__personal-avatar"
          to="/profile"
          role="headerTopAvatar"
        >
          <img src={avatar ? baseurl + avatar : avatarDefault} alt="" />
        </NavLink>
        {isAuth && <HeaderNotify />}

        {isAuth && (
          <button
            className="header--middle__exit"
            type="button"
            onClick={() => logout()}
          >
            <img src={iconNotify} alt="" />
          </button>
        )}
      </div>
    </header>
  );
};
