import React from "react";
import Headline from "components/headline/Headline";
import WishTile from "components/wishTile/WishTile";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "components/swiperNavigation/swiperNavigation";
import CardTile from "components/cardTile/cardTile";
import CardIcon from "components/cardIcon/cardIcon";
import { useSlc, RootState, action, useDsp } from "app/store";
import { ReactComponent as IconStaf } from "assets/images/32pt_staf.svg";
import CardContent from "components/cardContent/cardContent";
import TextLink from "components/textLink/textLink";
import "swiper/css";

type props = {
  title?: string;
  performWish: any;
  linkToCategory?: string;
  data: {
    uuid: any;
    title: string;
    avatar: string;
    name: string;
    location: string;
    tags: any;
    hashtags: any;
  }[];
};

export const WishlistSlider = ({
  title,
  data,
  performWish,
  linkToCategory = "/",
}: props) => {
  const dispatch = useDsp();
  const user = useSlc((state: RootState) => state.user);

  return (
    <div className="wishlist-slider">
      <Headline className="headline_lg wishlist-slider__title">
        {title}
      </Headline>
      <Swiper
        className="wishlist-slider__list"
        modules={[Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView="auto"
        slideToClickedSlide={false}
        navigation={{
          prevEl: ".wishlist-slider__arrow--prev",
          nextEl: ".wishlist-slider__arrow--next",
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: "active",
          bulletClass: "swiper--dots__item",
          el: ".wishlist-slider__dots",
          renderBullet(index, className) {
            return `<span class="${className}"></span>`;
          },
        }}
      >
        {data.map((el: any) => {
          const cartTags = [{ text: el.category.name, type: "" }];
          if (el.sub_category === "small_deal")
            cartTags.push({ text: "0.5 баллов", type: "" });
          if (el.sub_category === "big_deal")
            cartTags.push({ text: "1 балл", type: "" });

          return (
            <SwiperSlide key={el.uuid} className="wish--tile--slide">
              {user.uuid !== el.author.uuid && (
                <WishTile
                  key={el.uuid}
                  title={el.title}
                  avatar={el.author.image}
                  name={`${el.author.name} `}
                  location={el.author.city.name}
                  tags={cartTags}
                  hashtags={el.tags}
                  // BUTTONS
                  btnDetailShow
                  btnActionShow
                  linkToProfile={`/user/${el.author.uuid}`}
                  // ACTIONS
                  onDetailClick={() => {
                    dispatch(
                      action.mWishDetailsDataSet({
                        uuid: el.uuid,
                        title: "Дело коллеги",
                        name: el.title,
                        description: el.description,
                        category: el.category.name,
                        city: el.cities.map((c: any) => c.name).join(" "),
                        maxPerformers: el.max_performer_amount,
                        status: el.status,
                      })
                    );
                    dispatch(action.mWishDetailsShow());
                  }}
                  onActionClick={() => performWish(el.uuid)}
                />
              )}

              {user.uuid === el.author.uuid && (
                <WishTile
                  key={el.uuid}
                  title={el.title}
                  avatar={el.author.image}
                  name={`${el.author.name} `}
                  location={el.author.city.name}
                  tags={[{ text: el.category.name, type: "" }]}
                  hashtags={el.tags}
                  // BUTTONS
                  linkToProfile={`/user/${el.author.uuid}`}
                />
              )}
            </SwiperSlide>
          );
        })}

        <SwiperSlide className="wish--tile--slide wish--tile--slide_end">
          <CardTile autoheight={false}>
            <CardIcon>
              <IconStaf />
            </CardIcon>
            <CardContent
              title="Посмотри добрые дела остальных коллег!"
              descr=""
            />

            <TextLink
              className="card--tile__link"
              arrow
              url={linkToCategory}
              weight={500}
            >
              Перейти на доску добрых дел
            </TextLink>
          </CardTile>
        </SwiperSlide>

        <SwiperNavigation
          className="wishlist-slider__navigation"
          arrowPrev="wishlist-slider__arrow--prev"
          arrowNext="wishlist-slider__arrow--next"
          dots="wishlist-slider__dots"
        />
      </Swiper>
    </div>
  );
};
