import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "components/swiperNavigation/swiperNavigation";
import { Button } from "components/button";
import imageIntro1 from "assets/images/image-information-1@2x.jpg";
import imageIntro1Mob from "assets/images/image-information-1-mob@2x.jpg";
import imageIntro2 from "assets/images/image-information-2@2x.jpg";
import imageIntro2Mob from "assets/images/image-information-2-mob@2x.jpg";
import imageIntro4 from "assets/images/image-information-4@2x.jpg";
import imageIntro4Mob from "assets/images/image-information-4-mob@2x.jpg";
import imageIntro5 from "assets/images/image-information-5@2x.jpg";
import imageIntro5Mob from "assets/images/image-information-5-mob@2x.jpg";
import imageIntro6 from "assets/images/image-information-6@2x.jpg";
import imageIntro6Mob from "assets/images/image-information-6-mob@2x.jpg";
import iconArrow from "assets/images/iconIntroArrow.svg";
import imageAddWish from "assets/images/image-information-add-wish@2x.png";

export const ModalIntro = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mIntro)}
      onHide={() => dispatch(action.mIntroHide())}
      className="modal--intro"
      dialogClassName="modal-dialog--intro"
      contentClassName="modal-content--intro"
      bodyClassName="modal-body--intro"
      backdrop="static"
    >
      <Swiper
        className="swiper-intro"
        modules={[Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView="auto"
        slideToClickedSlide
        navigation={{
          prevEl: ".new--wishlist__arrow--prev",
          nextEl: ".new--wishlist__arrow--next",
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: "active",
          bulletClass: "swiper--dots__item",
          el: ".new--wishlist__dots",
          renderBullet(index, className) {
            return `<span class="${className}"></span>`;
          },
        }}
      >
        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-1">
          <picture>
            <source srcSet={imageIntro1} media="(min-width: 769px)" />
            <source srcSet={imageIntro1Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro1}
              alt=""
            />
          </picture>

          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Привет, меня зовут Кружок, а это платформа для помощи,
              волонтёрства и&nbsp;общения.
            </h3>
            <p className="swiper-intro__text">
              Здесь ты сможешь найти единомышленников для добрых дел, поддержать
              коллег и&nbsp;научиться новому.
            </p>
            <p className="swiper-intro__text">
              В нашем пространстве нет коммерческих предложений или&nbsp;платных
              услуг. Мы делаем добрые дела безвозмездно.
            </p>
            <p className="swiper-intro__text">
              <b>Потому что МегаФон – это люди.</b>
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-2">
          <picture>
            <source srcSet={imageIntro2} media="(min-width: 769px)" />
            <source srcSet={imageIntro2Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro2}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Укажи информацию о себе, поделись интересами и&nbsp;увлечениями,
              обязательно загрузи фотографию, чтобы коллеги могли тебя узнать.
            </h3>
            <p className="swiper-intro__text">
              В профиле ты найдёшь события, в&nbsp;которых уже участвуешь
              и&nbsp;поделиться своими желаниями.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-3">
          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Если хочешь, чтобы коллеги присоединились к&nbsp;твоему доброму
              делу или&nbsp;исполнили желание, оставь заявку.
            </h3>
            <div className="swiper-intro__step-1">
              <p>1. Нажми на кнопку «Начать»</p>
              <img src={iconArrow} alt="" />
              <Button
                onClick={() => {
                  dispatch(action.mIntroHide());
                  dispatch(action.mWishCreateShow());
                }}
              >
                Начать
              </Button>
            </div>
            <div className="swiper-intro__step-2">
              <img src={imageAddWish} alt="" />
              <p>
                2. Заполни карточку. Укажи название, описание, выбери подходящую
                категорию, город и&nbsp;количество участников
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-4">
          <picture>
            <source srcSet={imageIntro4} media="(min-width: 769px)" />
            <source srcSet={imageIntro4Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro4}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content">
            <h2 className="swiper-intro__h2">
              Когда твоя заявка пройдёт модерацию, её увидят коллеги
            </h2>
            <p className="swiper-intro__text">
              Карточка с добрым делом появится в&nbsp;подходящем разделе.
              Присоединиться к нему могут один или несколько человек,
              в&nbsp;зависимости от выбранных условий.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-5">
          <picture>
            <source srcSet={imageIntro5} media="(min-width: 769px)" />
            <source srcSet={imageIntro5Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro5}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content">
            <h3 className="swiper-intro__h3">
              Если решил помочь коллеге, зайди в&nbsp;нужный раздел
              и&nbsp;выбери карточку дела или желания
            </h3>
            <p className="swiper-intro__text">
              Нажми на кнопку «Помочь» и&nbsp;переходи в&nbsp;чат
              с&nbsp;автором, чтобы обсудить детали. Когда дело будет сделано
              снова зайди в&nbsp;чат, приложи фотоподтверждение и&nbsp;смени
              статус на «Выполнено».
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-intro__slide swiper-intro__slide-6">
          <picture>
            <source srcSet={imageIntro6} media="(min-width: 769px)" />
            <source srcSet={imageIntro6Mob} media="(min-width: 100px)" />
            <img
              className="swiper-intro__slide-top-image"
              src={imageIntro6}
              alt=""
            />
          </picture>
          <div className="swiper-intro__content swiper-intro__content-6">
            <h3 className="swiper-intro__h3">
              Если для твоего доброго дела нужна помощь Кружка, оставь нам
              сообщение
            </h3>
            <Button
              className="swiper-intro__button-6"
              onClick={() => {
                dispatch(action.mIntroHide());
                dispatch(action.mFeedbackShow());
              }}
            >
              Обратная связь
            </Button>
          </div>
        </SwiperSlide>

        <SwiperNavigation
          className="swiper-intro__navigation"
          arrowPrev="new--wishlist__arrow--prev"
          arrowNext="new--wishlist__arrow--next"
          dots="new--wishlist__dots"
        />
      </Swiper>
    </ModalBasic>
  );
};
