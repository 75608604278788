import React, { FC } from 'react';
import clsx from 'clsx';

type props = {
  className?: string,
}

const SwiperDots: FC<props> = (props) => {
  const classes = clsx('swiper--dots', props.className);

  return (
    <div className={classes}></div>
  )
}

export default SwiperDots;
