import React from "react";
import { useDsp } from "app/store";
import { apiWishPerform } from "app/api";
import { SectionUserWishesDone } from "blocks/profile";
import { useNavigate } from "react-router-dom";

export const WrapperUserWishesDone = () => {
  const navigate = useNavigate();
  const dispatch = useDsp();

  // const performWish = (uuid: string) => {
  //   apiWishPerform(uuid, dispatch, navigate);
  // };
  return (
    <SectionUserWishesDone
    // performWish={performWish}
    />
  );
};
