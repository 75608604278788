import React from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import RSelect, { components } from "react-select";
import { Scrollbar } from "components/scrollbar/";
import { ReactComponent as IconDefault } from "assets/images/icon-select-arrow.svg";

type props = {
  prefix?: string;
  className?: string;
  label?: string;
  name: string;
  placeholder: string;
  options: any[];
  defaultValue?: any;
  id: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: any;
  isSearchable?: boolean;
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <IconDefault />
  </components.DropdownIndicator>
);

const Menu = (props: any) => {
  const count = props.options.length;
  return (
    <>
      {count < 4 && (
        <components.Menu className="autoheight" {...props}>
          {props.children}
        </components.Menu>
      )}
      {count > 3 && (
        <components.Menu {...props}>
          <Scrollbar>{props.children}</Scrollbar>
        </components.Menu>
      )}
    </>
  );
};

export const SelectDefault = ({
  prefix = "form-select",
  className,
  name,
  placeholder,
  label,
  disabled,
  options,
  defaultValue,
}: props) => (
  <Field name={name}>
    {({ field }: any) => (
      <BSform.Group className="form-group">
        {label && <BSform.Label>{label}</BSform.Label>}

        <RSelect
          // menuIsOpen={true}
          name={name}
          placeholder={placeholder}
          className={className}
          classNamePrefix={prefix}
          options={options}
          defaultValue={defaultValue}
          components={{ DropdownIndicator, Menu }}
          captureMenuScroll={false}
          menuShouldScrollIntoView={false}
          isSearchable={false}
          isDisabled={disabled}
          onChange={(e: any) => {
            const event = {
              target: {
                name,
                value: e,
              },
            };
            field.onChange(event);
            // if (onChange) onChange(e);
          }}
        />
        <ErrorMessage name={name} component="div">
          {(msg) => <div className="input__error-text">{msg}</div>}
        </ErrorMessage>
      </BSform.Group>
    )}
  </Field>
);
