import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { actionToken, reducerToken } from "./_token";
import { actionUser, reducerUser } from "./_user";
import { actionModal, reducerModal } from "./_modal";
import { actionData, reducerData } from "./_data";
import { actionChat, reducerChat } from "./_chat";
import { actionFilter, reducerFilter } from "./_filter";
import { actionFilterRating, reducerFilterRating } from "./_filterRating";
import { actionRecovery, reducerRecovery } from "./_recovery";
import { actionWishCategories, reducerWishCategories } from "./_wishCategories";
import { actionProfile, reducerProfile } from "./_profile";
import { actionPreloader, reducerPreloader } from "./_preloader";
import { actionGallery, reducerGallery } from "./_gallery";
import { actionCatalog, reducerCatalog } from "./_catalog";
import { actionNotify, reducerNotify } from "./_notify";

export const action = {
  ...actionToken,
  ...actionUser,
  ...actionModal,
  ...actionData,
  ...actionChat,
  ...actionFilter,
  ...actionFilterRating,
  ...actionRecovery,
  ...actionWishCategories,
  ...actionProfile,
  ...actionPreloader,
  ...actionGallery,
  ...actionCatalog,
  ...actionNotify,
};

export const store = configureStore({
  reducer: {
    ...reducerToken,
    ...reducerUser,
    ...reducerModal,
    ...reducerData,
    ...reducerChat,
    ...reducerFilter,
    ...reducerFilterRating,
    ...reducerRecovery,
    ...reducerWishCategories,
    ...reducerProfile,
    ...reducerPreloader,
    ...reducerGallery,
    ...reducerCatalog,
    ...reducerNotify,
  },
  devTools: true,
});

export const useDsp = useDispatch;
export const useSlc = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
