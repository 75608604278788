import { urls } from "app/constants";
import { axiosSecure } from "./_api";

export const apiChatUsers = (users: any[], success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.chatUser,
    method: "post",
    data: { uids: users.map((user: any) => user.user) },
  })
    .then(success)
    .catch(unsuccess);
};

export const apiGlobalChat = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.chatGlobal,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};
