import React from "react";
import { useSlc, RootState } from "app/store";
import { profileLinks } from "app/constants";
import { SubNav } from "components/nav";
import { WrapperProfileWishes } from "app/wrappers";
import RedirectTo from "components/RedirectTo/RedirectTo";

export const Wishes = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);

  if (isAuth)
    return (
      <>
        <SubNav links={profileLinks} />
        <WrapperProfileWishes />
      </>
    );
  return <RedirectTo path="/sign-in" />;
};
