import React from "react";
import { NavLink } from "react-router-dom";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import Headline from "components/headline/Headline";
import RedirectTo from "components/RedirectTo/RedirectTo";
import { WrapperReg } from "app/wrappers";

export const Registration = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);

  if (!isAuth)
    return (
      <div className="authorization authorization_reg bg-decoration">
        <div className="bg-decoration__login" />
        <div className="container">
          <div className="authorization__wrapper">
            <Headline className="authorization__headline" variant="xl">
              Добро пожаловать в&nbsp;Кружок!
            </Headline>

            <nav className="authorization__nav">
              <NavLink className="authorization__nav--link" to="/sign-up">
                Регистрация
              </NavLink>
              <NavLink className="authorization__nav--link" to="/sign-in">
                Вход
              </NavLink>
            </nav>
            <WrapperReg />
          </div>
        </div>
      </div>
    );
  return <RedirectTo path="/" />;
};
