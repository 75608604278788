import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";

export const Scrollbar = ({ children, className }: any) => (
  <PerfectScrollbar
    className={clsx("scrollbars__main-container", className)}
    options={{
      suppressScrollX: false,
      wheelPropagation: false,
      wheelSpeed: 0.5,
    }}
    onTouchMove={(event) => {
      // event.preventDefault();
      event.stopPropagation();
    }}
    onDrag={(event) => {
      // event.preventDefault();
      event.stopPropagation();
    }}
    onScroll={(event) => {
      // event.preventDefault();
      event.stopPropagation();
    }}
  >
    {children}
  </PerfectScrollbar>
);
