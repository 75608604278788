import React, { useState, useEffect } from "react";
import { apiGetArticles } from "app/api";
import { useNavigate } from "react-router-dom";
import { SectionArticles } from "blocks";

export const WrapperArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLosding] = useState(true);

  useEffect(() => {
    const success = ({ data }: any) => {
      if (data && data.length > 0) {
        setArticles(data);
        setLosding(false);
      }
    };
    const unsuccess = (error: any) => {
      console.log(error);
    };
    apiGetArticles(success, unsuccess);
  }, []);

  return <SectionArticles loading={loading} articles={articles} />;
};
