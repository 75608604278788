import { createAction, createReducer } from "@reduxjs/toolkit";

const fRatingShow = createAction("fRatingShow");
const fRatingHide = createAction("fRatingHide");

const fRatingIsShow = createReducer(false, (builder) => {
  builder.addCase(fRatingShow, (state, action) => true);
  builder.addCase(fRatingHide, (state, action) => false);
});

export const actionFilterRating = {
  fRatingShow,
  fRatingHide,
};

export const reducerFilterRating = {
  fRatingIsShow,
};
