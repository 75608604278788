import React from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import AsyncSelect from "react-select/async";
import { apiCity } from "app/api";
import { components } from "react-select";
import { Scrollbar } from "components/scrollbar/";
import { ReactComponent as IconDefault } from "assets/images/icon-select-arrow.svg";

type props = {
  id: string;
  name: string;
  label?: string;
  placeholder: string;
  cssPrefix?: string;
  variant?: string;
};

const NoOptionsMessage = (config: any) => (
  <components.NoOptionsMessage {...config}>
    <span>Введи название города</span>
  </components.NoOptionsMessage>
);

const LoadingMessage = (config: any) => (
  <components.LoadingMessage {...config}>
    <span>Поиск...</span>
  </components.LoadingMessage>
);

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <IconDefault />
  </components.DropdownIndicator>
);

const LoadingIndicator = () => <span></span>;

const Menu = (props: any) => {
  const count = props.options.length;
  if (count < 4)
    return (
      <components.Menu className="autoheight" {...props}>
        {props.children}
      </components.Menu>
    );
  return (
    <components.Menu {...props}>
      <Scrollbar>{props.children}</Scrollbar>
    </components.Menu>
  );
};

export const SelectCity = ({
  id,
  name,
  label,
  placeholder,
  cssPrefix = "form-select",
}: props) => (
  <Field name={name}>
    {({ field }: any) => (
      <BSform.Group className="form-group">
        {label && <BSform.Label>{label}</BSform.Label>}

        <div className="form-control-relative-wrapper">
          <AsyncSelect
            // menuIsOpen={true}
            isMulti
            id={id}
            name={name}
            placeholder={placeholder}
            components={{
              NoOptionsMessage,
              LoadingMessage,
              LoadingIndicator,
              DropdownIndicator,
              Menu,
            }}
            className="form-select-container"
            classNamePrefix={cssPrefix}
            cacheOptions
            loadOptions={apiCity}
            captureMenuScroll={false}
            menuShouldScrollIntoView={false}
            onChange={(e: any) => {
              const event = {
                target: {
                  name,
                  value: e.map((el: any) => el.value),
                },
              };
              field.onChange(event);
            }}
          />
        </div>

        <ErrorMessage name={name} component="div">
          {(msg) => <div className="input__error-text">{msg}</div>}
        </ErrorMessage>
      </BSform.Group>
    )}
  </Field>
);
