import React, { useEffect, useState } from "react";
import "./ArticleTile.scss";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import { apiGetArticleImage } from "app/api";

type props = {
  article: any;
};

export const ArticleTile = ({ article }: props) => {
  console.log(" +++++ ", article);

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const success = ({ data }: any) => {
      if (data && data.source_url) {
        setImageUrl(data.source_url);
      }
    };
    const unsuccess = (error: any) => {
      console.log(error);
    };
    if (article && article.featured_media) {
      apiGetArticleImage(article.featured_media, success, unsuccess);
    }
  }, []);

  return (
    <NavLink className="article-tile" to={`/article/${article.id}`}>
      <div className="article-tile__image">
        {imageUrl && <img src={imageUrl} alt="" />}
      </div>
      <div className="article-tile__body">
        <p>{article.title.rendered}</p>
        <span className="article-tile__link">
          Читать
          <IconArrowRight />
        </span>
      </div>
    </NavLink>
  );
};
