import { createAction, createReducer } from "@reduxjs/toolkit";

// GALLERY LIST
const galleryListSet = createAction<any>("galleryListSet");
const galleryList = createReducer([], (builder) => {
  builder.addCase(galleryListSet, (state, action) => action.payload);
});

// GALLERY NUMBER
const mGalleryViewNumberSet = createAction<any>("mGalleryViewNumberSet");
const mGalleryViewNumber = createReducer(0, (builder) => {
  builder.addCase(mGalleryViewNumberSet, (state, action) => action.payload);
});

export const actionGallery = {
  galleryListSet,
  mGalleryViewNumberSet,
};

export const reducerGallery = {
  galleryList,
  mGalleryViewNumber,
};
