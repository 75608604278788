// import React from "react";
// import { useSlc, action, useDsp, RootState } from "app/store";
// import { FormProfileWishesFilter } from "components/form";
// import WishTile from "components/wishTile/WishTile";
// import { Button } from "components/button";
// import { LoaderMini } from "components/loader";
// import ImageEmpty from "assets/images/image-profile-wishes-empty@2x.png";
// import ImageMobileEmpty from "assets/images/image-mobile-profile-wishes-empty@2x-min.png";
// import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
// import { ReactComponent as IconStaf } from "assets/images/32pt_staf.svg";
// import { path } from "ramda";
// import { LinkActionBack } from "components/link";

import React from "react";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { FormProfilePerformFilter } from "components/form";
import { Link, useParams } from "react-router-dom";
import WishTile from "components/wishTile/WishTile";
import { useApiUserWishesDone } from "app/hooks";
import { LoaderMini } from "components/loader";
import ImageEmpty from "assets/images/image-profile-perform-empty@2x.png";
import ImageMobileEmpty from "assets/images/image-mobile-profile-perform-empty@2x-min.png";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import { ReactComponent as IconHands } from "assets/images/32pt_hands.svg";
import { LinkActionBack } from "components/link";
import { path } from "ramda";

// export const SectionUserWishesDone = () => {
//   const dispatch = useDsp();
//   const { id }: any = useParams() || "";
//   const reload = useSlc((state: RootState) => state.reloadMain);
//   const { loading, data }: any = useApiUserWishesDone(id, reload);

//   const wishList: any[] = path(["data"], data) || [];

//   if (loading)
//     return (
//       <section className="profile-wishes">
//         <LoaderMini />
//       </section>
//     );

//   return (
//     <section
//       className="profile-wishes profile-wishes--wishes bg-decoration"
//       role="sectionProfileWishes"
//     >
//       <div className="bg-decoration__wrapper" />

//       <div className="profile-wishes__wrapper">
//         <div className="profile-wishes__menu-back">
//           <LinkActionBack to={`/user/${id}`}>Назад</LinkActionBack>
//         </div>

//         <div className="profile__wishes">
//           <div className="profile__card-icon">
//             <IconStaf />
//           </div>
//           <h2 className="profile__card-title">Дела коллеги</h2>
//           <p className="profile__card-subtitle">Помоги своему коллеге!</p>
//         </div>

//         <div className="profile-wishes__contaiter">
//           <div className="profile-wishes__tile-list">
//             {wishList.map((el: any) => {
//               const tags: any = [{ text: el.category.name, type: "" }];

//               if (el.status === "done") {
//                 tags.unshift({
//                   text: "Исполнен",
//                   type: "done",
//                 });
//               }

//               return (
//                 <WishTile
//                   key={el.uuid}
//                   className="wishlist-paginator__tile"
//                   title={el.title}
//                   avatar={el.author.image}
//                   name={`${el.author.name}`}
//                   location={el.author.city.name}
//                   tags={tags}
//                   hashtags={el.tags}
//                   // BUTTONS
//                   btnDetailShow
//                   btnActionShow={el.status === "published"}
//                   // btnDetailLink={`/user/wishes/${el.uuid}`}
//                   linkToProfile={`/user/${el.author.uuid}`}
//                   // ACTIONS
//                   onDetailClick={() => {
//                     dispatch(
//                       action.mWishDetailsDataSet({
//                         uuid: el.uuid,
//                         title: "Дело коллеги",
//                         name: el.title,
//                         description: el.description,
//                         category: el.category.name,
//                         city: el.city.name,
//                         maxPerformers: el.max_performer_amount,
//                         status: el.status,
//                         contracts: el.contracts,
//                       })
//                     );
//                     dispatch(action.mWishDetailsShow());
//                   }}
//                 />
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

export const SectionUserWishesDone = () => {
  const dispatch = useDsp();

  const { id }: any = useParams() || "";
  // const loading = useSlc((state: RootState) => state.loaderProfilePrfs);
  // const data = useSlc((state: RootState) => state.dataProfilePrfs);
  const reload = useSlc((state: RootState) => state.reloadMain);
  const { loading, data }: any = useApiUserWishesDone(id, reload);
  const wishes: any[] = path(["data", "results"], data) || [];

  console.log("WISHES DONE", wishes);

  // const filter = useSlc((state: RootState) => state.filterProfilePrfs);
  // const user = useSlc((state: RootState) => state.user);

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  if (wishes.length > 0)
    return (
      <section
        className="profile-wishes profile-wishes--porforms bg-decoration"
        role="sectionProfilePerformers"
      >
        <div className="bg-decoration__wrapper bg-decoration__wrapper_profile" />
        <div className="profile-wishes__wrapper">
          <div className="profile-wishes__menu-back">
            <LinkActionBack to={`/user/${id}`}>Назад</LinkActionBack>
          </div>

          <div className="profile__perform">
            <div className="profile__card-icon">
              <IconHands />
            </div>
            <h2 className="profile__card-title">Выполненные дела</h2>
            <button
              className="btn-reset button-action-forward profile__card-button"
              type="button"
            >
              {/* <Link to="/wishlists">
                <span>Выбрать дело</span>
              </Link> */}
              {/* <IconArrowRight /> */}
            </button>
          </div>
          <div className="profile-wishes__contaiter">
            <form className="profile-wishes__filter" />
            <div className="profile-wishes__tile-list">
              {wishes.map((el: any) => {
                console.log("WISH DONE", el);
                return (
                  <WishTile
                    key={el.uuid}
                    className="wishlist-paginator__tile"
                    title={el.title}
                    avatar={el.author.image}
                    name={`${el.author.name} `}
                    location={el.author.city.name}
                    // tags={tags}
                    hashtags={el.tags}
                    // BUTTONS
                    // btnDoneShow={el.contract.status === "new"}
                    // btnDetailLink={`/user/wishes/${el.uuid}`}
                    // linkToProfile={`/user/${el.author.uuid}`}
                    // ACTIONS
                    // onDoneClick={() =>
                    //   dispatch(action.mWishDoneSet(el.contract.uuid))
                    // }
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <section className="empty-notice bg-decoration">
      <div className="bg-decoration__wrapper" />
      <div className="empty-notice__container">
        <picture className="empty-notice__image">
          <source
            type="image/png"
            srcSet={ImageMobileEmpty}
            media="(max-width: 575px)"
          />
          <source type="image/png" srcSet={ImageEmpty} />
          <img src={ImageEmpty} alt="" />
        </picture>
        <div className="empty-notice__data">
          <h2 className="empty-notice__title">
            У твоего коллеги пока нет выполненных дел
          </h2>
          <p className="empty-notice__subtitle">
            «Кружок» – это платформа по исполнению больших и маленьких желаний
            (вишей), созданная специально к юбилею МегаФона. Это самый настоящий
            круговорот добрых дел: ты поможешь коллеге исполнить его желание, а
            кто-то – он или другой коллега, неважно – исполнит твоё. А
            представляешь, как здорово, если ты выступишь в роли волшебника и
            прямо сейчас воплотишь мечту коллеги, у которого сегодня день
            рождения!
            <br />
            И не забывай о том, что за каждый исполненный виш тебе начисляется
            балл рейтинга. Самые заботливые и отзывчивые коллеги по итогам
            рейтинга получают приятные сюрпризы :)
            <br />
            Так что скорее переходи в вишлист и выбирай, кому ты хочешь помочь
            :)
          </p>
        </div>
        <div className="empty-notice__buttons">
          {/* <Link to="/wishlists" className="button">
            <span>Перейти в добрые дела</span>
          </Link> */}
          <Link to="/" className="empty-notice__link">
            <span>На главную</span>
            <IconArrowRight />
          </Link>
        </div>
      </div>
    </section>
  );
};
