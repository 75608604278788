import { urls } from "app/constants";
import {
  dtoFormikServerErr,
  dtoWishCreate,
  dtoWishlists,
  dtoWishPaginator,
  dtoWishEdit,
} from "app/utils/dto";
import { axiosSecure } from "./_api";

export const apiWishCreate = (
  params: any,
  success: any,
  unsuccess: any,
  setErrors: any
) => {
  axiosSecure({
    url: urls.wishCreate,
    method: "post",
    data: dtoWishCreate(params),
  })
    .then(success)
    .catch((error) => {
      // console.log("API WISH CREATE ERROR 400", error);
      const errorObject = dtoFormikServerErr(error.response.data);
      setErrors(errorObject);
      return unsuccess(error);
    });
};

export const apiWishCategories = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishCategories,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiWishTags = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishTags,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiWishDelete = (uuid: string, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishDelete(uuid),
    method: "DELETE",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiWishList = (params: any, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishList,
    method: "get",
    params: dtoWishlists(params),
  })
    .then(success)
    .catch(unsuccess);
};

export const apiWishPaginator = (
  slug: string,
  params: any,
  success: any,
  unsuccess: any
) => {
  axiosSecure({
    url: urls.wishPaginator(slug),
    method: "get",
    params: dtoWishPaginator(params),
  })
    .then(success)
    .catch(unsuccess);
};

export const apiBirthPaginator = (
  params: any,
  success: any,
  unsuccess: any
) => {
  axiosSecure({
    url: urls.wishBirthPaginator,
    method: "get",
    params: dtoWishPaginator(params),
  })
    .then(success)
    .catch(unsuccess);
};

// WISH MY GET
export const apiWishMy = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishCreate,
    method: "get",
  })
    .then(success)
    .catch(unsuccess);
};

// WISH EDIT
export const apiWishEdit = (
  uuid: string,
  params: any,
  success: any,
  unsuccess: any,
  setErrors: any
) => {
  axiosSecure({
    url: urls.wishMy(uuid),
    method: "post",
    data: dtoWishEdit(params),
  })
    .then(success)
    .catch((error) => {
      // console.log("API WISH EDIT ERROR 400", error);
      const errorObject = dtoFormikServerErr(error.response.data);
      setErrors(errorObject);
      return unsuccess(error);
    });
};

export const apiWishRefuse = (uuid: string, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishRefuse(uuid),
    method: "post",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiWishReject = (uuid: string, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishReject(uuid),
    method: "post",
  })
    .then(success)
    .catch(unsuccess);
};

export const apiWishDone = (
  uuid: string,
  file: any,
  success: any,
  unsuccess: any
) => {
  const formData = new FormData();
  formData.append("attachment", file);

  axiosSecure({
    url: urls.wishDone(uuid),
    data: formData,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then(success)
    .catch(unsuccess);
};

export const apiWishApprove = (uuid: string, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.wishApprove(uuid),
    method: "post",
  })
    .then(success)
    .catch(unsuccess);
};
