import React, { useEffect, useState } from "react";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import { useParams, Link } from "react-router-dom";
// import { SectionProfileWish } from "blocks/profile";
// import { WishMakers } from "components/wishMakers/wishMakers";
import { ChatProfile } from "components/chat";
// import { profileLinks } from "app/constants";
import { LinkActionBack } from "components/link";
import { LoaderMini } from "components/loader";
import { Formik, FormWishProfile } from "components/form";
import { schemas } from "app/utils/schemas";
import { useApiMyWish, useApiCategories, useApiChatSecret } from "app/hooks";
import {
  apiWishEdit,
  apiNotifyList,
  apiNotifyRead,
  apiNotifyCheckAll,
} from "app/api";
import { path } from "ramda";
import avatarDefaultBig from "assets/images/avatar-default-big.svg";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "components/swiperNavigation/swiperNavigation";
import { WishNotify } from "components/wishNotify";
// import imagePlug from "assets/images/headline-decor-desktop@2x-min.png";
import "swiper/css";

export const WrapperProfileWish = () => {
  const dispatch = useDsp();
  // const myUuid = useSlc((state: RootState) => state.user.uuid);
  const globalReload = useSlc((state: RootState) => state.hookReload);
  const [reload, setReload]: [number, any] = useState(0);
  const [phone, setPhone]: [string, any] = useState("");
  const [telega, setTelega]: [string, any] = useState("");

  const { id }: any = useParams() || "";
  const { loading, data, error }: any = useApiMyWish(id, reload + globalReload);
  const wishCategories: any = useApiCategories().data;
  const { user } = store.getState();

  // CHAT
  const chatData: any = useApiChatSecret().data;
  const chatSecret = path(["data", "secret"], chatData) || "";

  useEffect(() => {
    const contracts: any[] = path(["data", "contracts"], data) || [];
    if (contracts.length > 0) {
      const phone = path([0, "performer", "contact_phone"], contracts);
      if (phone) setPhone(phone);
    }
    const telega = path([0, "performer", "contact_telegram"], contracts);
    if (telega) setTelega(telega);
  }, [data]);

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  if (data) {
    let categoryList = [];
    let subCategoryList: any[] = [];

    if (
      wishCategories &&
      wishCategories.status === 200 &&
      wishCategories.data &&
      wishCategories.data.length > 0
    ) {
      // IF SYSTEM USER
      if (user && user.isSystemUser) {
        categoryList = wishCategories.data
          .filter((i: any) => i.is_system === true)
          .map((l: any) => ({
            label: l.name,
            value: l.slug,
          }));

        wishCategories.data
          .filter((i: any) => i.is_system === true)
          .forEach((i: any) => {
            if (
              i &&
              i.available_sub_category &&
              i.available_sub_category.length
            ) {
              i.available_sub_category.forEach((s: any) => {
                let label = "_";
                if (s === "small_deal") label = "0,5 баллов";
                if (s === "big_deal") label = "1 балл";
                subCategoryList.push({
                  label: label,
                  value: s,
                });
              });
            }
          });

        // ELSE
      } else {
        categoryList = wishCategories.data
          .filter((i: any) => i.is_system !== true)
          .map((l: any) => ({
            label: l.name,
            value: l.slug,
          }));
      }
    }

    const title = data.data.title || "";

    // const description = data.data.description || "";
    let description = "";
    if (data.data.description) {
      description = JSON.parse(data.data.description);
    }
    //

    const categoryName = data.data.category.name || "";
    const categorySlug = data.data.category.slug || "";
    const categoryDefault = {
      value: categorySlug,
      label: categoryName,
    };

    const subCategoryDefault = {
      value: null,
      label: "",
    };

    if (data.data.sub_category) {
      let label = " ";
      if (data.data.sub_category === "small_deal") label = "0,5 баллов";
      if (data.data.sub_category === "big_deal") label = "1 балл";

      subCategoryDefault.value = data.data.sub_category;
      subCategoryDefault.label = label;
    }

    const tagsDefault: any = path(["data", "tags"], data) || [];
    const tagsDefObj = tagsDefault.map((tag: string) => ({
      label: tag,
      value: tag,
    }));

    // const cityName = path(["data", "city", "name"], data) || "город не указан";
    // const cityUuid = path(["data", "city", "uuid"], data) || "uuid";
    const cityDefault: any = path(["data", "cities"], data) || [];
    const cityDefObj = cityDefault.map((city: any) => ({
      label: city.name,
      value: city.uuid,
    }));

    const performers = data.data.max_performer_amount;
    const performersDefault = {
      value: performers,
      label: performers,
    };

    const wishStaus = data.data.status;

    const contractList: any = path(["data", "contracts"], data) || [];

    const chatTopic = path(["data", "tinode_chat_uid"], data) || "";
    const update = Date.now();
    const willConnect = contractList.length > 0;

    // API WISH EDIT
    const apiSuccess = () => {
      dispatch(
        action.mInfoSetData({
          title: "Спасибо",
          message: "Изменения сохранены",
        })
      );
      dispatch(action.mInfoShow());
      setReload(reload + 1);
    };

    const apiError = () => {
      console.log("API WISH EDIT ERROR");
    };

    // API WISH REMOVE
    const wishRemove = () => {
      dispatch(action.mWishRemoveSet(id));
    };

    // WISH ACCEPT MODAL
    const wishAccept = () => {
      dispatch(action.mWishAcceptSet(id));
    };

    return (
      <section className="profile-wish bg-decoration" role="sectionProfileWish">
        <WishNotify wishId={id} />

        <div className="profile-wish__wrapper">
          <div className="profile-wish__form">
            <LinkActionBack to="/profile/wishes">Мои дела</LinkActionBack>
            <div className="profile-wish__form-wrapper">
              <h2 className="profile-wish__title">Мое дело</h2>
              <Formik
                initialValues={{
                  wishName: title,
                  wishDesc: description,
                  wishCategory: categoryDefault,
                  wishSubCategory: subCategoryDefault,
                  tags: tagsDefault,
                  city: cityDefault.map((c: any) => c.uuid),
                  memberAmount: performersDefault,
                }}
                validationSchema={
                  user.isSystemUser ? schemas.mySubWish : schemas.myWish
                }
                onSubmit={(params, { setErrors }) =>
                  apiWishEdit(id, params, apiSuccess, apiError, setErrors)
                }
              >
                <FormWishProfile
                  user={user}
                  wishStaus={wishStaus}
                  categoryList={categoryList}
                  subCategoryList={subCategoryList}
                  categoryDefault={categoryDefault}
                  subCategoryDefault={subCategoryDefault}
                  performers={performers}
                  performersDefault={performersDefault}
                  tagsDefault={tagsDefault}
                  tagsDefObj={tagsDefObj}
                  cityDefObj={cityDefObj}
                  contractList={contractList}
                  wishRemove={wishRemove}
                  wishAccept={wishAccept}
                />
              </Formik>
            </div>
          </div>

          <div className="profile-wish__history">
            {/* <WishMakers /> */}
            {contractList.length === 0 && (
              <div className="wish-makers">
                <div className="wish-makers__avatar">
                  <img src={avatarDefaultBig} alt="" />
                </div>
                <div className="wish-makers__info">
                  <div className="wish-makers__no-performers">
                    Твое дело еще не принято на исполнение
                  </div>
                </div>
              </div>
            )}
            {contractList.length === 1 &&
              contractList.map((contract: any, id: number) => {
                const userName = path(["performer", "name"], contract) || "";
                const cityName =
                  path(["performer", "city", "name"], contract) || "";
                const completedWishes =
                  path(["performer", "completed_wishes_count"], contract) || 0;
                const uuid = path(["performer", "uuid"], contract) || "";
                const avatar = path(["performer", "image"], contract) || "";
                return (
                  <Link className="wish-makers" to={"/user/" + uuid} key={id}>
                    <div className="wish-makers__avatar">
                      <img src={avatar ? avatar : avatarDefaultBig} alt="" />
                    </div>
                    <div className="wish-makers__info">
                      <div className="wish-makers__title">Исполнитель</div>
                      <div className="wish-makers__name">
                        <>{userName}</>
                      </div>
                      <div className="wish-makers__city">
                        <span>Город:</span> <>{cityName}</>
                      </div>
                      <div className="wish-makers__amount">
                        <span>Выполнил дел:</span> <>{completedWishes}</>
                      </div>
                    </div>
                  </Link>
                );
              })}

            {contractList.length > 1 && (
              <Swiper
                className="wish-makers__slider"
                modules={[Pagination, Navigation]}
                spaceBetween={5}
                slidesPerView="auto"
                slideToClickedSlide
                navigation={{
                  prevEl: ".wish-makers__slider-arrow--prev",
                  nextEl: ".wish-makers__slider-arrow--next",
                }}
                pagination={{
                  clickable: true,
                  bulletActiveClass: "active",
                  bulletClass: "swiper--dots__item",
                  el: ".new--wishlist__dots",
                  renderBullet(index, className) {
                    console.log("++ BOOLET", className);
                    return `<span class="${className}"></span>`;
                  },
                }}
                onSlideChange={(sw) => {
                  // console.log("slide change", sw);
                  const index = sw.activeIndex || 0;
                  const performer = contractList[index];
                  console.log("performer", performer);

                  const phone =
                    path(["performer", "contact_phone"], performer) || "";

                  setPhone(phone);

                  const telega =
                    path(["performer", "contact_telegram"], performer) || "";
                  setTelega(telega);
                }}
              >
                {contractList.map((contract: any, id: number) => {
                  const userName = path(["performer", "name"], contract) || "";
                  const cityName =
                    path(["performer", "city", "name"], contract) || "";
                  const completedWishes =
                    path(["performer", "completed_wishes_count"], contract) ||
                    0;
                  const uuid = path(["performer", "uuid"], contract) || "";
                  const avatar = path(["performer", "image"], contract) || "";
                  return (
                    <SwiperSlide className="" key={id}>
                      <Link className="wish-makers" to={"/user/" + uuid}>
                        <div className="wish-makers__avatar">
                          <img
                            src={avatar ? avatar : avatarDefaultBig}
                            alt=""
                          />
                        </div>
                        <div className="wish-makers__info">
                          <div className="wish-makers__title">Исполнитель</div>
                          <div className="wish-makers__name">
                            <>{userName}</>
                          </div>
                          <div className="wish-makers__city">
                            <span>Город:</span> <>{cityName}</>
                          </div>
                          <div className="wish-makers__amount">
                            <span>Выполнил дел:</span>
                            <>{completedWishes}</>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
                <SwiperNavigation
                  className="wish-makers__navigation"
                  arrowPrev="new--wishlist__arrow--prev"
                  arrowNext="new--wishlist__arrow--next"
                  dots="new--wishlist__dots"
                />
              </Swiper>
            )}
            <ChatProfile
              chatSecret={chatSecret}
              chatTopic={chatTopic}
              update={update}
              willConnect={willConnect}
            />
          </div>
        </div>
      </section>
    );
  }

  return <></>;
};
