import React from "react";
import Headline from "components/headline/Headline";
import { ArticleTile } from "components";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavigation from "components/swiperNavigation/swiperNavigation";
import "swiper/css";

type props = {
  articles: any[];
};

export const Volunteering = ({ articles }: props) => (
  <div className="birthday--wishlist" id="volunteering">
    <div className="container">
      <Headline className="headline_lg birthday--wishlist__headline">
        Про волонтерство
      </Headline>
      <Swiper
        className="birthday--wishlist__list"
        modules={[Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView="auto"
        slideToClickedSlide
        navigation={{
          prevEl: ".birthday--wishlist__arrow--prev",
          nextEl: ".birthday--wishlist__arrow--next",
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: "active",
          bulletClass: "swiper--dots__item",
          el: ".birthday--wishlist__dots",
          renderBullet(index, className) {
            return `<span class="${className}"></span>`;
          },
        }}
      >
        {articles &&
          articles.length > 0 &&
          articles.map((article: any, key: number) => (
            <SwiperSlide key={key} className="wish--tile--slide">
              <ArticleTile article={article} />
            </SwiperSlide>
          ))}

        <SwiperNavigation
          className="birthday--wishlist__navigation"
          arrowPrev="birthday--wishlist__arrow--prev"
          arrowNext="birthday--wishlist__arrow--next"
          dots="birthday--wishlist__dots"
        />
      </Swiper>
    </div>
  </div>
);
