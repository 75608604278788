import React from "react";
import { TotalCounter } from "elements";
import { LoaderMini } from "components/loader";
import { Link } from "react-router-dom";
import avatarDefault from "assets/images/avatar-default.svg";
import { FormRatingFilter } from "components/form";
import { ButtonRatingFilter } from "elements/button";
import { Paginator } from "components/paginator";

type props = {
  loading: boolean;
  rating: any;
  filterCount: number;
  pagination: any;
  onPaginatorClick: (obj: any) => void;
};

export const SectionRating = ({
  loading,
  rating,
  filterCount,
  pagination,
  onPaginatorClick,
}: props) => {
  const members: any[] = rating.results || [];
  const totalCount = rating.total_completed_count || 0;
  // const totalCount = members.reduce(
  //   (a: number, user: any) => a + user.completed_wishes_count,
  //   0
  // );

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  return (
    <section className="rating bg-decoration" role="sectionRating">
      <div className="bg-decoration__rating" />
      <div className="container rating__container">
        <div className="rating__wrapper">
          <div className="rating__settings">
            <TotalCounter
              className="rating__total"
              title="Всего дел:"
              quantity={totalCount}
            />
            <ButtonRatingFilter />
            <FormRatingFilter />
          </div>

          <div className="rating__list">
            <div className="rating__column-name">Набрано баллов</div>
            {members.map(
              (
                // eslint-disable-next-line camelcase
                {
                  uuid,
                  name,
                  image,
                  // eslint-disable-next-line camelcase
                  rating_score,
                }: any,
                id: number
              ) => (
                <div className="member" key={id}>
                  <div className="member__user">
                    <Link to={`/user/${uuid}`}>
                      <div className="member__avatar">
                        <img src={image ? image : avatarDefault} alt="" />
                      </div>
                    </Link>
                    <Link to={`/user/${uuid}`} className="member__name">
                      {name}
                    </Link>
                  </div>
                  <div className="member__points">
                    {/* eslint-disable-next-line camelcase */}
                    {rating_score}
                  </div>
                </div>
              )
            )}
          </div>
          <Paginator
            filterCount={filterCount}
            pagination={pagination}
            onPaginatorClick={onPaginatorClick}
          />
        </div>
      </div>
    </section>
  );
};
