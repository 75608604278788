import React from "react";
import { WishlistSlider } from "blocks/wishlist";
import { useSlc, RootState } from "app/store";
import { FormWishListFilter } from "components/form";
import { ButtonWishListFilter } from "elements/button";
import { LoaderMini } from "components/loader";
import { path } from "ramda";

type props = {
  data: any;
  performWish: any;
};

export const SectionWishLists = ({ data, performWish }: props) => {
  const loading = useSlc((state: RootState) => state.loaderWishLists);

  const sotsialnayaAktivnost: any[] =
    path(["sotsialnaya-aktivnost", "list"], data) || [];
  const sobyitiyaKruzhka: any[] =
    path(["sobyitiya-kruzhka", "list"], data) || [];
  const korporativnoeVolontyorstvo: any[] =
    path(["korporativnoe-volontyorstvo", "list"], data) || [];

  // const razvitie: any[] = path(["razvitie", "list"], data) || [];
  // const sport: any[] = path(["sport", "list"], data) || [];
  // const puteshestviya: any[] = path(["puteshestviya", "list"], data) || [];
  // const dobro: any[] = path(["dobro", "list"], data) || [];
  // const razvlecheniya: any[] = path(["razvlecheniya", "list"], data) || [];
  // const dom: any[] = path(["dom", "list"], data) || [];
  // const pitomtsyi: any[] = path(["pitomtsyi", "list"], data) || [];
  // const tvorchestvo: any[] = path(["tvorchestvo", "list"], data) || [];
  // const drugoe: any[] = path(["drugoe", "list"], data) || [];

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  return (
    <section className="wishlists bg-decoration" role="sectionWishlists">
      <div className="bg-decoration__wishlist" />
      <div className="container">
        <ButtonWishListFilter />
        <FormWishListFilter counter={false} />

        {sotsialnayaAktivnost.length > 0 && (
          <WishlistSlider
            title="Социальная активность"
            data={sotsialnayaAktivnost.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/sotsialnaya-aktivnost"
          />
        )}

        {sobyitiyaKruzhka.length > 0 && (
          <WishlistSlider
            title="События кружка"
            data={sobyitiyaKruzhka.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/sobyitiya-kruzhka"
          />
        )}

        {korporativnoeVolontyorstvo.length > 0 && (
          <WishlistSlider
            title="Корпоративное волонтерство"
            data={korporativnoeVolontyorstvo.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/korporativnoe-volontyorstvo"
          />
        )}

        {/*
        {razvitie.length > 0 && (
          <WishlistSlider
            title="Развитие"
            data={razvitie.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/razvitie"
          />
        )}

        {sport.length > 0 && (
          <WishlistSlider
            title="Спорт"
            data={sport.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/sport"
          />
        )}

        {puteshestviya.length > 0 && (
          <WishlistSlider
            title="Путешествия"
            data={puteshestviya.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/travel"
          />
        )}

        {dobro.length > 0 && (
          <WishlistSlider
            title="Добро"
            data={dobro.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/good"
          />
        )}

        {razvlecheniya.length > 0 && (
          <WishlistSlider
            title="Развлечения"
            data={razvlecheniya.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/entertain"
          />
        )}

        {dom.length > 0 && (
          <WishlistSlider
            title="Дом"
            data={dom.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/home"
          />
        )}

        {pitomtsyi.length > 0 && (
          <WishlistSlider
            title="Питомцы"
            data={pitomtsyi.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/pet"
          />
        )}

        {tvorchestvo.length > 0 && (
          <WishlistSlider
            title="Творчество"
            data={tvorchestvo.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/creation"
          />
        )}

        {drugoe.length > 0 && (
          <WishlistSlider
            title="Другое"
            data={drugoe.slice(0, 10) || []}
            performWish={performWish}
            linkToCategory="/wishlists/other"
          />
        )} */}
      </div>
    </section>
  );
};
