import React from "react";
import Intro from "blocks/intro/intro";
import NewWishlist from "blocks/newWishlist/newWishlist";
import QuickCategories from "blocks/quickCategories/quickCategories";
import { Volunteering } from "blocks";
import Faq from "blocks/faq/faq";
import { ChatWidget, ChatExtend } from "components/chat";
import { LoaderMini } from "components/loader";

type props = {
  loading: boolean;
  dashboard: any[];
  catalog: any;
  articles: any[];
  faq: any[];
  performWish: any;
  chatSecret: any;
  chatTopic: any;
  update: number;
};

export const SectionMain = ({
  loading,
  dashboard,
  articles,
  faq,
  catalog,
  performWish,
  chatSecret,
  chatTopic,
  update,
}: props) => {
  if (loading)
    return (
      <section className="main bg-decoration">
        <LoaderMini />
      </section>
    );

  return (
    <section className="main bg-decoration">
      <div className="bg-decoration__main" />
      <Intro />
      <NewWishlist dashboard={dashboard} performWish={performWish} />
      <QuickCategories catalog={catalog} />
      <Volunteering articles={articles} />
      <Faq faq={faq} />
      <ChatWidget />
      <ChatExtend
        chatSecret={chatSecret}
        chatTopic={chatTopic}
        update={update}
      />
    </section>
  );
};
