import React, { useState, useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  NodeMatch,
} from "slate";
import { withHistory } from "slate-history";
import { Field, ErrorMessage, BSform } from "components/form";
// import { Button, Icon, Toolbar } from "./components";

const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES: string[] = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
let initialValue: any[] = [
  {
    type: "paragraph",
    align: "left",
    children: [{ text: "" }],
  },
];

export const RichtextPreview = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

  const [editor] = useState(() => withReact(createEditor()));

  if (description) {
    initialValue = JSON.parse(description);
  }

  return (
    <div className="form-group">
      {label && <BSform.Label>{label}</BSform.Label>}
      <div className="richtext__wrapper preview">
        <Slate editor={editor} initialValue={initialValue}>
          <Editable
            // eslint-disable-next-line arrow-body-style
            renderElement={(props: any) => {
              return renderElement(props);
            }}
            // eslint-disable-next-line arrow-body-style
            renderLeaf={(props) => {
              return renderLeaf(props);
            }}
            placeholder={" "}
            // spellCheck
            // autoFocus
            // onKeyDown={(event) => {
            //   console.log("+++ KEY", event);
            // eslint-disable-next-line no-restricted-syntax
            // for (const hotkey in HOTKEYS) {
            //   if (isHotkey(hotkey, event as any)) {
            //     event.preventDefault();
            //     const mark = HOTKEYS[hotkey];
            //     toggleMark(editor, mark);
            //   }
            // }
            // }}
          />
        </Slate>
      </div>
    </div>
  );
};

// const toggleBlock = (editor: any, format: any) => {
//   const isActive = isBlockActive(
//     editor,
//     format,
//     TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
//   );
//   const isList = LIST_TYPES.includes(format);

//   Transforms.unwrapNodes(editor, {
//     match: (n: any) => {
//       const m = n as any;
//       return (
//         !Editor.isEditor(n) &&
//         SlateElement.isElement(n) &&
//         LIST_TYPES.includes(m.type) &&
//         !TEXT_ALIGN_TYPES.includes(format)
//       );
//     },
//     split: true,
//   });
//   let newProperties: any;
//   if (TEXT_ALIGN_TYPES.includes(format)) {
//     newProperties = {
//       align: isActive ? undefined : format,
//     };
//   } else {
//     newProperties = {
//       // eslint-disable-next-line no-nested-ternary
//       type: isActive ? "paragraph" : isList ? "list-item" : format,
//     };
//   }
//   Transforms.setNodes<SlateElement>(editor, newProperties);

//   if (!isActive && isList) {
//     const block = { type: format, children: [] };
//     Transforms.wrapNodes(editor, block);
//   }
// };

// const toggleMark = (editor: any, format: any) => {
//   const isActive = isMarkActive(editor, format);

//   if (isActive) {
//     Editor.removeMark(editor, format);
//   } else {
//     Editor.addMark(editor, format, true);
//   }
// };

// const isBlockActive = (editor: any, format: any, blockType = "type") => {
//   const { selection } = editor;
//   if (!selection) return false;

//   const [match] = Array.from(
//     Editor.nodes(editor, {
//       at: Editor.unhangRange(editor, selection),
//       match: (n: any | any[] | string[]) => {
//         const m = n as any;
//         return (
//           !Editor.isEditor(n) &&
//           SlateElement.isElement(n) &&
//           m[blockType] === format
//         );
//       },
//     })
//   );

//   return !!match;
// };

// const isMarkActive = (editor: any, format: any) => {
//   const marks = Editor.marks(editor) as any;
//   return marks ? marks[format] === true : false;
// };

const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    // eslint-disable-next-line no-param-reassign
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    // eslint-disable-next-line no-param-reassign
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    // eslint-disable-next-line no-param-reassign
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    // eslint-disable-next-line no-param-reassign
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
