import axios from "axios";

export const apiGetArticles = (success: any, unsuccess: any) => {
  axios
    .get("https://test-wp.test-digital.ru/?rest_route=/wp/v2/posts")
    .then(success)
    .catch(unsuccess);
};

export const apiGetArticle = (
  articleId: number,
  success: any,
  unsuccess: any
) => {
  axios
    .get(
      `https://test-wp.test-digital.ru/index.php?rest_route=/wp/v2/posts/${articleId}`
    )
    .then(success)
    .catch(unsuccess);
};

export const apiGetArticleImage = (
  imageId: number,
  success: any,
  unsuccess: any
) => {
  axios
    .get(`https://test-wp.test-digital.ru/?rest_route=/wp/v2/media/${imageId}`)
    .then(success)
    .catch(unsuccess);
};
