import React, { useEffect } from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { useParams } from "react-router-dom";
import { useApiUserWish, useApiCategories, useApiChatSecret } from "app/hooks";
import { path } from "ramda";
import { Formik, FormWishUser } from "components/form";
import { WishMakers } from "components/wishMakers/wishMakers";
import { ChatProfile } from "components/chat";
import { profileLinks } from "app/constants";
import { LinkActionBack } from "components/link";
import { schemas } from "app/utils/schemas";
import { LoaderMini } from "components/loader";
import avatarDefaultBig from "assets/images/avatar-default-big.svg";
import { WishNotify } from "components/wishNotify";

export const WrapperUserWish = () => {
  const dispatch = useDsp();
  const myUuid = useSlc((state: RootState) => state.user.uuid);
  const { id }: any = useParams() || "";
  const { loading, data }: any = useApiUserWish(id);
  const wishCategories: any = useApiCategories().data;
  const chatData: any = useApiChatSecret().data;

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  if (data) {
    const constactId: any = path(["data", "contract", "uuid"], data) || "";
    const chatSecret = path(["data", "secret"], chatData) || "";

    let categoryList = [];
    if (
      wishCategories &&
      wishCategories.status === 200 &&
      wishCategories.data &&
      wishCategories.data.length > 0
    ) {
      categoryList = wishCategories.data.map((l: any) => ({
        // icon: l.icon,
        label: l.name,
        value: l.slug,
      }));
    }

    const title = path(["data", "title"], data) || "";

    // const description = path(["data", "description"], data) || "";
    let description = "";
    if (path(["data", "description"], data)) {
      description = JSON.parse(path(["data", "description"], data));
    }
    //

    const categoryName = path(["data", "category", "name"], data) || "";
    const categorySlug = path(["data", "category", "slug"], data) || "";
    const categoryDefault = {
      value: categorySlug,
      label: categoryName,
    };

    const tagsDefault: any = path(["data", "tags"], data) || [];
    const tagsDefObj = tagsDefault.map((tag: string) => ({
      label: tag,
      value: tag,
    }));

    // const cityName = path(["data", "city", "name"], data) || "город не указан";
    // const cityUuid = path(["data", "city", "uuid"], data) || "uuid";
    const cityDefault: any = path(["data", "cities"], data) || [];
    const cityDefObj = cityDefault.map((city: any) => ({
      label: city.name,
      value: city.uuid,
    }));

    const userName = path(["data", "author", "name"], data) || "Аноним";
    const avatar = path(["data", "author", "image"], data) || "";
    const userCity = path(["data", "author", "city", "name"], data) || "";

    const phone = path(["data", "author", "contact_phone"], data) || "";
    const telega = path(["data", "author", "contact_telegram"], data) || "";

    const performers = path(["data", "max_performer_amount"], data);
    const performersDefault = {
      value: performers,
      label: performers,
    };

    const contractStatus = path(["data", "contract", "status"], data);

    const completedWishes = path(
      ["data", "author", "completed_wishes_count"],
      data
    );

    const tinodeRoomId = path(["data", "tinode_chat_uid"], data) || "";
    const update = Date.now();

    // MODAL WISH DONE
    const wishDone = () => {
      if (constactId) dispatch(action.mWishDoneSet(constactId));
    };

    // MODAL WISH REFUSE
    const wishRefuse = () => {
      if (constactId) dispatch(action.mWishRefuseSet(constactId));
    };

    return (
      <section className="profile-wish bg-decoration" role="sectionProfileWish">
        <WishNotify wishId={id} />

        <div className="profile-wish__wrapper">
          <div className="profile-wish__form">
            <LinkActionBack to="/profile/performers">
              Помогаю коллегам
            </LinkActionBack>
            <div className="profile-wish__form-wrapper">
              <h2 className="profile-wish__title"> </h2>
              <Formik
                initialValues={{
                  wishName: title,
                  wishDesc: description,
                  wishCategory: categoryDefault,
                  tags: tagsDefault,
                  city: cityDefault,
                  memberAmount: performersDefault,
                }}
                validationSchema={schemas.myWish}
                onSubmit={() => {}}
              >
                <FormWishUser
                  contractStatus={contractStatus}
                  categoryList={categoryList}
                  categoryDefault={categoryDefault}
                  performersDefault={performersDefault}
                  tagsDefault={tagsDefault}
                  cityDefault={cityDefault}
                  tagsDefObj={tagsDefObj}
                  cityDefObj={cityDefObj}
                  wishRefuse={wishRefuse}
                  wishDone={wishDone}
                />
              </Formik>
            </div>
          </div>

          <div className="profile-wish__history">
            <div className="wish-makers">
              <div className="wish-makers__avatar">
                <img src={avatar ? avatar : avatarDefaultBig} alt="" />
              </div>
              <div className="wish-makers__info">
                <div className="wish-makers__title">Автор</div>
                <div className="wish-makers__name">{userName}</div>
                <div className="wish-makers__city">
                  <span>Город:</span> {userCity}
                </div>
                <div className="wish-makers__amount">
                  <span>Выполнил дел:</span> <>{completedWishes}</>
                </div>
              </div>
            </div>
            <ChatProfile
              chatSecret={chatSecret}
              chatTopic={tinodeRoomId}
              update={update}
              willConnect={true}
            />

            {/* <div className="chat-profile">
              <div className="chat">
                <div className="chat-header"></div>
                <div className="chat-body">
                  <div className="chat-body__plug">
                    <>
                      {phone && <p>Телефон: {`${phone}`}</p>}
                      {telega && <p>Телеграм: {`${telega}`}</p>}
                      {!phone && !telega && (
                        <p>
                          Пользователь не указал номер телефона или ник в
                          телеграмм
                        </p>
                      )}
                    </>
                  </div>
                </div>
                <div className="chat-footer"></div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
  return <></>;
};
