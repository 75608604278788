import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import type { RootState } from "app/store";
import { useSlc, useDsp, action } from "app/store";
import logoBrand from "assets/images/logo-brand.png";
import { HashLink } from "react-router-hash-link";
// import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";

export const HeaderTop = () => {
  const dispatch = useDsp();
  const isAuth = useSlc((state: RootState) => state.user.isAuth);

  // if (user.isAuth)
  return (
    <header className="header--top" role="headerTop">
      <div className="header--top__container container">
        <div className="header--top__logo">
          <NavLink
            className="header--top__logo--link"
            role="headerTopLogo"
            to="/"
          >
            <img src={logoBrand} alt="" />
          </NavLink>
        </div>
        <nav className="header--top__nav">
          <div className="header--top__nav-wrapper">
            <NavLink className="header--top__nav--link" to="/">
              Главная
            </NavLink>
            <button
              className="btn-reset header--top__nav--link"
              type="button"
              onClick={() => dispatch(action.mKruzokShow())}
            >
              Кружок — это
            </button>
            <NavLink className="header--top__nav--link" to="/wishlists">
              Добрые дела
            </NavLink>
            <HashLink className="header--top__nav--link" to="/#volunteering">
              Про волонтерство
            </HashLink>
            <NavLink className="header--top__nav--link" to="/rating">
              Рейтинг
            </NavLink>
            <button
              className="btn-reset header--top__nav--link"
              type="button"
              onClick={() => dispatch(action.mIntroShow())}
            >
              Подсказки
            </button>
            <button
              className="btn-reset header--top__nav--link"
              type="button"
              onClick={() => dispatch(action.mFeedbackShow())}
            >
              Помощь
            </button>
            <a
              href="./assets/documents/rules.pdf"
              className="btn-reset header--top__nav--link"
              target="_blank"
            >
              Правила конкурса
            </a>
          </div>
        </nav>

        <a
          className="footer__telegram-link"
          href="https://t.me/+i5qokaJMrTYzNTAy"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
        </a>

        {/* {isAuth && (
          <button
            className="btn-reset button-action-forward button-action-logout"
            type="button"
            onClick={() => logout()}
          >
            <span>Выход</span>
            <IconArrowRight />
          </button>
        )} */}
      </div>
    </header>
  );
  // return <></>;
};
