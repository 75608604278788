import React from "react";
import { action, useDsp } from "app/store";
import { ReactComponent as IconSettings } from "assets/svg/icon-settings.svg";

export const ButtonWishListFilter = () => {
  const dispatch = useDsp();
  const filterShow = () => dispatch(action.fWishListShow());

  return (
    <button
      type="button"
      className="btn-reset button-mobile-menu"
      onClick={filterShow}
    >
      <IconSettings />
    </button>
  );
};
