import React from "react";
import { NavLink } from "react-router-dom";
import type { RootState } from "app/store";
import { useSlc } from "app/store";
import Headline from "components/headline/Headline";
import RedirectTo from "components/RedirectTo/RedirectTo";
import { WrapperLogin, WrapperRec, WrapperActive } from "app/wrappers";

export const Authorization = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);
  const authVariant = useSlc((state: RootState) => state.authVariant);

  if (!isAuth)
    return (
      <div className="authorization bg-decoration">
        <div className="bg-decoration__login" />
        <div className="container">
          <div className="authorization__wrapper">
            <Headline className="authorization__headline" variant="xl">
              Добро пожаловать в&nbsp;Кружок!
            </Headline>

            <nav className="authorization__nav">
              <NavLink className="authorization__nav--link" to="/sign-up">
                Регистрация
              </NavLink>
              <NavLink className="authorization__nav--link" to="/sign-in">
                Вход
              </NavLink>
            </nav>

            {authVariant === "login" && <WrapperLogin />}
            {authVariant === "recovery" && <WrapperRec />}
            {authVariant === "activate" && <WrapperActive />}
          </div>
        </div>
      </div>
    );
  return <RedirectTo path="/" />;
};
