import React from "react";
import { Link, useParams } from "react-router-dom";
import { clsx } from "clsx";
import { ReactComponent as IconArrowLeft } from "assets/images/icon-arrow-left.svg";

type props = {
  to: string;
  className?: string;
  children: any;
};

export const LinkActionBack = ({ to, className, children }: props) => (
  <Link to={to} className={clsx("link-action-back", className)}>
    <IconArrowLeft />
    <span>{children}</span>
  </Link>
);
