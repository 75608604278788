import React, { FC } from "react";
import Modal from "react-bootstrap/Modal";
import { ButtonModalClose } from "components/button";
import clsx from "clsx";

type props = {
  className?: string;
  dialogClassName?: string;
  contentClassName?: string;
  bodyClassName?: string;
  show: boolean;
  backdrop?: any;
  title?: any;
  onHide: any;
  children: any;
};

export const ModalBasic: FC<props> = ({
  className,
  dialogClassName,
  contentClassName,
  bodyClassName,
  show,
  backdrop,
  title,
  onHide,
  children,
}) => (
  <Modal
    show={show}
    onHide={onHide}
    className={clsx("modal--basic", className)}
    dialogClassName={clsx("modal-dialog--basic", dialogClassName)}
    contentClassName={clsx("modal-content--basic", contentClassName)}
    backdrop={backdrop}
    centered
  >
    <>
      {title && (
        <div className="modal-header modal-header--basic">
          <div className="modal-title modal-title--basic">{title}</div>
        </div>
      )}
      <ButtonModalClose onClick={onHide} />
      <div className={clsx("modal-body modal-body--basic", bodyClassName)}>
        {children}
      </div>
    </>
  </Modal>
);
