import React, { FC } from "react";
import clsx from "clsx";
import SwiperDots from "components/swiperDots/swiperDots";
import SwiperArrow from "components/swiperArrow/swiperArrow";

type props = {
  className?: string;
  arrowPrev?: string;
  arrowNext?: string;
  dots?: string;
};

const SwiperNavigation: FC<props> = (props) => (
  <div className={clsx("swiper--navigation", props.className)}>
    {props.arrowPrev && (
      <SwiperArrow variant="prev" className={props.arrowPrev} />
    )}
    {props.arrowNext && (
      <SwiperArrow variant="next" className={props.arrowNext} />
    )}
    {props.dots && <SwiperDots className={props.dots} />}
  </div>
);

export default SwiperNavigation;
