import React from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { ReactComponent as IconEdit } from "assets/images/icon-edit.svg";
import clsx from "clsx";
import "react-datepicker/dist/react-datepicker.css";
import { IMaskInput, maskSelector } from "app/utils/imask";

type props = {
  className?: string;
  variant?: string;
  label?: string;
  name: string;
  placeholder: string;
  role?: string;
  onChange?: any;
  disabled?: boolean;
  onEditClick?: any;
  onBlur?: any;
};

export const InputDate = ({
  variant,
  name,
  label,
  placeholder,
  className,
  role,
  disabled = false,
  onEditClick,
  onBlur,
}: props) => {
  const utsToYMD = (date: string) => {
    // console.log("DTO UTC TO YMD", date);
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`.padStart(2, "0");
    let day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return [year, month, day].join("-");
  };

  const handleCalendarClose = () => {
    if (onBlur) onBlur();
  };

  return (
    <Field name={name}>
      {({ field }: any) => (
        <div className="form-group">
          {label && <BSform.Label>{label}</BSform.Label>}

          <div className="form-control-relative-wrapper">
            <DatePicker
              selected={field.value ? new Date(field.value) : null}
              // dateFormat="yyyy-MM-dd"
              dateFormatCalendar="MMMM"
              dateFormat="dd.MM"
              placeholderText={placeholder}
              disabled={disabled}
              locale={ru}
              onChange={(date: any) => {
                // console.log("- E TARGET VALUE - ", date);
                const dateYMD = utsToYMD(date);
                // console.log("- E TARGET VALUE - ", dateYMD);
                field.onChange({
                  target: {
                    name,
                    value: dateYMD,
                  },
                });
              }}
              onCalendarClose={handleCalendarClose}
              // withPortal
              // showYearDropdown
              customInput={
                // <input
                //   name="date"
                //   className={clsx("form-control", className)}
                //   disabled={disabled}
                //   role={role}
                // />
                <IMaskInput
                  className={clsx("form-control", className)}
                  name="date"
                  type="text"
                  placeholder="123"
                  role={role}
                  disabled={disabled}
                  {...maskSelector("date")}
                  {...field}
                  onInput={(e: any) => {}}
                  onBlur={(e: any) => {}}
                />
              }
            />

            {variant === "edit" && (
              <button
                type="button"
                className={`btn-reset form-control-edit-button ${
                  disabled ? "disabled" : ""
                }`}
                onClick={() => onEditClick()}
              >
                <IconEdit />
              </button>
            )}
          </div>

          <ErrorMessage name={name} component="div">
            {(msg) => <div className="input__error-text">{msg}</div>}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
};
