import React, { useState, useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  NodeMatch,
} from "slate";
import { withHistory } from "slate-history";
import { Field, ErrorMessage, BSform } from "components/form";
// import { Button, Icon, Toolbar } from "./components";

const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES: string[] = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
let initialValue: any[] = [
  {
    type: "paragraph",
    align: "left",
    children: [{ text: "" }],
  },
];

export const RichText = ({
  label,
  name,
  placeholder,
  role,
  disabled,
}: {
  label: string;
  name: string;
  placeholder: string;
  role: string;
  disabled?: boolean;
}) => {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  // const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const [editor] = useState(() => withReact(createEditor()));

  return (
    <Field name={name}>
      {({ field }: any) => {
        if (field && field.value) initialValue = field.value;
        else
          initialValue = [
            {
              type: "paragraph",
              align: "left",
              children: [{ text: "" }],
            },
          ];

        return (
          <div className="form-group">
            {label && <BSform.Label>{label}</BSform.Label>}
            <div className={`richtext__wrapper ${disabled ? "disabled" : ""}`}>
              <Slate
                editor={editor}
                initialValue={initialValue}
                onChange={(value) => {
                  const event = {
                    target: {
                      value: value,
                      name: name,
                    },
                  };
                  field.onChange(event);
                  console.log(" +++ ", value);
                }}
              >
                {/* <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" />
        </Toolbar> */}
                <Editable
                  // eslint-disable-next-line arrow-body-style
                  renderElement={(props: any) => {
                    return renderElement(props);
                  }}
                  // eslint-disable-next-line arrow-body-style
                  renderLeaf={(props) => {
                    return renderLeaf(props);
                  }}
                  placeholder={placeholder}
                  // spellCheck
                  // autoFocus
                  // onKeyDown={(event) => {
                  //   console.log("+++ KEY", event);
                  // eslint-disable-next-line no-restricted-syntax
                  // for (const hotkey in HOTKEYS) {
                  //   if (isHotkey(hotkey, event as any)) {
                  //     event.preventDefault();
                  //     const mark = HOTKEYS[hotkey];
                  //     toggleMark(editor, mark);
                  //   }
                  // }
                  // }}
                />
              </Slate>
            </div>
            <ErrorMessage name={name} component="div">
              {(msg) => <div className="input__error-text">{msg}</div>}
            </ErrorMessage>
          </div>
        );
      }}
    </Field>
  );
};

// const toggleBlock = (editor: any, format: any) => {
//   const isActive = isBlockActive(
//     editor,
//     format,
//     TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
//   );
//   const isList = LIST_TYPES.includes(format);

//   Transforms.unwrapNodes(editor, {
//     match: (n: any) => {
//       const m = n as any;
//       return (
//         !Editor.isEditor(n) &&
//         SlateElement.isElement(n) &&
//         LIST_TYPES.includes(m.type) &&
//         !TEXT_ALIGN_TYPES.includes(format)
//       );
//     },
//     split: true,
//   });
//   let newProperties: any;
//   if (TEXT_ALIGN_TYPES.includes(format)) {
//     newProperties = {
//       align: isActive ? undefined : format,
//     };
//   } else {
//     newProperties = {
//       // eslint-disable-next-line no-nested-ternary
//       type: isActive ? "paragraph" : isList ? "list-item" : format,
//     };
//   }
//   Transforms.setNodes<SlateElement>(editor, newProperties);

//   if (!isActive && isList) {
//     const block = { type: format, children: [] };
//     Transforms.wrapNodes(editor, block);
//   }
// };

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// const isBlockActive = (editor: any, format: any, blockType = "type") => {
//   const { selection } = editor;
//   if (!selection) return false;

//   const [match] = Array.from(
//     Editor.nodes(editor, {
//       at: Editor.unhangRange(editor, selection),
//       match: (n: any | any[] | string[]) => {
//         const m = n as any;
//         return (
//           !Editor.isEditor(n) &&
//           SlateElement.isElement(n) &&
//           m[blockType] === format
//         );
//       },
//     })
//   );

//   return !!match;
// };

const isMarkActive = (editor: any, format: any) => {
  const marks = Editor.marks(editor) as any;
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    // eslint-disable-next-line no-param-reassign
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    // eslint-disable-next-line no-param-reassign
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    // eslint-disable-next-line no-param-reassign
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    // eslint-disable-next-line no-param-reassign
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

// const BlockButton = ({ format, icon }: any) => {
//   const editor = useSlate();
//   return (
//     <Button
//       active={isBlockActive(
//         editor,
//         format,
//         TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
//       )}
//       onMouseDown={(event: any) => {
//         event.preventDefault();
//         toggleBlock(editor, format);
//       }}
//     >
//       <Icon>{icon}</Icon>
//     </Button>
//   );
// };

// const MarkButton = ({ format, icon }: any) => {
//   const editor = useSlate();
//   return (
//     <Button
//       active={isMarkActive(editor, format)}
//       onMouseDown={(event: any) => {
//         event.preventDefault();
//         toggleMark(editor, format);
//       }}
//     >
//       <Icon>{icon}</Icon>
//     </Button>
//   );
// };
