import React, { useState } from "react";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import { apiWishDone } from "app/api";
import { FormWishDone } from "components/form";
import { useNavigate } from "react-router-dom";
import { messages, photoTypes, photoExts, photoFileSize } from "app/constants";

export const WrapperWishDone = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDsp();
  const navigate = useNavigate();

  const uuid = useSlc((state: RootState) => state.mWishDone);

  const onChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const name = file.name.toLowerCase() || "file.file";
      const type = file.type.toLowerCase() || "unknown";
      const ext = `.${name.split(".").pop()}` || "unknown";
      const size = file.size || 0;

      let isValid = true;
      if (!photoTypes.includes(type)) isValid = false;
      if (!photoExts.includes(ext)) isValid = false;
      if (size > photoFileSize) isValid = false;

      if (isValid) {
        const reader = new FileReader() as any;
        reader.onloadend = () => {
          setData(reader.result);
          setFile(file);
          setError("");
        };
        reader.readAsDataURL(file);
      } else {
        setError(messages.photo.error);
      }
    }
  };

  const onReset = () => {
    setData("");
    setFile(null);
    setError("");
  };

  const apiSuccsess = ({ status, data }: any, uuid: string) => {
    // console.log("API REFUSE WISH SUCCESS");
    if (status === 200 && data) {
      const currentPerformList = store.getState().dataProfilePrfs;
      const newList = currentPerformList.map((wish: any) => {
        if (wish.uuid === data.uuid) return data;
        return wish;
      });
      dispatch(action.dataProfilePrfsSet(newList));
      // console.log("CURRENT PERFORM WISHES", currentPerformList);

      dispatch(action.mWishDoneSet(""));
      navigate("/profile/performers");
    }
  };

  const apiError = (err: any) => {
    // console.log("API REFUSE WISH SUCCESS", err);
  };

  const onSubmit = () => {
    apiWishDone(uuid, file, (data: any) => apiSuccsess(data, uuid), apiError);
  };

  return (
    <div className="row mt-2">
      <div className="d-flex">
        <FormWishDone
          onChange={onChange}
          data={data}
          onReset={onReset}
          error={error}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
