import { createAction, createReducer } from "@reduxjs/toolkit";

const tokenSet = createAction<string>("tokenSet");

const token = createReducer("", (builder) => {
  builder.addCase(tokenSet, (state, action) => action.payload);
});

export const actionToken = {
  tokenSet,
};

export const reducerToken = {
  token,
};
