import React, { useState } from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import { ReactComponent as IconUnvisible } from "assets/images/icon-passw-unvisible.svg";
import { ReactComponent as IconVisible } from "assets/images/icon-passw-visible.svg";

export const InputPass = ({
  name,
  label,
  placeholder,
  role,
  autocomplete,
  onChange,
}: props) => {
  const [isTypePass, setTypePass] = useState(true);

  const switchType = () => setTypePass(!isTypePass);
  return (
    <Field name={name}>
      {({ field }: any) => (
        <BSform.Group className="form-group">
          {label && <BSform.Label>{label}</BSform.Label>}

          <div className="form-control-relative-wrapper">
            <BSform.Control
              name={name}
              type={isTypePass ? "password" : "text"}
              placeholder={placeholder}
              role={role}
              autoComplete={autocomplete}
              {...field}
              onInput={(e: any) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
            />
            <button
              className="form-control-passw-button"
              type="button"
              onClick={() => switchType()}
            >
              {isTypePass && <IconUnvisible />}
              {!isTypePass && <IconVisible />}
            </button>
          </div>

          <ErrorMessage name={name} component="div">
            {(msg) => <div className="input__error-text">{msg}</div>}
          </ErrorMessage>
        </BSform.Group>
      )}
    </Field>
  );
};

type props = {
  label?: string;
  name: string;
  type?: string;
  placeholder: string;
  role?: string;
  autocomplete?: string;
  onChange?: any;
};
