import React, { FC } from "react";
import { ModalBasic } from "components/modal";
import { action, useDsp, useSlc, RootState } from "app/store";
import { WrapperWishCreate } from "app/wrappers";

export const ModalWishCreate: FC = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mWishCreate)}
      onHide={() => dispatch(action.mWishCreateHide())}
      title="Добавить дело"
    >
      <WrapperWishCreate />
    </ModalBasic>
  );
};
