import { action, useDsp } from "app/store";
import { useApiProfile, useApiMyWishes } from "app/hooks";
import { Preloader } from "components/loader";
import { path } from "ramda";

export const AuthProvider = ({ children }: any) => {
  const dispatch = useDsp();
  const { loading, data, error }: any = useApiProfile();
  const myWishes: any = useApiMyWishes();

  const count = path(["data", "data", "results"], myWishes) || [];
  if (count.length > 0) dispatch(action.dataProfileWishesSet(count));

  if (loading) {
    dispatch(action.preloaderShow());
    return <Preloader />;
  }

  if (data && data.status && data.status === 200 && data.data) {
    const p = data.data.contact_phone;
    let phone = "";
    if (p) {
      phone = `${p[0]}${p[1]}(${p[2]}${p[3]}${p[4]})${p[5]}${p[6]}${p[7]}-${p[8]}${p[9]}-${p[10]}${p[11]}`;
    }
    dispatch(
      action.userSet({
        isAuth: true,
        name: data.data.name,
        birthDay: data.data.date_birthday,
        city: data.data.city,
        number: data.data?.personnel_number?.number || null,
        description: data.data.description || "",
        email: data.data.email || "some@email.com",
        image: data.data.image || "",
        uuid: data.data.uuid || "",
        balls: data.data.completed_wishes || 0,
        phone: phone,
        telega: data.data.contact_telegram || "",
        isSystemUser: data.data.is_system_user,
      })
    );
    // MODAL INTRO
    if (!localStorage.getItem("intro")) {
      dispatch(action.mIntroShow());
      localStorage.setItem("intro", "showed");
    }
    return <>{children}</>;
  }

  if (error) {
    dispatch(
      action.userSet({
        isAuth: false,
        name: "",
        birthDay: "",
        city: "",
        number: "",
        email: "",
        uuid: "",
        balls: 0,
      })
    );
    return <>{children}</>;
  }

  return <></>;
};
