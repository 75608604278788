import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx'
import Timer from 'easytimer.js';
import prependZero from 'app/utils/prependZero/prependZero';
// import useTimer from 'easytimer-react-hook';
// example https://albert-gonzalez.github.io/easytimer-react-hook/
// sources code of example https://github.com/albert-gonzalez/easytimer-react-hook/tree/main/example

const secondsToDays = (seconds: number) => {
  const d = Math.floor(seconds / (3600 * 24));
  return d;
}
const secondsToHours = (seconds: number) => {
  const h = Math.floor(seconds % (3600 * 24) / 3600);
  return h;
}
const secondsToMinutes = (seconds: number) => {
  const m = Math.floor(seconds % 3600 / 60);
  return m;
}
const secondsToSeconds = (seconds: number) => {
  const s = Math.floor(seconds % 60);
  return s;
}

type propsETI = {
  value: any,
  label: any,
  separator: any,
}

const EasyTimerItem: FC<propsETI> = ({ value, label, separator, ...props }) => (
    <span className='easy--timer__item'>
      <span className='easy--timer__value'>
        {label === 'days'
          ? value
          : prependZero(value)
        }
        {separator &&
          <span className='easy--timer__separator'>:</span>
        }
      </span>
    </span>
  )

type propsET = {
  timeType: any,
  startValue: number,
  onComplete: any,
  isCountdown?: boolean,
}

const EasyTimer: FC<propsET> = ({
  timeType = ['days', 'hours', 'minutes', 'seconds'],
  startValue = 30,
  onComplete,
  isCountdown = true,
  ...props
}) => {
  const classes = clsx('easy--timer', {
    'easy--timer_dhms': timeType.length === 4,
    'easy--timer_hms': timeType.length === 3,
    'easy--timer_ms': timeType.length === 2,
    'easy--timer_s': timeType.length === 1
  });

  const [days, setDays] = useState(secondsToDays(startValue));
  const [hours, setHours] = useState(secondsToHours(startValue));
  const [minutes, setMinutes] = useState(secondsToMinutes(startValue));
  const [seconds, setSeconds] = useState(secondsToSeconds(startValue));
  // const [timerView, setTimerView] = useState();
  
  const getValue = (name: any) => {
    switch (name) {
      case 'days':
        return days;
      case 'hours':
        return hours;
      case 'minutes':
        return minutes;
      default:
        return seconds;
    }
  }

  useEffect(
    () => {
      const timer = new Timer();
      const secondsUpdatedFC = () => {
        // setTimerView(timer.getTimeValues().toString(timeType));
        setDays(timer.getTimeValues().days);
        setHours(timer.getTimeValues().hours);
        setMinutes(timer.getTimeValues().minutes);
        setSeconds(timer.getTimeValues().seconds);
      }
      const targetAchievedFC = () => {
        onComplete();
      }
      timer.start({
        countdown: isCountdown,
        startValues: { days, hours, minutes, seconds }
      });
      timer.addEventListener("secondsUpdated", secondsUpdatedFC);
      timer.addEventListener("targetAchieved", targetAchievedFC);
      // Remove listeners on unmount
      return () => {
        timer.stop();
        timer.removeEventListener("secondsUpdated", secondsUpdatedFC);
        timer.removeEventListener("targetAchieved", targetAchievedFC);
      };
    },
    []
  );

  return (
    <span className={classes}>
      {timeType.map((el: any, idx: any) => (
        <EasyTimerItem
          key={idx}
          value={getValue(el)}
          label={el}
          separator={idx !== timeType.length - 1 ? true : false}
        />
      ))}
    </span>
  );
}

export default EasyTimer;