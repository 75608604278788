import React from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { SelectFilter } from "components/input";

export const FormProfilePerformFilter = () => {
  const dispatch = useDsp();
  const value = useSlc((state: RootState) => state.filterProfilePrfs);

  return (
    <form className="profile-wishes__filter">
      <SelectFilter
        name="filter"
        placeholder="Все статусы дел"
        defaultValue={value}
        onChange={(e: string) => dispatch(action.filterProfilePrfsSet(e))}
        options={[
          {
            value: "all",
            label: "Все статусы дел",
          },
          {
            value: "new",
            label: "Исполняется",
          },
          {
            value: "performer_committed",
            label: "Ожидает подтверждения выполнения",
          },
          {
            value: "approved",
            label: "Исполнен",
          },
        ]}
      />
    </form>
  );
};
