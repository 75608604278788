import React from "react-select";
import {
  ModalWishDetails,
  ModalFeedback,
  ModalOrganizerHelp,
  ModalWishCreate,
  ModalWishRemove,
  ModalWishRefuse,
  ModalWishDone,
  ModalWishAccept,
  ModalAvatarCrop,
  ModalInfo,
  ModalProcess,
  ModalIntro,
  ModalGallery,
  ModalGalleryView,
  ModalKruzok,
} from "components/modal/";

export const ModalProvider = () => (
  <>
    <ModalWishDetails />
    <ModalFeedback />
    <ModalOrganizerHelp />
    <ModalWishCreate />
    <ModalWishRemove />
    <ModalWishRefuse />
    <ModalWishDone />
    <ModalWishAccept />
    <ModalAvatarCrop />
    <ModalInfo />
    <ModalProcess />
    <ModalIntro />
    <ModalGallery />
    <ModalGalleryView />
    <ModalKruzok />
  </>
);
