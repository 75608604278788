import React, { useEffect } from "react";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import { apiWishApprove, apiProfileWishes, apiWishReject } from "app/api";
import { useApiMyWish } from "app/hooks";
import { Button } from "components/button";
// import { useNavigate } from "react-router-dom";
import { path } from "ramda";
import avatarDef from "assets/images/avatar-default-big.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { ReactComponent as ChevronLeft } from "../../../assets/images/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../assets/images/chevron-right.svg";

export const WrapperWishAccept = () => {
  const dispatch = useDsp();
  const uuid = useSlc((state: RootState) => state.mWishAccept);
  const wishData: any = useApiMyWish(uuid, 0).data;

  const baseurl = process.env.REACT_APP_API_BASE_URL;

  console.log("WISH DATA", wishData);

  const contracts: any = path(["data", "contracts"], wishData) || [];

  console.log("WISH CONTRACTS", contracts);

  const apiWishesSuccess = ({ status, data }: any) => {
    // console.log("API PROFILE WISHES SUCCESS", data);
    if (status === 200 && data) {
      dispatch(action.dataProfileWishesSet(data.results));
      dispatch(action.loaderProfileWishesSet(false));
    }
  };

  // APPROVE
  const apiApproveSuccess = ({ status }: any) => {
    // console.log("API APPROVE SUCCESS", status);
    if (status === 200) {
      dispatch(action.mWishAcceptSet(""));

      dispatch(action.hookReloadSet(Date.now()));

      dispatch(action.loaderProfileWishesSet(true));
      apiProfileWishes(apiWishesSuccess, () => {});
    }
  };
  const apiApproveError = (error: any) => {
    console.log("API APPROVE ERROR", error);
  };

  // REJECT
  const apiRejectSuccess = ({ status }: any) => {
    console.log("API Reject SUCCESS", status);
    if (status === 200) {
      dispatch(action.mWishAcceptSet(""));

      dispatch(action.hookReloadSet(Date.now()));

      dispatch(action.loaderProfileWishesSet(true));
      apiProfileWishes(apiWishesSuccess, () => {});
    }
  };

  const apiRejectError = (error: any) => {
    console.log("API Reject ERROR", error);
  };

  return (
    <>
      <div className="modal__row">
        <p className="modal-body__subtitle">
          Подтверди, что доброе дело действительно
          <br />
          сделано&nbsp;коллегой или отклони его
        </p>
      </div>

      <div className="modal-accept__contracts">
        <Swiper
          className="modal-accept__slider"
          modules={[Pagination, Navigation]}
          spaceBetween={0}
          slidesPerView="auto"
          slideToClickedSlide
          navigation={{
            prevEl: ".modal-accept__arrow--prev",
            nextEl: ".modal-accept__arrow--next",
          }}
        >
          {contracts &&
            contracts.map((contract: any, id: number) => {
              const name = path(["performer", "name"], contract) || "";
              const avatar = path(["performer", "image"], contract) || "";

              return (
                <SwiperSlide key={id} className="modal-accept__slide">
                  <div className="modal-accept__contract" key={id}>
                    <div className="modal-accept__contract-data">
                      <div className="modal-accept__contract-ava">
                        <img src={avatar ? avatar : avatarDef} alt="" />
                      </div>
                      <div className="modal-accept__contract-name">
                        <>{name}</>
                      </div>
                    </div>

                    <div className="modal-accept__preview">
                      <img src={baseurl + contract.verification_media} alt="" />
                    </div>

                    <div className="modal-accept__buttons">
                      {contract && contract.status === "new" && (
                        <Button
                          disabled={true}
                          className="modal-accept__contract-btn"
                        >
                          Дело выполняется
                        </Button>
                      )}

                      {contract &&
                        contract.status === "performer_committed" && (
                          <>
                            <Button
                              className="modal-accept__contract-btn"
                              onClick={() => {
                                apiWishApprove(
                                  contract.uuid,
                                  apiApproveSuccess,
                                  apiApproveError
                                );
                              }}
                            >
                              Подтвердить
                            </Button>

                            <Button
                              className="modal-accept__contract-btn"
                              onClick={() => {
                                apiWishReject(
                                  contract.uuid,
                                  apiRejectSuccess,
                                  apiRejectError
                                );
                              }}
                            >
                              Отклонить
                            </Button>
                          </>
                        )}

                      {contract && contract.status === "approved" && (
                        <Button
                          disabled={true}
                          className="modal-accept__contract-btn"
                        >
                          Исполнено
                        </Button>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

          {/* {wishList.slice(0, 10).map((el: any) => {
            const name = path(["author", "name"], el);
            const city: string = path(["author", "city", "name"], el) || "";
            return (
                </SwiperSlide>)
            })} */}
        </Swiper>

        <button className="modal-accept__arrow--prev" type="button">
          <ChevronLeft />
        </button>
        <button className="modal-accept__arrow--next" type="button">
          <ChevronRight />
        </button>
      </div>

      {/* <div className="d-flex">
        <Button
          type="button"
          onClick={() =>
            apiWishDone(uuid, (data: any) => apiSuccsess(data, uuid), apiError)
          }
        >
          Подтвердить
        </Button>
        <Button
          type="button"
          white
          onClick={() => dispatch(action.mWishDoneSet(""))}
        >
          Отменить
        </Button>
      </div> */}
    </>
  );
};
