import { urls, wishNotPerform } from "app/constants";
import { action } from "app/store";
import { axiosSecure } from "./_api";

export const apiWishPerform = (uuid: string, dispatch: any, navigate: any) => {
  axiosSecure({
    url: urls.wishPerform(uuid),
    method: "post",
  })
    .then(({ status, data }: any) => {
      // SUCCESS
      if (status === 200 && data) {
        if (uuid) navigate(`/user/wishes/${uuid}`);
      }
      dispatch(action.mWishDetailsHide());
    })
    .catch((error: any) => {
      // ERROR
      if (error.response && error.response.status === 404) {
        dispatch(action.mInfoSetData(wishNotPerform));
        dispatch(action.mInfoShow());
      }
      dispatch(action.mWishDetailsHide());
    });
};
