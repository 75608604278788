import React, { useState } from "react";
import { store, action, useDsp } from "app/store";
import { apiGalleryUpload } from "app/api";
import { Button } from "components/button";
import { useUrl, convertHeif } from "libheif-web";
import {
  galleryImgExts,
  galleryFileSize,
  galleryInfo,
  galleryError,
} from "app/constants";

export const WrapperGalleryUpload = () => {
  const dispatch = useDsp();
  const [errors, setErrors] = useState("");

  const [preview0, setPreview0] = useState();
  const [preview1, setPreview1] = useState();
  const [preview2, setPreview2] = useState();

  const [fileToUpload0, setToUpload0]: any = useState();
  const [fileToUpload1, setToUpload1]: any = useState();
  const [fileToUpload2, setToUpload2]: any = useState();

  const checkFileExt = (name: string) => {
    const ext = name.split(".").slice(-1)[0] || "unknown";
    if (galleryImgExts.includes(ext)) return true;
    return false;
  };

  const validFile = (file: any) => {
    if (file && file.size > galleryFileSize) {
      setErrors(galleryError);
      return false;
    }
    if (file && file.name && !checkFileExt(file.name.toLowerCase())) {
      setErrors(galleryError);
      return false;
    }
    return true;
  };

  const onSelectFile = async (event: any) => {
    setErrors("");
    if (event && event.target && event.target.files) {
      const file0 = event.target.files[0];
      const file1 = event.target.files[1];
      const file2 = event.target.files[2];

      console.log("--- NAME ---", validFile(file0));

      if (validFile(file0) && validFile(file1) && validFile(file2)) {
        if (file0) {
          const name = file0.name.toLowerCase() || "file.file";

          const ext = `.${name.split(".").pop()}` || "unknown";

          if (ext === ".heic") {
            // PRELOADER
            dispatch(
              action.mProcessSetData({
                title: "Обработка",
                message: "Конвертация HIEC в JPG",
              })
            );
            dispatch(action.mProcessShow());
            useUrl("assets/scripts/libheif.min.js");
            const imageJPG = await convertHeif(
              file0,
              "filename.jpg",
              "image/jpg"
            );
            if (imageJPG) {
              const file: any = URL.createObjectURL(imageJPG);
              setPreview0(file);
              setToUpload0(imageJPG);
            }
            dispatch(action.mProcessHide());
          } else {
            const file: any = URL.createObjectURL(file0);
            setPreview0(file);
            setToUpload0(file0);
          }
        }

        if (file1) {
          const name = file1.name.toLowerCase() || "file.file";
          const ext = `.${name.split(".").pop()}` || "unknown";

          if (ext === ".heic") {
            // PRELOADER
            dispatch(
              action.mProcessSetData({
                title: "Обработка",
                message: "Конвертация HIEC в JPG",
              })
            );
            dispatch(action.mProcessShow());
            useUrl("assets/scripts/libheif.min.js");
            const imageJPG = await convertHeif(
              file1,
              "filename.jpg",
              "image/jpg"
            );
            if (imageJPG) {
              const file: any = URL.createObjectURL(imageJPG);
              setPreview1(file);
              setToUpload1(imageJPG);
            }
            dispatch(action.mProcessHide());
          } else {
            const file: any = URL.createObjectURL(file1);
            setPreview1(file);
            setToUpload1(file1);
          }
        }

        if (file2) {
          const name = file2.name.toLowerCase() || "file.file";
          const ext = `.${name.split(".").pop()}` || "unknown";

          if (ext === ".heic") {
            // PRELOADER
            dispatch(
              action.mProcessSetData({
                title: "Обработка",
                message: "Конвертация HIEC в JPG",
              })
            );
            dispatch(action.mProcessShow());
            useUrl("assets/scripts/libheif.min.js");
            const imageJPG = await convertHeif(
              file2,
              "filename.jpg",
              "image/jpg"
            );
            if (imageJPG) {
              const file: any = URL.createObjectURL(imageJPG);
              setPreview2(file);
              setToUpload2(imageJPG);
            }
            dispatch(action.mProcessHide());
          } else {
            const file: any = URL.createObjectURL(file2);
            setPreview2(file);
            setToUpload2(file2);
          }
        }
      }
    }
  };

  const apiSuccess = ({ status, data }: any) => {
    console.log("API GALLERY SEND SUCCESS", data);
    if (status === 200 && data) {
      const currList = store.getState().galleryList || [];
      console.log("CURR LIST", currList);
      dispatch(action.galleryListSet([...currList, ...data]));
    }
    dispatch(action.mProcessHide());
  };

  const apiError = (error: any) => {
    console.log("API GALLERY SEND SUCCESS", error);
    dispatch(action.mProcessHide());
  };

  // API SEND
  const sendImages = () => {
    const formData = new FormData();
    if (fileToUpload0) formData.append("image", fileToUpload0);
    if (fileToUpload1) formData.append("image", fileToUpload1);
    if (fileToUpload2) formData.append("image", fileToUpload2);
    apiGalleryUpload(formData, apiSuccess, apiError);
    dispatch(
      action.mProcessSetData({
        title: "Загрузка",
        message: "Загрузка файла в галерею",
      })
    );
    dispatch(action.mProcessShow());
  };

  return (
    <form className="form-gallery-upload" action="">
      <div className="form-gallery-upload__previews">
        {preview0 && (
          <div className="form-gallery-upload__image">
            <img src={preview0} alt="" />
          </div>
        )}

        {preview1 && (
          <div className="form-gallery-upload__image">
            <img src={preview1} alt="" />
          </div>
        )}

        {preview2 && (
          <div className="form-gallery-upload__image">
            <img src={preview2} alt="" />
          </div>
        )}
      </div>

      <input
        className="form-gallery-upload__files"
        type="file"
        id="formGalleryUploadFiles"
        multiple
        onChange={onSelectFile}
        accept="image/jpg, image/jpeg, image/png, image/heif, .heic"
      />

      {!(preview0 || preview1 || preview2) && !errors && (
        <p className="form-gallery-upload__info">{galleryInfo}</p>
      )}

      {errors && <div className="form-gallery-upload__error">{errors}</div>}

      {!(preview0 || preview1 || preview2) && (
        <label
          className="button form-gallery-upload__button"
          htmlFor="formGalleryUploadFiles"
        >
          Загрузить фото
        </label>
      )}

      {(preview0 || preview1 || preview2) && (
        <Button
          className="form-gallery-upload__button"
          type="button"
          onClick={() => {
            sendImages();
            dispatch(action.mGalleryHide());
          }}
        >
          Опубликовать
        </Button>
      )}
    </form>
  );
};
