import { createAction, createReducer } from "@reduxjs/toolkit";

// SITY LIST
const cityListSet = createAction<any>("userSet");
const cityList = createReducer([], (builder) => {
  builder.addCase(cityListSet, (state, action) => action.payload);
});

// FAQ DATA
const faqDataSet = createAction<any>("faqDataSet");
const faqData = createReducer([], (builder) => {
  builder.addCase(faqDataSet, (state, action) => action.payload);
});

// WISHLISTS DATA
const wishlistDataSet = createAction<any>("wishlistDataSet");
const wishlistData = createReducer({}, (builder) => {
  builder.addCase(wishlistDataSet, (state, action) => action.payload);
});

// WISHPAGINATOR DATA
const wishPaginatorDataSet = createAction<any>("wishlistDataSet");
const wishPaginator = createReducer({}, (builder) => {
  builder.addCase(wishPaginatorDataSet, (state, action) => action.payload);
});

// RELOAD MAIN
const reloadMainSet = createAction<number>("reloadMainSet");
const reloadMain = createReducer(0, (builder) => {
  builder.addCase(reloadMainSet, (state, action) => action.payload);
});

// TINODE
const tiNodeSet = createAction<any>("tiNodeSet");
const tiNode = createReducer(0, (builder) => {
  builder.addCase(tiNodeSet, (state, action) => action.payload);
});

export const actionData = {
  cityListSet,
  faqDataSet,
  wishlistDataSet,
  wishPaginatorDataSet,
  //
  reloadMainSet,
  //
  tiNodeSet,
};

export const reducerData = {
  cityList,
  faqData,
  wishlistData,
  wishPaginator,
  //
  reloadMain,
  //
  tiNode,
};
