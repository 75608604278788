import React, { useEffect } from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { apiProfileWishes } from "app/api";
import { SectionProfileWishes } from "blocks/profile";

export const WrapperProfileWishes = () => {
  const dispatch = useDsp();
  const notifyList: any = useSlc((state: RootState) => state.notifyList) || [];

  const apiSuccess = ({ status, data }: any) => {
    // console.log("API PROFILE WISHES SUCCESS", data);
    if (status === 200 && data) {
      dispatch(action.dataProfileWishesSet(data.results));
      dispatch(action.loaderProfileWishesSet(false));
    }
  };

  const apiError = (err: any) => {
    // console.log("API PROFILE WISHES ERROR", err);
    dispatch(action.loaderProfileWishesSet(false));
  };

  useEffect(() => {
    apiProfileWishes(apiSuccess, apiError);
    dispatch(action.loaderProfileWishesSet(true));
  }, [notifyList]);

  return <SectionProfileWishes />;
};
