import React from "react";
import "./SectionArticles.scss";
import { LoaderMini } from "components/loader";
import { ArticleTile } from "components";
// import { NavLink } from "react-router-dom";
// import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";

export const SectionArticles = ({ loading, articles }: any) => {
  if (loading)
    return (
      <section className="articles">
        <LoaderMini />
      </section>
    );

  return (
    <section className="articles bg-decoration">
      <div className="bg-decoration__articles" />
      <div className="container articles__container">
        {articles &&
          articles.length > 0 &&
          articles.map((article: any, key: number) => (
            <ArticleTile article={article} />
          ))}

        {/* {articles &&
          articles.map((article: any, key: number) => {
            const articleId = article.id;
            return (
              <div className="article" key={key}>
                <div className="article__title">
                  <h3
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: article.title.rendered }}
                  />
                </div>
                <div
                  className="article__body--limited"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }}
                />
                <NavLink
                  className="article__read-link"
                  to={`/article/${articleId}`}
                >
                  Читать
                  <IconArrowRight />
                </NavLink>
              </div>
            );
          })} */}
      </div>
    </section>
  );
};
