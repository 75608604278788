// import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, ModalProvider } from "app/providers";
import { HeaderTop, HeaderMiddle } from "blocks/header";
import {
  Registration,
  Authorization,
  Main,
  WishLists,
  WishPaginator,
  Rating,
  Profile,
  Wishes,
  Wish,
  Performers,
  Performer,
  UserProfile,
  UserWishes,
  UserWishesDone,
  UserWish,
  Gallery,
  SomeGallery,
  GlobalChatRedirect,
  PageArticles,
  PageArticle,
  Page404,
} from "pages";
import { BrowserTracing } from "@sentry/tracing";
import { store } from "app/store";
import Footer from "blocks/footer/footer";
import { EmailConfirm } from "blocks/emailConfirm/emailConfirm";
import reportWebVitals from "./reportWebVitals";
import "./styles/scss/main.scss";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AuthProvider>
      <Router>
        <ModalProvider />
        <HeaderTop />
        <HeaderMiddle />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/sign-up" element={<Registration />} />
          <Route path="/sign-in" element={<Authorization />} />
          <Route path="/wishlists" element={<WishLists />} />
          {/*  */}
          <Route
            path="/wishlists/sotsialnaya-aktivnost"
            element={<WishPaginator />}
          />
          <Route
            path="/wishlists/sobyitiya-kruzhka"
            element={<WishPaginator />}
          />
          <Route
            path="/wishlists/korporativnoe-volontyorstvo"
            element={<WishPaginator />}
          />
          {/* <Route path="/wishlists/travel" element={<WishPaginator />} />
          <Route path="/wishlists/good" element={<WishPaginator />} />
          <Route path="/wishlists/home" element={<WishPaginator />} />
          <Route path="/wishlists/pet" element={<WishPaginator />} />
          <Route path="/wishlists/creation" element={<WishPaginator />} />
          <Route path="/wishlists/other" element={<WishPaginator />} />
          <Route path="/wishlists/birthday" element={<WishPaginator />} /> */}
          {/*  */}
          <Route path="/rating" element={<Rating />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/wishes" element={<Wishes />} />
          <Route path="/profile/wishes/:id" element={<Wish />} />
          <Route path="/profile/performers" element={<Performers />} />
          <Route path="/profile/performers/:id" element={<Performer />} />
          <Route path="/user/:id" element={<UserProfile />} />
          <Route path="/user/wishlists/:id" element={<UserWishes />} />
          <Route path="/user/wishlists_done/:id" element={<UserWishesDone />} />
          <Route path="/user/wishes/:id" element={<UserWish />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:id" element={<SomeGallery />} />
          <Route path="/mail_confirmation/:id" element={<EmailConfirm />} />
          <Route path="/global_chat" element={<GlobalChatRedirect />} />
          <Route path="/blog" element={<PageArticles />} />
          <Route path="/article/:id" element={<PageArticle />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  </Provider>
);

// function RequireAuth() {
//   const user = useSlc((state: RootState) => state.user)
//   let location = useLocation();
//   if (user.isAuth) return <Profile />
//   return <Navigate to="/" state={{ from: location }} replace />
// }

// function Redirect() {
//   let location = useLocation();
//   return <Navigate to="/" state={{ from: location }} replace />;
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
