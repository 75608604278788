import React from "react";
import { useDsp } from "app/store";
import { apiWishPerform } from "app/api";
import { SectionUserWishes } from "blocks/profile";
import { useNavigate } from "react-router-dom";

export const WrapperUserWishes = () => {
  const navigate = useNavigate();
  const dispatch = useDsp();

  const performWish = (uuid: string) => {
    apiWishPerform(uuid, dispatch, navigate);
  };
  return <SectionUserWishes performWish={performWish} />;
};
