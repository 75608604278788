import React from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { SelectFilter } from "components/input";

export const FormProfileWishesFilter = () => {
  const dispatch = useDsp();
  const value = useSlc((state: RootState) => state.filterProfileWishes);

  return (
    <form className="profile-wishes__filter">
      <SelectFilter
        name="filter"
        placeholder="Все статусы дел"
        defaultValue={value}
        onChange={(e: string) => dispatch(action.filterProfileWishesSet(e))}
        options={[
          {
            value: "all",
            label: "Все статусы дел",
          },
          {
            value: "on_verification",
            label: "На модерации",
          },
          {
            value: "declined",
            label: "Отклонено",
          },
          {
            value: "published",
            label: "Ждет исполнителя",
          },
          {
            value: "in_progress",
            label: "Исполняется",
          },
          {
            value: "waiting_proof",
            label: "Ожидает подтверждения выполнения",
          },
          {
            value: "done",
            label: "Исполнен",
          },
        ]}
      />
    </form>
  );
};
