import React from "react";
import { Field, Form } from "components/form";
import {
  InputText,
  SelectDefault,
  FilterCity,
  SelectHash,
} from "components/input";
import { Button } from "components/button";
import { RichText } from "../../richText/richtext";

export const FormWishProfile = ({
  user,
  wishStaus,
  categoryList,
  subCategoryList,
  categoryDefault,
  subCategoryDefault,
  performers,
  performersDefault,
  tagsDefObj,
  cityDefObj,
  wishRemove,
  wishAccept,
  contractList,
}: any) => {
  let canEdit = wishStaus === "on_verification" || wishStaus === "declined";
  let canSystemEdit =
    wishStaus === "on_verification" || wishStaus === "declined";
  const canReedit = wishStaus === "declined";
  const canRemove =
    wishStaus === "on_verification" ||
    (wishStaus === "published" && contractList.length === 0) ||
    wishStaus === "declined";

  const btnAcceptDisabledShow =
    (wishStaus === "waiting_for_performer_commit" &&
      contractList.length > 0 &&
      contractList.every((el: any) => el.status !== "performer_committed")) ||
    (wishStaus === "published" &&
      contractList.length > 0 &&
      contractList.every((el: any) => el.status !== "performer_committed")) ||
    (wishStaus === "done" &&
      contractList.length > 0 &&
      contractList.every((el: any) => el.status !== "performer_committed") &&
      contractList.some((el: any) => el.status === "new"));

  const btnAcceptShow =
    (wishStaus === "waiting_for_performer_commit" &&
      contractList.length > 0 &&
      contractList.some((el: any) => el.status === "performer_committed")) ||
    (wishStaus === "published" &&
      contractList.length > 0 &&
      contractList.some((el: any) => el.status === "performer_committed")) ||
    (wishStaus === "done" &&
      contractList.length > 0 &&
      contractList.some((el: any) => el.status === "performer_committed")) ||
    (wishStaus === "waiting_for_proof_of_work" &&
      contractList.length > 0 &&
      contractList.some((el: any) => el.status === "performer_committed"));

  if (user && user.isSystemUser) canSystemEdit = true;

  return (
    <Form className="form form-wish-profile">
      <div className="form-wish-profile__container">
        <div className="row">
          <div className="col-12 mb-3">
            <InputText
              label="Название"
              name="wishName"
              placeholder="Введи название"
              role="formWishCreateName"
              disabled={!canEdit}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            {/* <InputText
              as="textarea"
              label="Описание"
              name="wishDesc"
              placeholder="Введи описание"
              role="formWishCreateDescr"
              disabled={!canEdit}
            /> */}
            <RichText
              label="Описание"
              name="wishDesc"
              placeholder="Введи описание"
              role="formWishCreateDescr"
              disabled={!canSystemEdit}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <SelectDefault
              name="wishCategory"
              label="Категория"
              placeholder="Выбери категорию"
              id="formWishCreateCategory"
              options={categoryList}
              defaultValue={categoryDefault}
              disabled={!canEdit}
            />
          </div>
        </div>

        {user && user.isSystemUser && (
          <div className="row">
            <div className="col-12 mb-3">
              <SelectDefault
                name="wishSubCategory"
                label="Подкатегория"
                placeholder="Выбери подкатегорию"
                id="formWishCreateSubCategory"
                options={subCategoryList}
                defaultValue={subCategoryDefault}
                disabled={!canEdit}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 mb-3">
            <SelectHash
              name="tags"
              label="Хэштеги"
              placeholder="Например, “рыбалка”, “поход”"
              id="formWishEditTags"
              options={[]}
              customTags={[]}
              defaultValue={tagsDefObj}
              disabled={!canSystemEdit}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <Field name="city">
              {({ field }: any) => (
                <FilterCity
                  name="city"
                  label="Место"
                  placeholder="Любой город"
                  id="formWishCreateCity"
                  defaultValue={cityDefObj}
                  disabled={!canSystemEdit}
                  onChange={(e: any) => {
                    const event = {
                      target: {
                        name: "city",
                        value: e.map((el: any) => el.value),
                      },
                    };
                    field.onChange(event);
                  }}
                />
              )}
            </Field>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-2">
            <SelectDefault
              name="memberAmount"
              label="Кол-во исполнителей"
              placeholder="Кол-во"
              id="formWishCreateMemberCount"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
                {
                  value: "5",
                  label: "5",
                },
                {
                  value: "10",
                  label: "10",
                },
              ]}
              defaultValue={performersDefault}
              disabled={!canSystemEdit}
            />
          </div>
        </div>
      </div>

      <div className="d-flex mt-2">
        {canSystemEdit && (
          <Button
            type="submit"
            className="mx-auto form-wish-profile__act-button"
          >
            {canReedit && <span>Отправить на модерацию</span>}
            {!canReedit && <span>Сохранить изменения</span>}
          </Button>
        )}

        {canRemove && (
          <Button
            type="button"
            className="mx-auto form-wish-profile__act-button"
            onClick={() => wishRemove()}
          >
            Удалить дело
          </Button>
        )}

        {btnAcceptDisabledShow && (
          <Button type="button" disabled={true}>
            Принять дело
          </Button>
        )}

        {btnAcceptShow && (
          <Button type="button" onClick={() => wishAccept()}>
            Принять дело
          </Button>
        )}
      </div>
    </Form>
  );
};
