import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import CreatableSelect from "react-select/creatable";
import RSelect, { components } from "react-select";
import { Scrollbar } from "components/scrollbar/";
import { apiWishTags } from "app/api";

type props = {
  id: string;
  name: string;
  label?: string;
  placeholder: string;
  options?: any;
  disabled?: boolean;
  defaultValue?: any;
  cssPrefix?: string;
  customTags: any;
};

const DropdownIndicator = (props: any) => <></>;

const Menu = (props: any) => (
  <components.Menu {...props}>
    <Scrollbar>{props.children}</Scrollbar>
  </components.Menu>
);

export const SelectHash = ({
  id,
  name,
  label,
  placeholder,
  options,
  customTags,
  disabled,
  defaultValue,
  cssPrefix = "form-select",
}: props) => {
  const [defTags, setDefTags] = useState([]);

  const apiSuccess = ({ status, data }: any) => {
    // console.log("API WISH TAGS SUCCESS", data);
    if (status === 200 && data && data.length > 0) {
      const opt = data.map((tag: string) => ({
        value: tag,
        label: tag,
      }));
      setDefTags(opt);
    }
  };

  const apiError = (error: any) => {
    // console.log("API WISH TAGS SUCCESS", error);
  };

  useEffect(() => {
    // console.log("USEEFFECT WISH TAGS SUCCESS");
    apiWishTags(apiSuccess, apiError);
  }, []);

  return (
    <Field name={name}>
      {({ field }: any) => (
        <BSform.Group className="form-group">
          {label && <BSform.Label>{label}</BSform.Label>}

          <CreatableSelect
            // menuIsOpen={true}
            id={id}
            name={name}
            placeholder={placeholder}
            components={{ DropdownIndicator, Menu }}
            className="form-select-container form-select-hash-container"
            classNamePrefix={cssPrefix}
            isMulti
            formatCreateLabel={(x) => `${x}`}
            isDisabled={disabled}
            // options={options}
            options={[...customTags, ...defTags]}
            defaultValue={defaultValue}
            closeMenuOnScroll
            captureMenuScroll={false}
            menuShouldScrollIntoView={false}
            onChange={(e: any) => {
              // console.log("SELECT HASH", e);
              const event = {
                target: {
                  name,
                  value: e.map((el: any) => el.value),
                },
              };
              field.onChange(event);
            }}
            // isOptionDisabled={() => selectedOptions.length >= 3}
          />

          <ErrorMessage name={name} component="div">
            {(msg) => <div className="input__error-text">{msg}</div>}
          </ErrorMessage>
        </BSform.Group>
      )}
    </Field>
  );
};
