import React, { useState, useRef } from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { Button } from "components/button";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { canvasPreview } from "app/utils/avaCrop/canvasPreview";
import { useDebounceEffect } from "app/utils/avaCrop/useDebounceEffect";
import { apiAvatar, apiProfile } from "app/api";
import "react-image-crop/dist/ReactCrop.css";

export const WrapperAvatarCrop = () => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const dispatch = useDsp();
  const srcImage = useSlc((state: RootState) => state.mAvaCropData);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const minLine = width > height ? height : width;
    const widthX = (minLine / width) * 100;
    const heightY = (minLine / height) * 100;
    let positionX = width - minLine;
    if (positionX > 0) positionX = (positionX / 2 / width) * 100;
    let positionY = height - minLine;
    if (positionY > 0) positionY = (positionY / 2 / height) * 100;
    setCrop({
      unit: "%",
      x: positionX,
      y: positionY,
      width: widthX,
      height: heightY,
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  // API PROFILE
  const apiProfileSuccess = (data: any) => {
    // console.log("API PROFILE SUCCESS", data);
    if (data && data.status && data.status === 200 && data.data) {
      // console.log("API PROFILE SUCCESS", data);
      dispatch(
        action.userSet({
          isAuth: true,
          name: data.data.name,
          birthDay: data.data.date_birthday,
          city: data.data.city,
          number: data.data.personnel_number.number,
          description: data.data.description || "Привет!",
          email: data.data.email || "some@email.com",
          image: data.data.image,
        })
      );
    }
  };

  // API AVATAR
  const apiSuccess = (data: any) => {
    console.log("API AVATAR UPLOAD SUCCESS", data);
    apiProfile(apiProfileSuccess, () => {});
  };

  const apiError = (error: any) => {
    console.log("API AVATAR UPLOAD ERROR", error);
  };

  function dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  return (
    <div className="ReactCrop">
      {!!srcImage && (
        <ReactCrop
          crop={crop}
          circularCrop={true}
          keepSelection={true}
          minWidth={100}
          minHeight={100}
          className="ReactCrop__cropper"
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={1}
        >
          <img ref={imgRef} alt="Crop me" src={srcImage} onLoad={onImageLoad} />
        </ReactCrop>
      )}
      <div className="ReactCrop__result">
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
      <Button
        className="mt-3 mx-auto"
        onClick={() => {
          if (previewCanvasRef && previewCanvasRef.current) {
            const img = previewCanvasRef.current.toDataURL("image/jpeg", 1.0);
            const blob = dataURItoBlob(img);
            // blob.name = "avatar.jpg";
            // console.log(blob);
            const fd = new FormData();
            fd.append("image", blob);
            apiAvatar(fd, apiSuccess, apiError);
            // const link = document.createElement("a");
            // if (typeof link.download === "string") {
            //   document.body.appendChild(link);
            //   link.download = "img.jpg";
            //   link.href = img;
            //   link.click();
            //   document.body.removeChild(link);
            dispatch(action.mAvaCropHide());
            // dispatch(action.userAvatar())
            // }
          }
        }}
      >
        Сохранить
      </Button>
    </div>
  );
};
