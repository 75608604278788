import { urls } from "app/constants";
import { useApi } from "./index";
// import { dtoFormikServerErr, dtoProfile } from "app/utils/dto";

export const useApiMainDashboard = ({ reload }: any) =>
  useApi({ url: urls.mainDashboard, method: "get", reload });

export const useApiMainBirthday = ({ reload }: any) =>
  useApi({ url: urls.mainBirthday, method: "get", reload });

export const useApiMainFaq = () => useApi({ url: urls.faq, method: "get" });
