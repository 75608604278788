import React from "react";
import { ReactComponent as IconPrev } from "assets/images/icon-arrow-left.svg";
import { ReactComponent as IconNext } from "assets/images/icon-arrow-right.svg";

type props = {
  filterCount: number;
  pagination: any;
  onPaginatorClick: any;
};

export const Paginator = ({
  filterCount,
  pagination,
  onPaginatorClick,
}: props) => {
  const { page, count } = pagination;
  const perPage = filterCount;
  const pages = Math.ceil(count / perPage);

  const prev = page - 1;
  const next = page + 1;

  // console.log("==================");
  // console.log("PAGER PAGE", page);
  // console.log("PAGER PAGES", pages);
  // console.log("PAGER PREV", prev);
  // console.log("PAGER NEXT", next);

  const onClick = (p: number) =>
    onPaginatorClick({
      page: p || 1,
      count,
    });

  // FIRST POSITION
  if (pages > 8 && (page === 1 || page === 2 || page === 3 || page === 4)) {
    const lastButton = pages;

    return (
      <div className="paginator-data__list">
        {prev < 1 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            disabled
          >
            <IconPrev />
          </button>
        )}
        {prev > 0 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            onClick={() => onClick(prev)}
          >
            <IconPrev />
          </button>
        )}

        <button
          key={1}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === 1 ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(1)}
        >
          {1}
        </button>

        <button
          key={2}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === 2 ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(2)}
        >
          {2}
        </button>

        <button
          key={3}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === 3 ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(3)}
        >
          {3}
        </button>

        <button
          key={4}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === 4 ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(4)}
        >
          {4}
        </button>

        <button
          key={5}
          type="button"
          className="btn-reset paginator-data__button"
          onClick={() => onClick(5)}
        >
          {5}
        </button>

        <button
          key={9999}
          type="button"
          className="btn-reset paginator-data__button"
        >
          ...
        </button>

        <button
          key={lastButton}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === lastButton ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(lastButton)}
        >
          {lastButton}
        </button>

        {next && page < pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            onClick={() => onClick(next)}
          >
            <IconNext />
          </button>
        )}

        {next && page >= pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            disabled
          >
            <IconNext />
          </button>
        )}
      </div>
    );
  }

  // END POSITION
  if (
    pages > 8 &&
    (page === pages || page === pages - 1 || page === pages - 2)
  ) {
    const lastButton = pages;

    return (
      <div className="paginator-data__list">
        {prev < 1 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            disabled
          >
            <IconPrev />
          </button>
        )}
        {prev > 0 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            onClick={() => onClick(prev)}
          >
            <IconPrev />
          </button>
        )}

        <button
          key={1}
          type="button"
          className="btn-reset paginator-data__button"
          onClick={() => onClick(1)}
        >
          {1}
        </button>

        <button
          key={9999}
          type="button"
          className="btn-reset paginator-data__button"
        >
          ...
        </button>

        <button
          key={lastButton - 3}
          type="button"
          className="btn-reset paginator-data__button"
          onClick={() => onClick(lastButton - 3)}
        >
          {lastButton - 3}
        </button>

        <button
          key={lastButton - 2}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === lastButton - 2 ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(lastButton - 2)}
        >
          {lastButton - 2}
        </button>

        <button
          key={lastButton - 1}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === lastButton - 1 ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(lastButton - 1)}
        >
          {lastButton - 1}
        </button>

        <button
          key={lastButton}
          type="button"
          className={`btn-reset paginator-data__button ${
            page === lastButton ? "paginator-data__button--active" : ""
          }`}
          onClick={() => onClick(lastButton)}
        >
          {lastButton}
        </button>

        {next && page < pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            onClick={() => onClick(next)}
          >
            <IconNext />
          </button>
        )}

        {next && page >= pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            disabled
          >
            <IconNext />
          </button>
        )}
      </div>
    );
  }

  // CENTER POSITION
  if (pages > 8 && (page > 4 || page < pages - 2)) {
    // const midButton = Math.ceil(pages / 2);
    // console.log("----", pages);
    // console.log("----", midButton);
    const lastButton = pages;

    return (
      <div className="paginator-data__list">
        {prev < 1 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            disabled
          >
            <IconPrev />
          </button>
        )}
        {prev > 0 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            onClick={() => onClick(prev)}
          >
            <IconPrev />
          </button>
        )}

        <button
          key={1}
          type="button"
          className="btn-reset paginator-data__button"
          onClick={() => onClick(1)}
        >
          {1}
        </button>

        <button
          key={9998}
          type="button"
          className="btn-reset paginator-data__button"
        >
          ...
        </button>

        <button
          key={page - 1}
          type="button"
          className="btn-reset paginator-data__button"
          onClick={() => onClick(page - 1)}
        >
          {page - 1}
        </button>

        <button
          key={page}
          type="button"
          className="btn-reset paginator-data__button paginator-data__button--active"
          onClick={() => onClick(page)}
        >
          {page}
        </button>

        <button
          key={page + 1}
          type="button"
          className="btn-reset paginator-data__button"
          onClick={() => onClick(page + 1)}
        >
          {page + 1}
        </button>

        <button
          key={9999}
          type="button"
          className="btn-reset paginator-data__button"
        >
          ...
        </button>

        <button
          key={lastButton}
          type="button"
          className="btn-reset paginator-data__button"
          onClick={() => onClick(lastButton)}
        >
          {lastButton}
        </button>

        {next && page < pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            onClick={() => onClick(next)}
          >
            <IconNext />
          </button>
        )}

        {next && page >= pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            disabled
          >
            <IconNext />
          </button>
        )}
      </div>
    );
  }

  // LESS THAN 7 PAGES
  if (pages > 1)
    return (
      <div className="paginator-data__list">
        {prev < 1 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            disabled
          >
            <IconPrev />
          </button>
        )}
        {prev > 0 && (
          <button
            type="button"
            className="btn-reset paginator-data__button-prev"
            onClick={() => onClick(prev)}
          >
            <IconPrev />
          </button>
        )}

        {[...new Array(pages).fill(0)].map((el: number, i: number) => (
          <button
            key={i}
            type="button"
            className={`btn-reset paginator-data__button ${
              page === i + 1 ? "paginator-data__button--active" : ""
            }`}
            onClick={() => onClick(i + 1)}
          >
            {i + 1}
          </button>
        ))}

        {next && page < pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            onClick={() => onClick(next)}
          >
            <IconNext />
          </button>
        )}

        {next && page >= pages && (
          <button
            type="button"
            className="btn-reset paginator-data__button-next"
            disabled
          >
            <IconNext />
          </button>
        )}
      </div>
    );
  return <></>;
};
