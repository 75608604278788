import React, { useEffect } from "react";
import { apiWishList, apiWishPerform } from "app/api";
import { useSlc, action, useDsp, RootState } from "app/store";
import { SectionWishLists } from "blocks/wishlists";
import { useNavigate } from "react-router-dom";

type apiParameters = {
  city: string;
  name: string;
};

export const WrapperWishLists = () => {
  const dispatch = useDsp();
  const navigate = useNavigate();

  const data: any = useSlc((state: RootState) => state.wishlistData) || {};
  const filterCity = useSlc((state: RootState) => state.fWishListsCity);
  const filterName = useSlc((state: RootState) => state.fWishListsName);

  const params: apiParameters = {
    city: filterCity.value,
    name: filterName.value,
  };

  const performWish = (uuid: string) => {
    apiWishPerform(uuid, dispatch, navigate);
  };

  const apiSuccess = ({ status, data }: any) => {
    // console.log("API WISH LIST SUCCESS", data);
    if (status === 200 && data) {
      dispatch(action.wishlistDataSet(data));
      dispatch(action.loaderWishListsHide());
    }
  };

  const apiError = (err: any) => {
    // console.log("API WISH LIST ERROR", err);
    dispatch(action.loaderWishListsHide());
  };

  useEffect(() => {
    apiWishList(params, apiSuccess, apiError);
    dispatch(action.loaderWishListsShow());
  }, [filterCity.value, filterName.value]);

  return <SectionWishLists data={data} performWish={performWish} />;
};
