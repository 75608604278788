import React from "react";
import "./SectionArticles.scss";
import { LoaderMini } from "components/loader";
import { NavLink } from "react-router-dom";

export const SectionArticle = ({ loading, article }: any) => {
  if (loading)
    return (
      <section className="articles">
        <LoaderMini />
      </section>
    );

  return (
    <section className="articles bg-decoration">
      <div className="bg-decoration__articles" />
      <div className="container articles__container">
        {article && (
          <div className="article">
            <div className="article__title">
              <h2
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: article.title.rendered }}
              />
            </div>
            <div
              className="article__body"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: article.content.rendered }}
            />
          </div>
        )}
        <NavLink className="button article__read-blog-button" to="/blog">
          Читать другие новости
        </NavLink>
      </div>
    </section>
  );
};
