import React, { useEffect } from "react";
import { action, useDsp } from "app/store";
import { apiProfileWishes } from "app/api";
import { useParams } from "react-router-dom";
import { SectionProfilePerformer } from "blocks/profile";

export const WrapperProfilePerformer = () => {
  const dispatch = useDsp();

  useEffect(() => {
    dispatch(action.loaderProfilePrfSet(false));
  }, []);

  return <SectionProfilePerformer />;
};
