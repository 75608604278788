import { urls } from "app/constants";
import { dtoLogin } from "app/utils/dto";
import { axiosBase } from "./_api";
import { path } from "ramda";

export const apiLogin = (
  params: any,
  success: any,
  unsuccess: any,
  setErrors: any
) => {
  axiosBase({
    url: urls.login,
    method: "post",
    data: dtoLogin(params),
  })
    .then(success)
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 401
      ) {
        // console.log("API LOGIN ERROR 401", error);

        let fieldErr: any = path(["response", "data", "errors"], error) || {};
        let codeErr: any = path(["response", "data", "code"], error) || "";

        if (codeErr === "authentication_failed")
          fieldErr.formError = ["Некорректные учетные данные"];

        if (codeErr === "user_is_not_active")
          fieldErr.formError = [
            "Вы не активировали аккаунт перейдя по ссылке в письме",
          ];

        if (codeErr === "user_is_blocked")
          fieldErr.formError = [
            "Пользователь заблокирован. Обратитесь в поддержку.",
          ];

        setErrors(fieldErr);
      }
      return unsuccess(error);
    });
};
