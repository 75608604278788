export const profileLinks = [
  {
    id: "129e4oesw48936",
    title: "Профиль",
    route: "/profile",
    pageReset: false,
    visible: true,
  },
  {
    id: "129e4wpk148937",
    title: "Мои добрые дела",
    route: "/profile/wishes",
    pageReset: false,
    visible: true,
  },
  {
    id: "129e4qd3848938",
    title: "Помогаю коллегам",
    route: "/profile/performers",
    pageReset: false,
    visible: true,
  },
];

export const wishlistLinks = [
  {
    id: "12908732948932",
    title: "Все категории",
    route: "/wishlists",
    pageReset: true,
    visible: true,
  },
  {
    id: "12908721948932",
    title: "Социальная активность",
    route: "/wishlists/sotsialnaya-aktivnost",
    pageReset: true,
    visible: true,
  },
  {
    id: "1290rfpa848932",
    title: "События кружка",
    route: "/wishlists/sobyitiya-kruzhka",
    pageReset: true,
    visible: true,
  },
  {
    id: "12908732m78932",
    title: "Корпоративное волонтерство",
    route: "/wishlists/korporativnoe-volontyorstvo",
    pageReset: true,
    visible: true,
  },
  // {
  //   id: "1290ky32948932",
  //   title: "Путешествия",
  //   route: "/wishlists/travel",
  //   pageReset: true,
  //   visible: true,
  // },
  // {
  //   id: "1290873p94893m",
  //   title: "Добро",
  //   route: "/wishlists/good",
  //   pageReset: true,
  //   visible: true,
  // },
  // {
  //   id: "1290873f94893s",
  //   title: "Дом",
  //   route: "/wishlists/home",
  //   pageReset: true,
  //   visible: true,
  // },
  // {
  //   id: "129087l294893w",
  //   title: "Питомцы",
  //   route: "/wishlists/pet",
  //   pageReset: true,
  //   visible: true,
  // },
  // {
  //   id: "129087wp94893r",
  //   title: "Творчество",
  //   route: "/wishlists/creation",
  //   pageReset: true,
  //   visible: true,
  // },
  // {
  //   id: "1290ov32948971",
  //   title: "Другое",
  //   route: "/wishlists/other",
  //   pageReset: true,
  //   visible: true,
  // },
  // {
  //   id: "129087esw48937",
  //   title: "Выполни дело коллеги в его день рождения!",
  //   route: "/wishlists/birthday",
  //   pageReset: true,
  //   visible: false,
  // },
];
