export const urls = {
  // FEEDBACK
  feedbackThemes: "/api/feedback/themes/",
  feedback: "/api/feedback/",

  // AUTH
  reg: "/api/auth/register/",
  login: "/api/auth/login/",
  recovery: "/api/auth/change-password/",
  activate: "/api/auth/verify/email/",
  token: "/api/auth/token/refresh/",

  // MAIN
  mainDashboard: "/api/wishes/dashboard",
  mainBirthday: "/api/wishes/dashboard/for_birthday",
  faq: "/api/questions/",

  // CITY
  city: (letters: string) => `/api/city/${letters}/`,

  // WISH
  wishCategories: "/api/wishes/categories",
  wishTags: "/api/wishes/tags/popular",
  wishCreate: "/api/wishes/create",
  wishDelete: (uuid: string) => `/api/wishes/my/${uuid}`,
  wishPerform: (uuid: string) => `/api/wishes/${uuid}/reply`,
  wishMy: (uuid: string) => `/api/wishes/my/${uuid}`,
  wishUser: (uuid: string) => `/api/wishes/replies/${uuid}`,
  wishDone: (uuid: string) => `/api/wishes/contracts/${uuid}/mark_as_done`,
  wishApprove: (uuid: string) => `/api/wishes/contracts/${uuid}/approve`,
  wishRefuse: (uuid: string) => `/api/wishes/contracts/${uuid}/cancel`,
  wishReject: (uuid: string) => `/api/wishes/contracts/${uuid}/reject`,

  // WISHLISTS
  wishList: "/api/wishes/grouped_by_categories",
  wishPaginator: (slug: string) => `/api/wishes/by_category/${slug}`,
  wishBirthPaginator: "/api/wishes/by_birthday",

  // RATING
  userRating: "/api/wishes/top_users",

  // PROFILE
  profile: "/api/auth/profile",
  profileWishes: "/api/wishes/my",
  profilePerformers: "/api/wishes/replies",
  profileAvatar: "/api/auth/profile/image",

  // USER
  userProfile: (uuid: string) => `/api/auth/profile/colleague/${uuid}`,
  userWishes: (uuid: string) => `/api/wishes/colleague/${uuid}`,
  userWishesDone: (uuid: string) => `/api/wishes/colleague/${uuid}/done`,

  // GALLERY
  myGallery: "/api/gallery/",
  deleteGallery: (uuid: string) => `api/gallery/${uuid}`,
  userGallery: (uuid: string) => `/api/gallery/colleague/${uuid}`,

  // CHAT
  chatUser: "/api/chat/users/search",
  chatSecrets: "/api/chat/secrets",
  chatGlobal: "/api/chat/login",

  // NOTIFY
  notifyList: "/api/auth/notifications/dashboard",
  notifyCheckAll: "/api/auth/notifications/read_all",
  notifyRead: "/api/auth/notifications/read",

  articles: "https://test-wp.test-digital.ru/?rest_route=/wp/v2/posts",
};
