import React from "react";
import { useSlc, RootState } from "app/store";
import { profileLinks } from "app/constants";
import { SubNav } from "components/nav";
import { WrapperProfilePerformer } from "app/wrappers";
import RedirectTo from "components/RedirectTo/RedirectTo";

export const Performer = () => {
  const isLoading = useSlc((state: RootState) => state.preloader);
  const isAuth = useSlc((state: RootState) => state.user.isAuth);

  if (isLoading) return <></>;
  if (isAuth)
    return (
      <>
        <SubNav links={profileLinks} />
        <WrapperProfilePerformer />
      </>
    );
  return <RedirectTo path="/sign-in" />;
};
