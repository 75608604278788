import React, { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevronLeft } from "../../assets/images/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron-right.svg";

type props = {
  className?: string,
  variant?: string,
}

const SwiperArrow: FC<props> = (props) => {
  const classes = clsx('swiper--arrow', props.className, 'swiper--' + props.variant);

  return (
    <div className={classes}>
      {props.variant === 'prev' && 
        <ChevronLeft/>
      }
      {props.variant === 'next' && 
        <ChevronRight/>
      }
    </div>
  )
}

export default SwiperArrow;
