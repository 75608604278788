import React from "react";
import { Form, Field, ErrorMessage, BSform } from "components/form";
import { useDsp, action } from "app/store";
import { InputText, InputPass } from "components/input";
import { Button } from "elements/button";
import ButtonText from "elements/button/ButtonText/ButtonText";

export const FormLogin = () => {
  const dispatch = useDsp();

  return (
    <Form className="form mt-3">
      <div className="row">
        <div className="col-12 mb-2 mb-sm-3">
          <InputText
            label="E-mail"
            name="email"
            placeholder="Введи личный e-mail"
            role="formLoginEmail"
          />
        </div>
        <div className="col-12 mb-2">
          <InputPass
            label="Пароль"
            name="password"
            type="password"
            placeholder="Введи пароль"
            role="formLoginPassword"
            autocomplete="password"
          />
        </div>
        <div className="col-12 mb-3">
          <ButtonText
            onClick={() => dispatch(action.authVariantSet("recovery"))}
          >
            Не помню пароль
          </ButtonText>
          <br />
          <ButtonText
            onClick={() => dispatch(action.authVariantSet("activate"))}
          >
            Активировать аккаунт
          </ButtonText>
        </div>
        <ErrorMessage name="formError" component="div">
          {(msg) => <div className="input__error-text">{msg}</div>}
        </ErrorMessage>
      </div>

      <Button
        className="mx-auto ms-sm-auto me-sm-0"
        type="submit"
        role="formLoginButtonSubmit"
      >
        Войти
      </Button>
    </Form>
  );
};
