import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

type props = {
  className?: string,
  children?: ReactNode,
}

const CardIcon: FC<props> = (props) => {
  const classes = clsx('card--icon', props.className);

  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

export default CardIcon;
