import React, { FC } from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { Formik, FormRec } from "components/form";
import { schemas, values } from "app/utils/schemas";
import { apiRecovery } from "app/api";

export const WrapperRec = () => {
  const dispatch = useDsp();
  const recoveryIsShow = useSlc((state: RootState) => state.recovery);

  const apiSuccess = ({ data, status }: any, email: string) => {
    // console.log("API RECOVERY SUCCESS", data);
    if (status === 201) {
      dispatch(action.recoveredEmailSet(email));
      dispatch(action.recoveryHide());
    }
  };

  const apiError = (err: any) => {
    // console.log("API RECOVERY ERROR", err);
  };

  return (
    <Formik
      initialValues={values.rec}
      validationSchema={schemas.rec}
      onSubmit={(params, { setErrors }) =>
        apiRecovery(
          params,
          (data: any) => apiSuccess(data, params.email),
          apiError,
          setErrors
        )
      }
    >
      <FormRec recoveryIsShow={recoveryIsShow} />
    </Formik>
  );
};
