import React, { FC } from "react";
import { Field, ErrorMessage, BSform } from "components/form";
import RSelect, { components } from "react-select";
import { Scrollbar } from "components/scrollbar/";
import { ReactComponent as IconDefault } from "assets/images/icon-select-arrow.svg";

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <IconDefault />
  </components.DropdownIndicator>
);

const Menu = (props: any) => {
  const count = props.options.length;
  if (count < 4)
    return <components.Menu {...props}>{props.children}</components.Menu>;
  return (
    <components.Menu {...props}>
      <Scrollbar>{props.children}</Scrollbar>
    </components.Menu>
  );
};

type props = {
  prefix?: string;
  label?: string;
  name: string;
  placeholder: string;
  options: any[];
  id: string;
  multiple?: boolean;
  onChange?: any;
  isSearchable?: boolean;
};

export const Select: FC<props> = ({
  prefix = "form-select",
  id,
  name,
  label,
  placeholder,
  multiple,
  options,
  onChange,
  isSearchable = false,
}) => (
  <Field name={name}>
    {({ field }: any) => (
      <BSform.Group className="form-group">
        {label && <BSform.Label>{label}</BSform.Label>}

        <RSelect
          // menuIsOpen={true}
          id={id}
          name={name}
          placeholder={placeholder}
          className="form-select-container"
          classNamePrefix={prefix}
          options={options}
          isMulti={multiple}
          components={{ DropdownIndicator, Menu }}
          captureMenuScroll={false}
          menuShouldScrollIntoView={false}
          isSearchable={isSearchable}
          onChange={(e: any) => {
            const event = {
              target: {
                name,
                value: Array.isArray(e) ? e.map((l: any) => l.value) : e.value,
              },
            };
            field.onChange(event);
            if (onChange) onChange(event);
          }}
        />

        <ErrorMessage name={name} component="div">
          {(msg) => <div className="input__error-text">{msg}</div>}
        </ErrorMessage>
      </BSform.Group>
    )}
  </Field>
);
