import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

type props = {
  children: ReactNode,
  url: string,
  className?: string,
  arrow?: boolean,
  weight?: number
}

const TextLink: FC<props> = (props) => {
  const classes = clsx('text--link', props.className, {
    'text--link_w500': props.weight === 500
  });

  return (
    <Link className={classes} to={props.url}>
      <span>{props.children}</span>
      {props.arrow &&
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M6.471 3.529 10.943 8 6.47 12.471l-.942-.942L9.057 8 5.53 4.471l.942-.942Z" clipRule="evenodd"/>
        </svg>
      }
    </Link>
  )
}

export default TextLink;
