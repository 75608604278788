import React from "react";
import { Chat } from "../chat/chat";
import { ChatPlugProfile } from "../chatPlug/chatPlug";

export const ChatProfile = ({ chatSecret, chatTopic, willConnect }: any) => {
  console.log("ChatProfile");

  return (
    <div className="chat-profile">
      {chatSecret && chatTopic && willConnect && (
        <Chat
          variant="profile"
          chatSecret={chatSecret}
          chatTopic={chatTopic}
          willConnect={willConnect}
        />
      )}
      {!chatSecret || !chatTopic || (!willConnect && <ChatPlugProfile />)}
    </div>
  );
};
