import React from "react";
import avatarDefaultBig from "assets/images/avatar-default-big.svg";

export const WishMakers = () => (
  <div className="wish-makers">
    <div className="wish-makers__avatar">
      <img src={avatarDefaultBig} alt="" />
    </div>
    <div className="wish-makers__info">
      <div className="wish-makers__title">Исполнитель</div>
      {/* <div className="wish-makers__name">Длинная Фамилия Длинное Имя</div> */}
      <div className="wish-makers__no-performers">
        Твое дело еще не принято на исполнение
      </div>
      {/* <div className="wish-makers__city">
        <span>Город:</span> Москва
      </div> */}
      {/* <div className="wish-makers__amount">
        <span>Выполнил дел:</span> 150
      </div> */}
    </div>
  </div>
);
