import { urls } from "app/constants";
import { useApi } from "./index";
// import { dtoFormikServerErr, dtoProfile } from "app/utils/dto";

export const useApiUserProfile = (uuid: string) =>
  useApi({ url: urls.userProfile(uuid), method: "get" });

export const useApiUserWishes = (id: string, reload: number) =>
  useApi({ url: urls.userWishes(id), method: "get", reload });

export const useApiUserWishesDone = (id: string, reload: number) =>
  useApi({ url: urls.userWishesDone(id), method: "get", reload });

export const useApiFriendGallery = (uuid: string) =>
  useApi({ url: urls.userGallery(uuid), method: "get" });
