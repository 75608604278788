import React, { FC } from "react";
import { Link } from "react-router-dom";

type props = {
  title: string;
  description: string;
  imageDesktop: string;
  imageMobile: string;
  url: string;
  count: number;
};

const CategoryTile: FC<props> = (props) => (
  <Link className="category--tile" to={props.url}>
    <div
      className="category--tile__title"
      dangerouslySetInnerHTML={{ __html: props.title }}
    ></div>
    <div
      className="category--tile__description"
      dangerouslySetInnerHTML={{ __html: props.description }}
    ></div>
    {/* <img
      className="category--tile__decor"
      src={props.imageDesktop}
      alt="Decor"
    /> */}

    {/* <div className="category--tile__counter">
      <span>Всего дел:</span>
      <span>{props.count}</span>
    </div> */}
    {/* <div className="category--tile__link">
      <span>Перейти</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M6.471 3.529 10.943 8 6.47 12.471l-.942-.942L9.057 8 5.53 4.471l.942-.942Z" clipRule="evenodd"/>
      </svg>
    </div> */}
  </Link>
);

export default CategoryTile;
