import { createAction, createReducer } from "@reduxjs/toolkit";

const userSet = createAction<any>("userSet");

const user = createReducer(
  {
    isAuth: false,
    name: "",
    birthDay: null,
    city: {
      name: "",
      uuid: "",
    },
    number: "",
    description: "",
    email: "",
    image: "",
    uuid: "",
    balls: 0,
    phone: "",
    telega: "",
    isSystemUser: false,
  },
  (builder) => {
    builder.addCase(userSet, (state, action) => action.payload);
  }
);

export const actionUser = {
  userSet,
};

export const reducerUser = {
  user,
};
