import React, { FC } from "react";
import { action, useSlc, useDsp, RootState } from "app/store";
import { useLocation } from "react-router-dom";

const Footer: FC = () => {
  const isAuth = useSlc((state: RootState) => state.user.isAuth);
  const currRoute = useLocation().pathname;
  const dispatch = useDsp();
  if (isAuth || currRoute === "/sign-up" || currRoute === "/sign-in")
    return (
      <footer className="footer" role="footer">
        <div className="footer__container container">
          <div className="footer__aside">
            <div className="footer__copyright">
              &copy; 2020-2025 ПАО «МегаФон»
            </div>
          </div>
          <div className="footer__center">
            <nav className="footer__nav">
              <button
                className="footer__nav--item footer__button-feedback"
                type="button"
                onClick={() => dispatch(action.mFeedbackShow())}
              >
                Помощь
              </button>
              {/* <button
                className="footer__nav--item"
                type="button"
                onClick={() => dispatch(action.mOrganizerHelpShow())}
              >
                Попросить помощь у организатора
              </button> */}
              {/* <a
                className="footer__nav--item"
                href={`${process.env.PUBLIC_URL}/assets/documents/kruzhok_instructions_small.pdf`}
                download
              >
                Правила платформы
              </a> */}
              <a
                className="footer__nav--item"
                href={`${process.env.PUBLIC_URL}/assets/documents/agreement.pdf`}
                target="_blank"
                rel="noreferrer"
                // download
              >
                Пользовательское соглашение
              </a>
            </nav>
          </div>
          {/* <div className="footer__aside footer__aside_mh" /> */}
        </div>
      </footer>
    );
  return <></>;
};

export default Footer;
