import React from "react";
import Headline from "components/headline/Headline";
import { Button } from "elements/button";
import { action, useDsp } from "app/store";

const Intro = () => {
  const dispatch = useDsp();

  return (
    <div className="intro">
      <div className="container">
        <Headline variant="xl" className="intro__headline">
          Твое пространство
          <br />
          для больших и&nbsp;маленьких <br />
          добрых дел
        </Headline>
        {/* <div className="intro__descr">
          <p>Мы — больше, чем коллеги!</p>
        </div> */}

        {/* <Headline variant="xl" className="intro__headline">
          Мегафон &mdash; это мы:
          <br /> <span>партнеры, наставники, друзья и&nbsp;эксперты</span>
        </Headline>
        <div className="intro__descr">
          <p>Мы — больше, чем коллеги!</p>
        </div> */}

        <Button
          className="intro__button"
          type="button"
          onClick={() => dispatch(action.mWishCreateShow())}
        >
          Начать
        </Button>
      </div>
    </div>
  );
};

export default Intro;
