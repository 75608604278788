import React, { FC, ReactNode, MouseEventHandler } from "react";
import clsx from "clsx";

type props = {
  type?: "button" | "submit" | "reset" | undefined;
  border?: boolean;
  white?: boolean;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  role?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const Button: FC<props> = (props) => {
  const classes = clsx("button", props.className, {
    button_border: props.border,
    button_white: props.white,
  });

  return (
    <button
      className={classes}
      disabled={props.disabled}
      type={props.type}
      role={props.role}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
};
