import React, { useState, useEffect, useRef } from "react";
import { apiNotifyList, apiNotifyRead, apiNotifyCheckAll } from "app/api";
import { useLocation, useParams } from "react-router-dom";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import PerfectScrollbar from "react-perfect-scrollbar";
import iconNotify from "assets/images/icon-notify.svg";
import iconNotifyActive from "assets/images/icon-notify-active.svg";
import iconNotifyWish from "assets/images/icon-notify-wish.svg";
import iconNotifyMessage from "assets/images/icon-notify-message.svg";
import { path } from "ramda";

// const notifyMessage = (type: string): { title: string; text: string } => {
//   if (type === "wish_expiration")
//     return {
//       title: "Истекает срок исполнения виша",
//       text: "К сожалению, срок исполнения твоего виша истекает.",
//     };
//   if (type === "wish_published")
//     return {
//       title: "Твой виш принят",
//       text: "Твой виш принят. Теперь осталось подождать, пока твои коллеги откликнутся на него.",
//     };
//   if (type === "wish_rejected")
//     return {
//       title: "Твой виш отклонен",
//       text: "К сожалению, твой виш не прошел модерацию. Для уточнения причин отклонения обратись в поддержку",
//     };
//   if (type === "wish_new_contract")
//     return {
//       title: "Твой виш взяли в работу",
//       text: "Твой виш взят на исполнение коллегой, самое время обсудить все детали.",
//     };
//   if (type === "wish_contract_canceled")
//     return {
//       title: "Исполнитель отказался от виша",
//       text: "К сожалению, твой коллега отказался от исполнения виша. Твое объявление теперь видно остальным коллегам.",
//     };
//   if (type === "wish_contract_committed")
//     return {
//       title: "Подтвердите исполнение виша",
//       text: "Коллега исполнил твой виш. Перейди, чтобы принять или отклонить исполнение.",
//     };
//   if (type === "wish_contract_commitment_rejected")
//     return {
//       title: "Автор виша не принял твой отклик на исполнение",
//       text: "Твой коллега не подтвердил исполнение виша",
//     };
//   if (type === "wish_contract_commitment_approved")
//     return {
//       title: "Автор виша принял твой отклик на исполнение",
//       text: "Твой коллега подтвердил исполнение виша",
//     };
//   if (type === "wish_new_message")
//     return {
//       title: "Новое сообщение",
//       text: "В чате появилось новое непрочитанное сообщение.",
//     };
//   return { title: "", text: "" };
// };

const notifyMessage = (type: string): { title: string; text: string } => {
  if (type === "wish_expiration")
    return {
      title: "Истекает срок исполнения доброго дела",
      text: "К сожалению, срок исполнения твоего доброго дела истекает.",
    };
  if (type === "wish_published")
    return {
      title: "Твое доброе дело принято",
      text: "Твое доброе дело принято. Теперь осталось подождать, пока твои коллеги откликнутся на него.",
    };
  if (type === "wish_rejected")
    return {
      title: "Твое доброе дело отклонено",
      text: "К сожалению, твое доброе дело не прошло модерацию. Для уточнения причин отклонения обратись в поддержку",
    };
  if (type === "wish_new_contract")
    return {
      title: "Твое доброе дело взяли в работу",
      text: "Твое доброе дело взято на исполнение коллегой, самое время обсудить все детали.",
    };
  if (type === "wish_contract_canceled")
    return {
      title: "Исполнитель отказался от доброго дела",
      text: "К сожалению, твой коллега отказался от исполнения доброго дела. Твое объявление теперь видно остальным коллегам.",
    };
  if (type === "wish_contract_committed")
    return {
      title: "Подтвердите исполнение доброго дела",
      text: "Коллега исполнил твое доброе дело. Перейди, чтобы принять или отклонить исполнение.",
    };
  if (type === "wish_contract_commitment_rejected")
    return {
      title: "Автор доброго дела не принял твой отклик на исполнение",
      text: "Твой коллега не подтвердил исполнение доброго дела",
    };
  if (type === "wish_contract_commitment_approved")
    return {
      title: "Автор доброго дела принял твой отклик на исполнение",
      text: "Твой коллега подтвердил исполнение доброго дела",
    };
  if (type === "wish_new_message")
    return {
      title: "Новое сообщение",
      text: "В чате появилось новое непрочитанное сообщение.",
    };
  return { title: "", text: "" };
};

const notifyCreateLink = (type: string, id: string, myWish: boolean) => {
  if (type === "wish_expiration") return `/profile/wishes/${id}`;
  if (type === "wish_published") return `/profile/wishes/${id}`;
  if (type === "wish_rejected") return `/profile/wishes/${id}`;
  if (type === "wish_new_contract") return `/profile/wishes/${id}`;
  if (type === "wish_contract_canceled") return `/profile/wishes/${id}`;
  if (type === "wish_contract_committed") return `/profile/wishes/${id}`;
  if (type === "wish_contract_commitment_rejected") return `/user/wishes/${id}`;
  if (type === "wish_contract_commitment_approved") return `/user/wishes/${id}`;
  if (type === "wish_new_message")
    return myWish ? `/profile/wishes/${id}` : `/user/wishes/${id}`;
  return "";
};

const notifyCreateIcon = (type: string) => {
  if (type === "wish_expiration") return iconNotifyWish;
  if (type === "wish_published") return iconNotifyWish;
  if (type === "wish_rejected") return iconNotifyWish;
  if (type === "wish_new_contract") return iconNotifyWish;
  if (type === "wish_contract_canceled") return iconNotifyMessage;
  if (type === "wish_contract_committed") return iconNotifyWish;
  if (type === "wish_contract_commitment_rejected") return iconNotifyMessage;
  if (type === "wish_contract_commitment_approved") return iconNotifyMessage;
  if (type === "wish_new_message") return iconNotifyMessage;
  return "";
};

const IconClose = () => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.59706 3.59608C3.82021 3.37292 4.18202 3.37292 4.40518 3.59608L8.00112 7.19157L11.5971 3.59608C11.803 3.39009 12.1272 3.37424 12.3513 3.54854L12.4052 3.59608C12.6283 3.81924 12.6283 4.18104 12.4052 4.4042L8.80969 8.00014L12.4052 11.5961C12.6112 11.8021 12.627 12.1262 12.4527 12.3504L12.4052 12.4042C12.182 12.6274 11.8202 12.6274 11.5971 12.4042L8.00112 8.80871L4.40518 12.4042C4.19919 12.6102 3.87505 12.626 3.65089 12.4517L3.59706 12.4042C3.3739 12.181 3.3739 11.8192 3.59706 11.5961L7.19254 8.00014L3.59706 4.4042C3.39106 4.19821 3.37522 3.87408 3.54952 3.64991L3.59706 3.59608Z"
      fill="inherit"
    />
  </svg>
);

export const HeaderNotify = () => {
  // console.log("HEADER NOTIFY");
  const dispatch = useDsp();
  const scrollRef: any = useRef();
  const panelRef: any = useRef();
  const [notifyOpen, setnotifyOpen] = useState(false);
  const [canLoad, setCanLoad] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const notifyList: any = useSlc((state: RootState) => state.notifyList) || [];
  const myUuid = store.getState().user.uuid || [];
  const location = useLocation();

  // console.log("+++ LOCATION", location);
  // console.log("+++ WISHID", wishId);

  const showHideNotifyPanel = () => {
    const isOpen = !notifyOpen;
    setnotifyOpen(isOpen);
  };

  setTimeout(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.updateScroll();
    }
  }, 300);

  const notifyUnreadList = notifyList.filter(
    (notifyItem: any) => notifyItem.unread
  );
  const notifyReadedList = notifyList.filter(
    (notifyItem: any) => !notifyItem.unread
  );

  const apiNotifyListError = (error: any) => {
    console.log(error);
  };

  const notifySetRead = (notifyId: string) => {
    apiNotifyRead(
      { ids: [notifyId] },
      (data: any) => {
        const ids = path(["data"], data) || [];
        const oldList = store.getState().notifyList || [];
        if (ids.length > 0) {
          const readId = ids[0] || 0;
          let newList = oldList.map((nItem: any) => {
            if (nItem.id === readId) {
              const item = {
                id: nItem.id,
                referenceable: nItem.referenceable,
                type: nItem.type,
                referenceable_type: nItem.referenceable_type,
                description: nItem.description,
                unread: false,
              };
              return item;
            }
            return nItem;
          });
          newList = newList
            .sort((a: any, b: any) => b.id - a.id)
            .sort((a: any, b: any) => {
              if (a.unread && !b.unread) return 1;
              if (!a.unread && b.unread) return -1;
              return 0;
            });
          dispatch(action.notifyListSet(newList));
        }
      },
      apiNotifyListError
    );
  };

  const notifySetReadAll = () => {
    apiNotifyCheckAll((data: any) => {
      // console.log("=============== data", data);
      if (data.status === 200) {
        const oldList = store.getState().notifyList || [];
        let newList = oldList.map((nItem: any) => {
          if (nItem.unread) {
            return {
              id: nItem.id,
              referenceable: nItem.referenceable,
              type: nItem.type,
              referenceable_type: nItem.referenceable_type,
              description: "",
              unread: false,
            };
          }
          return nItem;
        });
        newList = newList
          .sort((a: any, b: any) => b.id - a.id)
          .sort((a: any, b: any) => {
            if (a.unread && !b.unread) return 1;
            if (!a.unread && b.unread) return -1;
            return 0;
          });
        dispatch(action.notifyListSet(newList));
      }
    }, apiNotifyListError);
  };

  const apiNotifyListSuccess = (data: any) => {
    const list: any = path(["data", "results"], data) || [];
    if (list.length > 0) {
      const oldList = store.getState().notifyList || [];
      let newList: any = [];

      list.map((newIt: any) => {
        if (
          oldList.filter((oldIt: any) => oldIt.id === newIt.id).length === 0
        ) {
          newList.push(newIt);
        }
      });

      if (newList.length > 0) {
        // CHECK IF CHAT OPEN
        const location = window.location.href;
        const urlParam = location.split("/").reverse()[0];
        const wishId = urlParam.length === 36 ? urlParam : "";

        if (
          location.includes("/user/wishes/") ||
          location.includes("/profile/wishes/")
        ) {
          const readIds: any = [];
          newList = newList.map((nItem: any) => {
            // console.log("////////////////////////////////", location);
            // console.log("////////////////////////////////", nItem);
            // console.log("////////////////////////////////", wishId);
            if (
              nItem.referenceable.uuid === wishId &&
              nItem.type === "wish_new_message" &&
              nItem.unread
            ) {
              readIds.push(nItem.id);
              return {
                created_at: nItem.created_at,
                id: nItem.id,
                referenceable: nItem.referenceable,
                type: nItem.type,
                referenceable_type: nItem.referenceable_type,
                description: "",
                unread: false,
                divider: true,
              };
            }
            return nItem;
          });

          if (readIds.length > 0) {
            apiNotifyRead(
              { ids: readIds },
              (data: any) => console.log(data),
              (error: any) => console.log(error)
            );
          }
        }

        //
        const combileList = [...newList, ...oldList]
          .sort((a: any, b: any) => b.id - a.id)
          .sort((a: any, b: any) => {
            if (a.unread && !b.unread) return 1;
            if (!a.unread && b.unread) return -1;
            return 0;
          });
        dispatch(action.notifyListSet(combileList));
      }
      setTimeout(() => setCanLoad(true), 1000);
    }
  };

  // REQUEST AND SET TIMEOUT
  useEffect(() => {
    console.log("USE EFFECT");
    apiNotifyList(1, apiNotifyListSuccess, apiNotifyListError);
    setInterval(() => {
      apiNotifyList(1, apiNotifyListSuccess, apiNotifyListError);
    }, 20000);
  }, []);

  // CHECK WISH
  useEffect(() => {
    const location = window.location.href;
    if (location.includes("/profile/wishes/")) {
      console.log("CHECK UNREAD MESSAGES");
      const oldList = store.getState().notifyList || [];
      const urlParam = location.split("/").reverse()[0];
      const wishId = urlParam.length === 36 ? urlParam : "";

      if (
        location.includes("/user/wishes/") ||
        location.includes("/profile/wishes/")
      ) {
        const readIds: any = [];
        const newList = oldList.map((nItem: any) => {
          // console.log("////////////////////////////////", location);
          // console.log("////////////////////////////////", nItem);
          // console.log("////////////////////////////////", wishId);
          if (
            nItem.referenceable.uuid === wishId &&
            nItem.type === "wish_new_message" &&
            nItem.unread
          ) {
            readIds.push(nItem.id);
            return {
              created_at: nItem.created_at,
              id: nItem.id,
              referenceable: nItem.referenceable,
              type: nItem.type,
              referenceable_type: nItem.referenceable_type,
              description: "",
              unread: false,
              divider: true,
            };
          }
          return nItem;
        });

        if (readIds.length > 0) {
          apiNotifyRead(
            { ids: readIds },
            (data: any) => console.log(data),
            (error: any) => console.log(error)
          );

          //
          const combileList = newList
            .sort((a: any, b: any) => b.id - a.id)
            .sort((a: any, b: any) => {
              if (a.unread && !b.unread) return 1;
              if (!a.unread && b.unread) return -1;
              return 0;
            });
          dispatch(action.notifyListSet(combileList));
        }
      }
    }
  }, [location]);

  // CHECK CLICK OUTSIDE
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target) &&
        !event.target.classList.contains("notify__show-button")
      ) {
        setnotifyOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="notify__wrapper">
      <button
        type="button"
        className={`btn-reset notify__show-button ${
          notifyOpen ? "active" : ""
        }`}
        onClick={() => showHideNotifyPanel()}
      >
        <img
          // src={notifyUnreadList.length > 0 ? iconNotifyActive : iconNotify}
          src={iconNotify}
          alt=""
        />
        {notifyUnreadList.length > 0 && <span>{notifyUnreadList.length}</span>}
      </button>

      {notifyOpen && (
        <div className="notify__notify-panel" ref={panelRef}>
          <PerfectScrollbar
            className="scrollbars__main-container notify__scrollbar"
            options={{
              suppressScrollX: false,
              wheelPropagation: false,
              wheelSpeed: 0.5,
            }}
            ref={scrollRef}
            onYReachEnd={() => {
              if (canLoad && notifyList.length > 25) {
                console.log("SCROLL END");
                setCanLoad(false);
                apiNotifyList(
                  currPage + 1,
                  (data: any) => {
                    setCurrPage(currPage + 1);
                    apiNotifyListSuccess(data);
                  },
                  apiNotifyListError
                );
              }
            }}
          >
            <div>
              {notifyUnreadList && notifyUnreadList.length > 0 && (
                <>
                  <div className="notify__counter">
                    Новые ({notifyUnreadList.length})
                    <button
                      type="button"
                      className="btn-reset notify__reset-button"
                      onClick={(e) => {
                        e.preventDefault();
                        notifySetReadAll();
                      }}
                    >
                      Отметить все как прочитанные
                    </button>
                  </div>
                  {notifyUnreadList.map((notifyItem: any, id: number) => {
                    const notifyType: string = notifyItem.type || "";
                    const authorId: string =
                      notifyItem.referenceable.author.uuid;
                    const notifyId: string = notifyItem.id || "";
                    const notifyWishId: string =
                      notifyItem.referenceable.uuid || "";
                    const notifyLink: string =
                      notifyCreateLink(
                        notifyType,
                        notifyWishId,
                        myUuid === authorId
                      ) || "";
                    const notifyIcon: string =
                      notifyCreateIcon(notifyType) || "";
                    const notifyTitle: string =
                      notifyMessage(notifyType).title || "";
                    const notifyDescr: string =
                      notifyMessage(notifyType).text || "";
                    return (
                      <a key={id} className="notify__link" href={notifyLink}>
                        <button
                          type="button"
                          className="notify__notify-item new"
                          onClick={() => setnotifyOpen(false)}
                        >
                          <img src={notifyIcon} alt="" />
                          <div className="notify__notify-item-data">
                            <h3>{notifyTitle}</h3>
                            <p>{notifyDescr}</p>
                            <p hidden>ID: {notifyId}</p>
                          </div>
                        </button>
                        <button
                          type="button"
                          className="btn-reset notify__read-button"
                          onClick={(e) => {
                            e.preventDefault();
                            notifySetRead(notifyId);
                          }}
                        >
                          <IconClose />
                        </button>
                      </a>
                    );
                  })}
                </>
              )}

              {notifyReadedList && notifyReadedList.length > 0 && (
                <>
                  <div className="notify__counter">
                    Прочитанные ({notifyReadedList.length})
                  </div>
                  {notifyReadedList.map((notifyItem: any, id: number) => {
                    const notifyType: string = notifyItem.type || "";
                    const authorId: string =
                      notifyItem.referenceable.author.uuid;
                    const notifyId: string = notifyItem.id || "";
                    const notifyWishId: string =
                      notifyItem.referenceable.uuid || "";
                    const notifyLink: string =
                      notifyCreateLink(
                        notifyType,
                        notifyWishId,
                        myUuid === authorId
                      ) || "";
                    const notifyIcon: string =
                      notifyCreateIcon(notifyType) || "";
                    const notifyTitle: string =
                      notifyMessage(notifyType).title || "";
                    const notifyDescr: string =
                      notifyMessage(notifyType).text || "";
                    return (
                      <a key={id} className="notify__link" href={notifyLink}>
                        <button
                          type="button"
                          className="notify__notify-item"
                          onClick={() => setnotifyOpen(false)}
                        >
                          <img src={notifyIcon} alt="" />
                          <div className="notify__notify-item-data">
                            <h3>{notifyTitle}</h3>
                            <p>{notifyDescr}</p>
                            <p hidden>ID: {notifyId}</p>
                          </div>
                        </button>
                      </a>
                    );
                  })}
                </>
              )}

              {notifyList && notifyList.length === 0 && (
                <div className="notify__counter notify__counter--empty">
                  У вас пока нет уведомлений
                </div>
              )}
            </div>
          </PerfectScrollbar>
        </div>
      )}
    </div>
  );
};
