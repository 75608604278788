import React from "react";
import { store, action, useDsp } from "app/store";
import { Formik, FormWishCreate } from "components/form";
import { schemas, values } from "app/utils/schemas";
import { apiWishCreate } from "app/api";
import { useApiCategories } from "app/hooks";

export const WrapperWishCreate = () => {
  const dispatch = useDsp();
  const wishCategories: any = useApiCategories().data;
  const { user } = store.getState();

  let categoryList = [];
  let subCategoryList: any[] = [];

  if (
    wishCategories &&
    wishCategories.status === 200 &&
    wishCategories.data &&
    wishCategories.data.length > 0
  ) {
    // IF SYSTEM USER
    if (user && user.isSystemUser) {
      categoryList = wishCategories.data
        .filter((i: any) => i.is_system === true)
        .map((l: any) => ({
          label: l.name,
          value: l.slug,
        }));

      wishCategories.data
        .filter((i: any) => i.is_system === true)
        .forEach((i: any) => {
          if (
            i &&
            i.available_sub_category &&
            i.available_sub_category.length
          ) {
            i.available_sub_category.forEach((s: any) => {
              let label = "_";
              if (s === "small_deal") label = "0,5 баллов";
              if (s === "big_deal") label = "1 балл";
              subCategoryList.push({
                label: label,
                value: s,
              });
            });
          }
        });

      // ELSE
    } else {
      categoryList = wishCategories.data
        .filter((i: any) => i.is_system !== true)
        .map((l: any) => ({
          label: l.name,
          value: l.slug,
        }));
    }
  }

  const apiSuccess = ({ status, data }: any) => {
    // console.log("API WISH CREATE SUCCESS");
    if (status === 201 && data) {
      dispatch(action.mWishCreateHide());

      dispatch(
        action.mInfoSetData({
          title: "Благодарим за созданное дело",
          message: `Сейчас оно проходит модерацию. После этого его увидят твои коллеги. Также ты его сможешь найти в разделе "Мои добрые дела" личного кабинета.`,
        })
      );
      dispatch(action.mInfoShow());

      //
      const currWishes = store.getState().dataProfileWishes;
      dispatch(action.dataProfileWishesSet([data, ...currWishes]));
    }
  };
  const apiError = () => {
    // console.log("API WISH CREATE ERROR");
  };

  return (
    <Formik
      initialValues={values.wishCreate}
      validationSchema={
        user.isSystemUser ? schemas.wishSubCreate : schemas.wishCreate
      }
      onSubmit={(params, { setErrors }) => {
        apiWishCreate(params, apiSuccess, apiError, setErrors);
      }}
    >
      <FormWishCreate
        categoryList={categoryList}
        subCategoryList={subCategoryList}
        user={user}
      />
    </Formik>
  );
};
