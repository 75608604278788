import React from "react";
import { FilterCity, FilterCitySingle, SelectFilter } from "components/input";
import { Button, ButtonModalClose } from "elements/button";
import { useSlc, action, useDsp, RootState } from "app/store";

export const FormRatingFilter = () => {
  const dispatch = useDsp();
  const filterIsShow = useSlc((state: RootState) => state.fRatingIsShow);
  const defaultCity = useSlc((state: RootState) => state.fRatingCity);
  const defaultPeriod = useSlc((state: RootState) => state.fRatingPeriod);

  const filterHide = () => {
    document.body.style.overflow = "";
    dispatch(action.fRatingHide());
  };

  // const apiUnsuccess = (err: any) => {
  //   console.log("APIUNSUCCESS DATA", err);
  // };

  return (
    <form
      className={
        filterIsShow ? "rating-filter rating-filter--show" : "rating-filter"
      }
    >
      <ButtonModalClose onClick={filterHide} />
      <div className="rating-filter__city">
        <FilterCitySingle
          className="form-select-filter-container"
          name="city"
          placeholder="Выбери город"
          defaultOptions={[
            {
              value: "",
              label: "Все города",
            },
          ]}
          defaultValue={defaultCity}
          onChange={(e: string) => {
            dispatch(
              action.fWishPaginationSet({
                page: 1,
                count: 1,
              })
            );
            dispatch(action.fRatingCitySet(e));
          }}
        />
      </div>
      <div className="rating-filter__period">
        <SelectFilter
          name="filter"
          placeholder="За все время"
          defaultValue={defaultPeriod}
          onChange={(e: string) => {
            // dispatch(
            //   action.fWishPaginationSet({
            //     page: 1,
            //     count: 1,
            //   })
            // );
            dispatch(action.fRatingPeriodSet(e));
          }}
          options={[
            {
              value: "all",
              label: "За все время",
            },
            {
              value: "week",
              label: "За неделю",
            },
            {
              value: "month",
              label: "За месяц",
            },
          ]}
        />
      </div>

      <Button className="rating-filter__apply-button" onClick={filterHide}>
        Применить изменения
      </Button>
    </form>
  );
};
