import React from "react";
import { Link } from "react-router-dom";
import { useSlc, action, useDsp, RootState } from "app/store";
import { FormProfileWishesFilter } from "components/form";
import WishTile from "components/wishTile/WishTile";
import { Button } from "components/button";
import { LoaderMini } from "components/loader";
import ImageEmpty from "assets/images/image-profile-wishes-empty@2x.png";
import ImageMobileEmpty from "assets/images/image-mobile-profile-wishes-empty@2x-min.png";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import { ReactComponent as IconStaf } from "assets/images/32pt_staf.svg";

const WishList = ({ title, data, filter, dispatch }: any) => {
  // console.log("========== data", data);
  // console.log("========== filter", filter);

  const filteredData = data.filter((el: any) => {
    // Все
    if (filter.value === "all") return true;
    // На модерации
    if (filter.value === "on_verification" && el.status === "on_verification")
      return true;
    // Отклонен
    if (
      filter.value === "declined" &&
      el.status === "declined" &&
      el.contracts.length === 0
    )
      return true;
    // Ждет исполнителя
    if (
      (filter.value === "published" &&
        el.status === "published" &&
        el.contracts.length === 0) ||
      (filter.value === "published" &&
        el.status === "published" &&
        el.contracts.length > 0 &&
        !el.contracts.some((el: any) => el.status === "performer_committed"))
    )
      return true;
    // Исполняется
    if (
      filter.value === "in_progress" &&
      el.status === "published" &&
      el.contracts.length > 0 &&
      el.contracts.some((el: any) => el.status === "new") &&
      !el.contracts.some((el: any) => el.status === "performer_committed")
    )
      return true;

    // Исполняется
    if (
      filter.value === "in_progress" &&
      el.status === "waiting_for_performer_commit" &&
      el.contracts.length > 0 &&
      el.contracts.some((el: any) => el.status === "new") &&
      !el.contracts.some((el: any) => el.status === "performer_committed")
    )
      return true;

    // ожидает подтверждения выполнения
    if (
      filter.value === "waiting_proof" &&
      el.status === "waiting_for_proof_of_work" &&
      el.contracts.length > 0
    )
      return true;

    // Исполнен
    if (
      filter.value === "done" &&
      el.status === "done" &&
      el.contracts.length > 0
    )
      return true;

    return false;
  });

  return (
    <>
      <p className="profile-wishes__has-notify">
        {title} ({filteredData.length})
      </p>
      <div className="profile-wishes__tile-list">
        {filteredData.map((el: any) => {
          const tags: any = [{ text: el.category.name, type: "" }];
          const expire: number = Date.parse(el.will_expire_at);
          const now: number = Date.now();

          if (el.status === "on_verification") {
            tags.unshift({
              text: "На модерации",
              type: "await",
            });
          }

          if (el.status === "declined") {
            tags.unshift({
              text: "Отклонен",
              type: "rejected",
            });
          }

          if (
            (el.status === "published" && el.contracts.length === 0) ||
            (el.status === "done" &&
              el.contracts.length > 0 &&
              !el.contracts.some(
                (el: any) => el.status === "performer_committed"
              ) &&
              !el.contracts.some((el: any) => el.status === "new") &&
              el.contracts.every((el: any) => el.status === "approved") &&
              el.contracts.length < el.max_performer_amount) ||
            (el.status === "published" &&
              el.contracts.length > 0 &&
              !el.contracts.some(
                (el: any) => el.status === "performer_committed"
              ))
          ) {
            tags.unshift({
              text: "Ждет исполнителя",
              type: "await",
            });
          }

          if (
            (el.status === "published" &&
              el.contracts.length > 0 &&
              el.contracts.some(
                (el: any) => el.status === "performer_committed"
              )) ||
            (el.status === "done" &&
              el.contracts.length > 0 &&
              !el.contracts.some(
                (el: any) => el.status === "performer_committed"
              ) &&
              el.contracts.some((el: any) => el.status === "new"))
          ) {
            tags.unshift({
              text: "Исполняется",
              type: "await",
            });
          }

          if (
            el.status === "waiting_for_performer_commit" &&
            el.contracts.length > 0 &&
            !el.contracts.some((el: any) => el.status === "performer_committed")
          ) {
            tags.unshift({
              text: "Исполняется",
              type: "await",
            });
          }

          if (
            (el.status === "waiting_for_proof_of_work" &&
              el.contracts.length > 0) ||
            (el.status === "published" &&
              el.contracts.length > 0 &&
              el.contracts.some(
                (el: any) => el.status === "performer_committed"
              )) ||
            (el.status === "done" &&
              el.contracts.length > 0 &&
              el.contracts.some(
                (el: any) => el.status === "performer_committed"
              )) ||
            (el.status === "waiting_for_performer_commit" &&
              el.contracts.length > 0 &&
              el.contracts.some(
                (el: any) => el.status === "performer_committed"
              ))
          ) {
            tags.unshift({
              text: "Ожидает подтверждения выполнения",
              type: "await",
            });
          }

          if (
            el.status === "done" &&
            el.contracts.length > 0 &&
            el.max_performer_amount === el.contracts.length &&
            el.contracts.every((el: any) => el.status === "approved")
          ) {
            tags.unshift({
              text: "Исполнен",
              type: "done",
            });
          }

          // EXPIRE
          if (now + 86400000 > expire) {
            tags.unshift({
              text: "Истекает через 1 день",
              type: "expires",
            });
          } else if (now + 172800000 > expire) {
            tags.unshift({
              text: "Истекает через 2 дня",
              type: "expires",
            });
          } else if (now + 259200000 > expire) {
            tags.unshift({
              text: "Истекает через 3 дня",
              type: "expires",
            });
          }

          return (
            <WishTile
              key={el.uuid}
              className="wishlist-paginator__tile"
              title={el.title}
              avatar="disabled"
              name=""
              tags={tags}
              hashtags={[]}
              // REASON
              rejectionReason={el.rejection_reason}
              // BUTTONS
              btnDetailLink={`/profile/wishes/${el.uuid}`}
              isRemoveble={
                el.status === "on_verification" ||
                (el.status === "published" && el.contracts.length === 0) ||
                el.status === "declined"
              }
              // ACTIONS
              onDelete={() => {
                dispatch(action.mWishRemoveSet(el.uuid));
              }}
              btnAcceptDisabledShow={
                (el.status === "waiting_for_performer_commit" &&
                  el.contracts.length > 0 &&
                  !el.contracts.some(
                    (el: any) => el.status === "performer_committed"
                  )) ||
                (el.status === "published" &&
                  el.contracts.length > 0 &&
                  el.contracts.every(
                    (el: any) => el.status !== "performer_committed"
                  )) ||
                (el.status === "done" &&
                  el.contracts.length > 0 &&
                  el.contracts.every(
                    (el: any) => el.status !== "performer_committed"
                  ) &&
                  el.contracts.some((el: any) => el.status === "new"))
              }
              btnAcceptShow={
                (el.status === "published" &&
                  el.contracts.length > 0 &&
                  el.contracts.some(
                    (el: any) => el.status === "performer_committed"
                  )) ||
                (el.status === "done" &&
                  el.contracts.length > 0 &&
                  el.contracts.some(
                    (el: any) => el.status === "performer_committed"
                  )) ||
                (el.status === "waiting_for_performer_commit" &&
                  el.contracts.length > 0 &&
                  el.contracts.some(
                    (el: any) => el.status === "performer_committed"
                  )) ||
                (el.status === "waiting_for_proof_of_work" &&
                  el.contracts.length > 0 &&
                  el.contracts.some(
                    (el: any) => el.status === "performer_committed"
                  ))
              }
              onAcceptClick={() => dispatch(action.mWishAcceptSet(el.uuid))}
            />
          );
        })}
      </div>
    </>
  );
};

export const SectionProfileWishes = () => {
  const dispatch = useDsp();
  const loading = useSlc((state: RootState) => state.loaderProfileWishes);
  const data = useSlc((state: RootState) => state.dataProfileWishes);
  const filter = useSlc((state: RootState) => state.filterProfileWishes);
  const notifyList: any = useSlc((state: RootState) => state.notifyList) || [];

  const wishReadIds = notifyList
    .filter((item: any) => item.unread)
    .map((item: any) => item.referenceable.uuid);

  const dataNew: any = [];
  const dataOld: any = [];

  data.map((wish: any) => {
    if (wishReadIds.includes(wish.uuid)) dataNew.push(wish);
    else dataOld.push(wish);
  });

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  if (data.length > 0)
    return (
      <section
        className="profile-wishes profile-wishes--wishes bg-decoration"
        role="sectionProfileWishes"
      >
        <div className="bg-decoration__wishes" />
        <div className="profile-wishes__wrapper">
          <div className="profile__wishes">
            <div className="profile__card-icon">
              <IconStaf />
            </div>
            <h2 className="profile__card-title">Мои добрые дела</h2>
            <p className="profile__card-subtitle"></p>
            <Button
              className="w-100 mt-3"
              type="button"
              onClick={() => dispatch(action.mWishCreateShow())}
            >
              Начать
            </Button>
          </div>

          <div className="profile-wishes__contaiter">
            <FormProfileWishesFilter />

            {dataNew.length > 0 && (
              <WishList
                title="Новый статус"
                data={dataNew}
                filter={filter}
                dispatch={dispatch}
              />
            )}

            {dataOld.length > 0 && (
              <WishList
                title="Дело без изменений"
                data={dataOld}
                filter={filter}
                dispatch={dispatch}
              />
            )}
          </div>
        </div>
      </section>
    );

  return (
    <section className="empty-notice bg-decoration">
      <div className="bg-decoration__wrapper" />
      <div className="empty-notice__container">
        <picture className="empty-notice__image">
          <source
            type="image/png"
            srcSet={ImageMobileEmpty}
            media="(max-width: 575px)"
          />
          <source type="image/png" srcSet={ImageEmpty} />
          <img src={ImageEmpty} alt="" />
        </picture>
        <div className="empty-notice__data">
          <h2 className="empty-notice__title">Здесь пока пусто</h2>
          <p className="empty-notice__subtitle">
            Добавь свое доброе дело, или расскажи о желании коллегам.
          </p>
        </div>
        <div className="empty-notice__buttons">
          <Button
            type="button"
            onClick={() => dispatch(action.mWishCreateShow())}
          >
            Начать
          </Button>
          <Link to="/wishlists" className="empty-notice__link">
            <span>Добрые дела</span>
            <IconArrowRight />
          </Link>
        </div>
      </div>
    </section>
  );
};
