import React, { FC } from "react";
import CategoryTile from "components/categoryTile/CategoryTile";
import Headline from "components/headline/Headline";
import { path } from "ramda";

const data = [
  {
    uuid: "sotsialnaya-aktivnost",
    title: "Социальная<br>активность",
    description:
      "Предложи свое доброе дело. Подержи приют, устрой субботник, навести бабушку",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/icon_cat_01.png`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/icon_cat_01.png`,
    url: "/wishlists/sotsialnaya-aktivnost",
  },
  {
    uuid: "sobyitiya-kruzhka",
    title: "События<br>Кружка",
    description:
      "Участвуй в федеральных волонтёрских проектах. Делай большое дело вместе с Кружком",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/icon_cat_02.png`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/icon_cat_02.png`,
    url: "/wishlists/sobyitiya-kruzhka",
  },
  {
    uuid: "korporativnoe-volontyorstvo",
    title: "Корпоративное<br>волонтёрство",
    description:
      "Исполняй желания коллег, делись своими знаниями, опытом и&nbsp;эмоциями",
    imageMobile: `${process.env.PUBLIC_URL}/assets/images/icon_cat_03.png`,
    imageDesktop: `${process.env.PUBLIC_URL}/assets/images/icon_cat_03.png`,
    url: "/wishlists/korporativnoe-volontyorstvo",
  },
  // {
  //   uuid: "dobro",
  //   title: "Добро",
  //   imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_dobro_v2.svg`,
  //   imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_dobro.svg`,
  //   url: "/wishlists/good",
  // },
  // {
  //   uuid: "razvlecheniya",
  //   title: "Развлечения",
  //   imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_razvlecheniya_v2.svg`,
  //   imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_razvlecheniya.svg`,
  //   url: "/wishlists/entertain",
  // },
  // {
  //   uuid: "dom",
  //   title: "Дом",
  //   imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_dom_v2.svg`,
  //   imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_dom.svg`,
  //   url: "/wishlists/home",
  // },
  // {
  //   uuid: "pitomtsyi",
  //   title: "Питомцы",
  //   imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_pitomcy_v2.svg`,
  //   imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_pitomcy.svg`,
  //   url: "/wishlists/pet",
  // },
  // {
  //   uuid: "tvorchestvo",
  //   title: "Творчество",
  //   imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_tvorchestvo_v2.svg`,
  //   imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_tvorchestvo.svg`,
  //   url: "/wishlists/creation",
  // },
  // {
  //   uuid: "drugoe",
  //   title: "Другое",
  //   imageMobile: `${process.env.PUBLIC_URL}/assets/images/cat_drugoe_v2.svg`,
  //   imageDesktop: `${process.env.PUBLIC_URL}/assets/images/cat_drugoe.svg`,
  //   url: "/wishlists/other",
  // },
];

const QuickCategories = ({ catalog }: any) => (
  <div className="quick--categories">
    <div className="container">
      <Headline className="headline_lg quick--categories__headline">
        Какие бывают добрые дела
      </Headline>
      <div className="quick--categories__list">
        {data.map((el) => {
          const count: number = path([el.uuid, "total"], catalog) || 0;
          return (
            <CategoryTile
              key={el.uuid}
              title={el.title}
              description={el.description}
              imageMobile={el.imageMobile}
              imageDesktop={el.imageDesktop}
              url={el.url}
              count={count}
            />
          );
        })}
      </div>
    </div>
  </div>
);

export default QuickCategories;
