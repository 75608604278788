import React, { FC } from "react";
import clsx from "clsx";
import { ReactComponent as IconAwait } from "../../assets/images/await.svg";
import { ReactComponent as IconDone } from "../../assets/images/done.svg";
import { ReactComponent as IconExpires } from "../../assets/images/expires.svg";
import { ReactComponent as IconInfo } from "../../assets/images/icon-info.svg";

type props = {
  className?: string;
  type?: "await" | "done" | "expires" | "rejected" | undefined;
  text?: string;
  rejectionReason?: null | string;
};

const CardTag: FC<props> = (props) => {
  const classes = clsx("card--tag", props.className, {
    _await: props.type === "await",
    _done: props.type === "done",
    _expires: props.type === "expires",
  });

  return (
    <div className={classes}>
      {props.type && props.type === "await" && <IconAwait />}
      {props.type && props.type === "done" && <IconDone />}
      {props.type && props.type === "expires" && <IconExpires />}
      {props.type && props.type === "rejected" && <IconInfo />}
      <span>{props.text}</span>
      {props.rejectionReason && (
        <div className="card--tag__reasen-popover">
          <div className="popover-body">{props.rejectionReason}</div>
        </div>
      )}
    </div>
  );
};

export default CardTag;
