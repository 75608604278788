const SUPPORTED_IMAGE_FORMATS = [
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/svg",
  "image/svg+xml",
];

const MIME_EXTENSIONS = ["jpg", "gif", "png", "svg"];

export function imageScaled(
  fileOrBlob: any,
  maxWidth: any,
  maxHeight: any,
  maxSize: any,
  forceSquare: any
) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onerror = function (err) {
      reject(new Error("Image format unrecognized"));
    };
    img.onload = async function () {
      // Once the image is loaded, the URL is no longer needed.
      URL.revokeObjectURL(img.src);

      // Calculate the desired image dimensions.
      const dim = fitImageSize(
        img.width,
        img.height,
        maxWidth,
        maxHeight,
        forceSquare
      );
      if (!dim) {
        reject(new Error("Invalid image"));
        return;
      }
      let canvas: any = document.createElement("canvas");
      canvas.width = dim.dstWidth;
      canvas.height = dim.dstHeight;
      let ctx: any = canvas.getContext("2d");
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(
        img,
        dim.xoffset,
        dim.yoffset,
        dim.srcWidth,
        dim.srcHeight,
        0,
        0,
        dim.dstWidth,
        dim.dstHeight
      );

      const mime = SUPPORTED_IMAGE_FORMATS.includes(fileOrBlob.type)
        ? fileOrBlob.type
        : "image/jpeg";
      // Generate blob to check size of the image.
      // eslint-disable-next-line no-promise-executor-return
      // let blob: any = await new Promise((resolve) =>
      //   // eslint-disable-next-line no-promise-executor-return
      //   canvas.toBlob(resolve, mime)
      // );

      canvas.toBlob((blob: any) => {
        if (!blob) {
          reject(new Error("Unsupported image format"));
        }

        // canvas = null;
        // console.log("-- HELPER", {
        //   mime: mime,
        //   blob: blob,
        //   width: dim.dstWidth,
        //   height: dim.dstHeight,
        //   name: fileNameForMime(fileOrBlob.name, mime),
        // });

        resolve({
          mime: mime,
          blob: blob,
          width: dim.dstWidth,
          height: dim.dstHeight,
          name: fileNameForMime(fileOrBlob.name, mime),
        });
      }, mime);

      // Ensure the image is not too large. Shrink the image keeping the aspect ratio.
      // Do nothing if maxsize is <= 0.
      // while (maxSize > 0 && blob.length > maxSize) {
      //   // eslint-disable-next-line no-bitwise
      //   dim.dstWidth = (dim.dstWidth * 0.70710678118) | 0;
      //   // eslint-disable-next-line no-bitwise
      //   dim.dstHeight = (dim.dstHeight * 0.70710678118) | 0;
      //   canvas.width = dim.dstWidth;
      //   canvas.height = dim.dstHeight;
      //   ctx = canvas.getContext("2d");
      //   ctx.clearRect(0, 0, canvas.width, canvas.height);
      //   ctx.drawImage(
      //     img,
      //     dim.xoffset,
      //     dim.yoffset,
      //     dim.srcWidth,
      //     dim.srcHeight,
      //     0,
      //     0,
      //     dim.dstWidth,
      //     dim.dstHeight
      //   );
      //   // eslint-disable-next-line no-await-in-loop, no-loop-func, no-promise-executor-return
      //   blob = await new Promise((resolve) => canvas.toBlob(resolve, mime));
      // }
    };
    img.src = URL.createObjectURL(fileOrBlob);
  });
}

// Calculate linear dimensions for scaling image down to fit under a certain size.
// Returns an object which contains destination sizes, source sizes, and offsets
// into source (when making square images).
export function fitImageSize(
  width: any,
  height: any,
  maxWidth: any,
  maxHeight: any,
  forceSquare: any
) {
  // Sanitize input
  // eslint-disable-next-line no-bitwise, no-param-reassign
  width |= 0;
  // eslint-disable-next-line no-bitwise, no-param-reassign
  height |= 0;
  // eslint-disable-next-line no-bitwise, no-param-reassign
  maxWidth |= 0;
  // eslint-disable-next-line no-bitwise, no-param-reassign
  maxHeight |= 0;

  if (width <= 0 || height <= 0 || maxWidth <= 0 || maxHeight <= 0) {
    return null;
  }

  if (forceSquare) {
    // eslint-disable-next-line no-bitwise, no-param-reassign, no-multi-assign
    maxWidth = maxHeight = Math.min(maxWidth, maxHeight);
  }

  const scale = Math.min(
    Math.min(width, maxWidth) / width,
    Math.min(height, maxHeight) / height
  );

  const size: any = {
    // eslint-disable-next-line no-bitwise
    dstWidth: (width * scale) | 0,
    // eslint-disable-next-line no-bitwise
    dstHeight: (height * scale) | 0,
  };

  if (forceSquare) {
    // Also calculate parameters for making the image square.
    // eslint-disable-next-line no-multi-assign
    size.dstWidth = size.dstHeight = Math.min(size.dstWidth, size.dstHeight);
    // eslint-disable-next-line no-multi-assign
    size.srcWidth = size.srcHeight = Math.min(width, height);
    // eslint-disable-next-line no-bitwise
    size.xoffset = ((width - size.srcWidth) / 2) | 0;
    // eslint-disable-next-line no-bitwise
    size.yoffset = ((height - size.srcWidth) / 2) | 0;
  } else {
    // eslint-disable-next-line no-multi-assign
    size.xoffset = size.yoffset = 0;
    size.srcWidth = width;
    size.srcHeight = height;
  }
  return size;
}

// Ensure file's extension matches mime content type
export function fileNameForMime(fname: string, mime: string) {
  const idx = SUPPORTED_IMAGE_FORMATS.indexOf(mime);
  if (idx < 0 || !fname) {
    // Unknown mime or empty name.
    return fname;
  }
  const ext = MIME_EXTENSIONS[idx];

  const at = fname.lastIndexOf(".");
  if (at >= 0) {
    // eslint-disable-next-line no-param-reassign
    fname = fname.substring(0, at);
  }
  return fname + "." + ext;
}

// Convert Blob to base64 string. Returns a promise resolved with the base64 string and mime.
export function blobToBase64(blob: any) {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    // reader.onerror = (_) => {
    //   reject(reader.error);
    // };
    reader.onload = () => {
      resolve({ mime: blob.type, bits: reader.result.split(",")[1] });
    };
    reader.readAsDataURL(blob);
  });
}

export default {
  imageScaled,
  blobToBase64,
};

// Convert a base64 encoded string with the provided mime type into a Blob.
export function base64ToBlob(str: any, mime: any) {
  if (!str) {
    return null;
  }

  try {
    // Make blob.
    const bin = atob(str);
    const { length } = bin;
    const buf = new ArrayBuffer(length);
    const arr = new Uint8Array(buf);
    for (let i = 0; i < length; i += 1) {
      arr[i] = bin.charCodeAt(i);
    }

    return new Blob([buf], { type: mime });
  } catch (err) {
    console.error("Failed to convert base64 to blob: ", err);
  }

  return null;
}
