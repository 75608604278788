import React, { FC } from "react";
import { ModalBasic } from "components/modal";
import { action, useDsp, useSlc, RootState } from "app/store";
import { WrapperOrganizerHelp } from "app/wrappers";

export const ModalOrganizerHelp: FC = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mOrganizerHelp)}
      onHide={() => dispatch(action.mOrganizerHelpHide())}
      title="Обращение к организатору"
    >
      <WrapperOrganizerHelp />
    </ModalBasic>
  );
};
