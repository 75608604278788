import React from "react";
import { action, useDsp, useSlc, RootState } from "app/store";
import { Chat } from "../chat/chat";

export const ChatExtend = ({ chatSecret, chatTopic, update }: any) => {
  const chatExtendIsActive = useSlc((state: RootState) => state.chatExstend);
  const dispatch = useDsp();
  const chatShow = () => {
    document.body.style.overflow = "hidden";
    dispatch(action.chatExstendShow());
  };

  if (chatExtendIsActive) {
    return (
      <div className="chat-extend">
        {chatSecret && chatTopic && (
          <Chat
            variant="extend"
            chatSecret={chatSecret}
            chatTopic={chatTopic}
            update={update}
            willConnect
          />
        )}
      </div>
    );
  }

  return (
    <button
      className="chat-extend chat-extend-min"
      type="button"
      onClick={chatShow}
    >
      {chatSecret && chatTopic && (
        <Chat
          variant="extend"
          chatSecret={chatSecret}
          chatTopic={chatTopic}
          update={update}
          willConnect
        />
      )}
      <div className="btn-reset chat-widget__name">Общий чат</div>
    </button>
  );
};
