import React, { useState, useEffect } from "react";
import { apiGetArticle } from "app/api";
import { useParams } from "react-router-dom";
import { SectionArticle } from "blocks";
import RedirectTo from "components/RedirectTo/RedirectTo";

export const WrapperArticle = () => {
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const { id }: any = useParams() || "";

  useEffect(() => {
    const success = ({ data }: any) => {
      if (data && data.id && data.title && data.content) {
        setArticle(data);
        setLoading(false);
      } else {
        setNotFound(true);
        setLoading(false);
      }
    };
    const unsuccess = (error: any) => {
      console.log(error);
      setNotFound(true);
      setLoading(false);
    };
    apiGetArticle(id, success, unsuccess);
  }, []);

  if (notFound) return <RedirectTo path="/page404" />;
  return <SectionArticle loading={loading} article={article} />;
};
