import React from "react";
import { Form } from "components/form";
import { InputText, Select } from "components/input";
import { Button } from "components/button";

type props = {
  themes: any;
  disabled: boolean;
};

export const FormFeedback = ({ themes, disabled }: props) => (
  <Form className="form">
    <div className="row">
      <div className="col-12 mb-3">
        <InputText
          label="Имя"
          name="name"
          placeholder="Введи имя"
          role="formFeedbackName"
          disabled={disabled}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-12 mb-3">
        <InputText
          label="E-mail"
          name="email"
          placeholder="Введи Email"
          role="formFeedbackEmail"
          disabled={disabled}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-12 mb-3">
        <Select
          name="theme"
          label="Тема"
          placeholder="Выбери тему"
          id="formRegCity"
          options={themes}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-12 mb-3">
        <InputText
          label="Описание"
          name="message"
          placeholder="Добавь описание"
          role="formFeedbackMessage"
        />
      </div>
    </div>

    <Button type="submit" className="mx-auto mt-3" role="formRegSubmitButton">
      Отправить
    </Button>
  </Form>
);
