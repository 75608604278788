import { urls } from "app/constants";
import { dtoReg } from "app/utils/dto";
import { axiosBase } from "./_api";
import { path } from "ramda";

export const apiReg = (
  params: any,
  success: any,
  unsuccess: any,
  setErrors: any
) => {
  axiosBase({
    url: urls.reg,
    method: "post",
    data: dtoReg(params),
  })
    .then(success)
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        // console.log("API REG ERROR 400", error);

        let fieldErr: any = path(["response", "data", "errors"], error) || {};

        if (fieldErr.email) {
          fieldErr.email = fieldErr.email.map((e: string) => {
            if (e === "unique") return "Email уже зарегистрирован";
            return "n";
          });
        }

        if (fieldErr.name) {
          fieldErr.name = fieldErr.name.map((e: string) => {
            if (e === "blank") return "Введи имя";
            return "n";
          });
        }

        if (fieldErr.personnel_number) {
          fieldErr.prNumber = fieldErr.personnel_number.map((e: string) => {
            if (e === "does_not_exist")
              return "Этот табельный номер не найден или уже используется";
            return "n";
          });
        }

        if (fieldErr.password) {
          fieldErr.password = fieldErr.password.map((e: string) => {
            if (e === "password_too_common") return "Пароль слишком простой";
            return "n";
          });
        }

        // console.log(fieldErr);
        setErrors(fieldErr);
      }
      return unsuccess(error);
    });
};
