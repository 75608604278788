import React, { FC } from "react";
import { ModalBasic } from "components/modal/";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { WrapperWishRefuse } from "app/wrappers";

export const ModalWishRefuse: FC = () => {
  const dispatch = useDsp();
  const uuid = useSlc((state: RootState) => state.mWishRefuse);
  return (
    <ModalBasic
      show={!!uuid}
      onHide={() => dispatch(action.mWishRefuseSet(""))}
      title={
        <span>
          Ты уверен, что хочешь <br />
          отказаться выполнять дело?
        </span>
      }
    >
      <WrapperWishRefuse />
    </ModalBasic>
  );
};
