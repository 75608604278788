import React, { FC } from "react";
import { ModalBasic } from "components/modal/";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { WrapperWishDelete } from "app/wrappers";

export const ModalWishRemove: FC = () => {
  const dispatch = useDsp();
  const uuid = useSlc((state: RootState) => state.mWishRemove);
  return (
    <ModalBasic
      show={!!uuid}
      onHide={() => dispatch(action.mWishRemoveSet(""))}
      title={
        <span>
          Ты уверен, что хочешь <br />
          удалить дело?
        </span>
      }
    >
      <WrapperWishDelete />
    </ModalBasic>
  );
};
