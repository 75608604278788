import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { apiGalleryDelete } from "app/api/_gallery";
import { store, action, useDsp, useSlc } from "app/store";
import { ReactComponent as IconDelete } from "assets/images/icon-delete.svg";

export const RenderUserPhoto = ({ imageProps }: any) => {
  const {
    title,
    src,
    alt,
    srcSet,
    sizes,
    className,
    onClick,
    ...restImageProps
  } = imageProps;

  return (
    <div
      className="gallery__image-wrapper"
      {...(srcSet ? { srcSet, sizes } : null)}
      {...restImageProps}
    >
      <LazyLoadImage
        effect="blur"
        className={`gallery__image ${className}`}
        src={src}
        placeholderSrc={title}
        onClick={onClick}
        {...(srcSet ? { srcSet, sizes } : null)}
        {...restImageProps}
      />
    </div>
  );
};

export const RenderPhoto = ({ imageProps }: any) => {
  const dispatch = useDsp();
  const {
    title,
    src,
    alt,
    srcSet,
    sizes,
    className,
    onClick,
    ...restImageProps
  } = imageProps;

  const removeSuccess = (result: any, id: string) => {
    if (result && result.status === 204) {
      console.log("seccess");
      let newList = store.getState().galleryList;
      newList = newList.filter((img: any) => img.id !== id);
      dispatch(action.galleryListSet(newList));
    }
  };

  return (
    <div
      className="gallery__image-wrapper"
      {...(srcSet ? { srcSet, sizes } : null)}
      {...restImageProps}
    >
      <LazyLoadImage
        effect="blur"
        className={`gallery__image ${className}`}
        src={src}
        placeholderSrc={title}
        onClick={onClick}
        {...(srcSet ? { srcSet, sizes } : null)}
        {...restImageProps}
      />
      <button
        className="btn-reset gallery__remove-button"
        type="button"
        onClick={() => {
          console.log("xxxxxxxxxxx xxx", alt);
          apiGalleryDelete(
            alt,
            (result: any) => removeSuccess(result, alt),
            () => {
              console.log("error");
            }
          );
        }}
      >
        <IconDelete />
      </button>
    </div>
  );
};
