import React, { useEffect } from "react";
import { apiWishPaginator, apiBirthPaginator, apiWishPerform } from "app/api";
import { useSlc, action, useDsp, RootState } from "app/store";
import { useLocation, useNavigate } from "react-router-dom";
import { SectionWishPaginator } from "blocks/wishPaginator";

type apiParameters = {
  city: string;
  name: string;
  page: number;
  perPage: string;
};

export const WrapperWishPaginator = () => {
  const dispatch = useDsp();
  const navigate = useNavigate();
  const currLink = useLocation().pathname;

  let slug = "";
  if (currLink === "/wishlists/sotsialnaya-aktivnost")
    slug = "sotsialnaya-aktivnost";
  if (currLink === "/wishlists/sobyitiya-kruzhka") slug = "sobyitiya-kruzhka";
  if (currLink === "/wishlists/korporativnoe-volontyorstvo")
    slug = "korporativnoe-volontyorstvo";
  // if (currLink === "/wishlists/travel") slug = "puteshestviya";
  // if (currLink === "/wishlists/good") slug = "dobro";
  // if (currLink === "/wishlists/home") slug = "dom";
  // if (currLink === "/wishlists/pet") slug = "pitomtsyi";
  // if (currLink === "/wishlists/creation") slug = "tvorchestvo";
  // if (currLink === "/wishlists/other") slug = "drugoe";
  // if (currLink === "/wishlists/birthday") slug = "birthday";

  const data: any = useSlc((state: RootState) => state.wishPaginator) || {};
  const filterCity = useSlc((state: RootState) => state.fWishListsCity);
  const filterName = useSlc((state: RootState) => state.fWishListsName);
  const filterCount = useSlc((state: RootState) => state.fWishListsCount);
  const pagination = useSlc((state: RootState) => state.fWishPagination);

  const params: apiParameters = {
    city: filterCity.value,
    name: filterName.value,
    page: pagination.page,
    perPage: filterCount.value,
  };

  const performWish = (uuid: string) => {
    apiWishPerform(uuid, dispatch, navigate);
  };

  const onPaginatorClick = (obj: any) => {
    dispatch(action.fWishPaginationSet(obj));
  };

  const apiSuccess = ({ status, data }: any, params: any) => {
    // console.log("API WISH PAGINATOR SUCCESS", data);
    if (status === 200 && data) {
      dispatch(action.wishPaginatorDataSet(data));
      dispatch(
        action.fWishPaginationSet({
          page: params.page || 1,
          count: data.count,
        })
      );
      dispatch(action.loaderWishListsHide());
    }
  };

  const apiError = (err: any) => {
    // console.log("API WISH PAGINATOR ERROR", err);
    dispatch(action.wishPaginatorDataSet({}));
    dispatch(action.loaderWishListsHide());
  };

  useEffect(() => {
    if (slug === "birthday") {
      apiBirthPaginator(
        params,
        (data: any) => apiSuccess(data, params),
        apiError
      );
    } else {
      apiWishPaginator(
        slug,
        params,
        (data: any) => apiSuccess(data, params),
        apiError
      );
    }

    dispatch(action.loaderWishListsShow());
  }, [
    slug,
    filterCity.value,
    filterName.value,
    filterCount.value,
    pagination.page,
  ]);

  return (
    <SectionWishPaginator
      data={data.results || []}
      performWish={performWish}
      filterCount={parseInt(filterCount.value, 10)}
      pagination={pagination}
      onPaginatorClick={onPaginatorClick}
    />
  );
};
