import React from "react";
import { Scrollbar } from "components/scrollbar/";

type props = {
  city: string;
  birthDay: string;
  description: string;
};

export const FormProfileSomeUser = ({ city, birthDay, description }: props) => (
  <div className="form-profile-user" role="formProfileUser">
    <div className="row mt-4">
      <div className="col-7">
        <div className="form-group">
          <label className="form-label">Город</label>
          <div className="form-select-edit__pseudo-input form-select-edit__control">
            {city}
          </div>
        </div>
      </div>
      <div className="col-5">
        <div className="form-group">
          <label className="form-label">День рождения</label>
          <div className="form-select-edit__pseudo-input form-select-edit__control">
            {birthDay}
          </div>
        </div>
      </div>
    </div>

    <div className="row mt-3">
      {/* <div className="form-group">
        <textarea
          className="form-control form-profile-user__textarea"
          disabled
          value={description}
        />
      </div> */}
      <div className="form-group">
        <label className="form-label">О себе</label>
        <div className="form-control-relative-wrapper form-control-relative-scroller">
          <Scrollbar>
            <p
              className="form-control form-control-template form-profile-user__textarea"
              style={{ opacity: 1, border: 0 }}
            >
              {description}
            </p>
            {/* <textarea
              style={{ position: "absolute", height: "100%" }}
              className="form-control form-control-repeater form-profile-user__textarea form-control"
              disabled
              value={description}
            /> */}
          </Scrollbar>

          {/* <pre className="form-control form-control-repeater form-profile-user__textarea form-control">
            {description}
          </pre> */}
        </div>
      </div>
    </div>
  </div>
);
