import React from "react";
import { store, action, useDsp, useSlc, RootState } from "app/store";
import { ReactComponent as IconDelete } from "assets/images/icon-delete.svg";

type props = {
  onChange: any;
  data: string;
  onReset: any;
  error: string;
  onSubmit: any;
};

const baseurl = process.env.REACT_APP_API_BASE_URL;

export const FormWishDone = ({
  onChange,
  data,
  onReset,
  error,
  onSubmit,
}: props) => (
  <form className="form form-wish-done">
    <label className="button label">
      <span>Загрузить фотографию</span>
      <input
        className="input"
        type="file"
        multiple={false}
        onChange={onChange}
        accept=".png, .jpg, .jpeg"
      />
    </label>
    <span className="description">
      Допустимый формат и размер файла для загрузи:
      <br /> .jpeg, .jpg, .png / не более 20 мб.
    </span>

    {data && !error && (
      <>
        <div className="preview">
          <img src={data} alt="" />
          <button type="button" onClick={onReset}>
            <IconDelete />
          </button>
        </div>

        <button className="button submit" type="button" onClick={onSubmit}>
          Подтвердить
        </button>
      </>
    )}

    {error && <div className="error">{error}</div>}
  </form>
);
