import React from "react";
import { store, useSlc, action, useDsp, RootState } from "app/store";
import { BSform } from "components/form";
import { ReactComponent as IconSearch } from "assets/images/icon-search.svg";
import clsx from "clsx";

type props = {
  className?: string;
  name: string;
  placeholder: string;
  role?: string;
  onSearchClick?: any;
  inputData: string;
  setInputData: any;
};

export const InputFilter = ({
  name,
  placeholder,
  className,
  onSearchClick,
  inputData,
  setInputData,
}: props) => (
  <div className="form-group">
    <div className="form-control-relative-wrapper">
      <BSform.Control
        className={clsx("form-control", className)}
        name={name}
        type="text"
        placeholder={placeholder}
        // defaultValue={filter.value}
        value={inputData}
        onInput={(e: any) => {
          // console.log("INPUT FILTER TEXT", e.target.value);
          const text = e.target.value || "";
          setInputData(text);
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onSearchClick();
          }
        }}
        // onBlur={() => {
        //   console.log("BLER", inputData);
        //   dispatch(action.fWishListsNameSet({ value: inputData.trim() }));
        // }}
      />

      <button
        className="btn-reset form-control-search-button"
        type="button"
        onClick={() => {
          if (onSearchClick) onSearchClick();
        }}
      >
        <IconSearch />
      </button>
    </div>
  </div>
);
