import React from "react";
import { FormWishListFilter } from "components/form";
import { ButtonWishListFilter } from "elements/button";
import Headline from "components/headline/Headline";
import WishTile from "components/wishTile/WishTile";
import { useLocation } from "react-router-dom";
import { useSlc, action, useDsp, RootState } from "app/store";
import { wishlistLinks } from "app/constants";
import { LoaderMini } from "components/loader";
import { Paginator } from "components/paginator";

type props = {
  data: {
    uuid: any;
    title: string;
    avatar: string;
    name: string;
    location: string;
    tags: any;
    hashtags: any;
  }[];
  performWish: any;
  filterCount: number;
  pagination: any;
  onPaginatorClick: (obj: any) => void;
};

export const SectionWishPaginator = ({
  data,
  performWish,
  filterCount,
  pagination,
  onPaginatorClick,
}: props) => {
  const dispatch = useDsp();
  const currLink = useLocation().pathname;
  const loading = useSlc((state: RootState) => state.loaderWishLists);
  const route = wishlistLinks.filter((link: any) => link.route === currLink);
  const title = route && route[0] ? route[0].title : "";
  const user = useSlc((state: RootState) => state.user);

  if (loading)
    return (
      <section className="profile-wishes">
        <LoaderMini />
      </section>
    );

  return (
    <section className="wishlists bg-decoration" role="sectionWishPaginator">
      <div className="bg-decoration__wishlist" />
      <div className="container">
        <ButtonWishListFilter />
        <FormWishListFilter counter className="filter-with-counter" />

        <div className="wishlist-paginator">
          <Headline className="headline_lg wishlist-paginator__title">
            {title}
          </Headline>

          <div className="wishlist-paginator__tile-list">
            {data.map((el: any) => {
              const cartTags = [{ text: el.category.name, type: "" }];
              if (el.sub_category === "small_deal")
                cartTags.push({ text: "0.5 баллов", type: "" });
              if (el.sub_category === "big_deal")
                cartTags.push({ text: "1 балл", type: "" });

              return (
                <>
                  {user.uuid !== el.author.uuid && (
                    <WishTile
                      key={el.uuid}
                      className="wishlist-paginator__tile"
                      title={el.title}
                      avatar={el.author.image}
                      name={`${el.author.name}`}
                      location={el.author.city.name}
                      tags={cartTags}
                      hashtags={el.tags}
                      // BUTTONS
                      btnDetailShow
                      btnActionShow
                      linkToProfile={`/user/${el.author.uuid}`}
                      // ACTIONS
                      onDetailClick={() => {
                        dispatch(
                          action.mWishDetailsDataSet({
                            uuid: el.uuid,
                            title: "Дело коллеги",
                            name: el.title,
                            description: el.description,
                            category: el.category.name,
                            city: el.cities.map((c: any) => c.name).join(" "),
                            maxPerformers: el.max_performer_amount,
                            status: el.status,
                          })
                        );
                        dispatch(action.mWishDetailsShow());
                      }}
                      onActionClick={() => performWish(el.uuid)}
                    />
                  )}

                  {user.uuid === el.author.uuid && (
                    <WishTile
                      key={el.uuid}
                      className="wishlist-paginator__tile"
                      title={el.title}
                      avatar={el.author.image}
                      name={`${el.author.name}`}
                      location={el.author.city.name}
                      tags={[{ text: el.category.name, type: "" }]}
                      hashtags={el.tags}
                      // BUTTONS
                      linkToProfile={`/user/${el.author.uuid}`}
                    />
                  )}
                </>
              );
            })}
          </div>
          <Paginator
            filterCount={filterCount}
            pagination={pagination}
            onPaginatorClick={onPaginatorClick}
          />
        </div>
      </div>
    </section>
  );
};
