import React from "react";
import imageMessage from "assets/images/image-chat-widget-message.svg";
import { action, useDsp, useSlc, RootState } from "app/store";
import { ReactComponent as IconClose } from "assets/images/icon-close.svg";

export const ChatWidget = () => {
  const chatExtendIsActive = useSlc((state: RootState) => state.chatExstend);
  const dispatch = useDsp();
  const chatShow = () => {
    document.body.style.overflow = "hidden";
    dispatch(action.chatExstendShow());
  };
  const chatHide = () => {
    document.body.style.overflow = "";
    dispatch(action.chatExstendHide());
  };
  return (
    <>
      {!chatExtendIsActive && (
        <button
          type="button"
          className="btn-reset chat-widget"
          onClick={chatShow}
        >
          <span className="chat-widget__name">Общий чат</span>
          <div className="chat-widget__hover">
            <img src={imageMessage} alt="" />
          </div>
        </button>
      )}
      {chatExtendIsActive && (
        <button
          type="button"
          className="btn-reset chat-widget__close-button"
          onClick={chatHide}
        >
          <IconClose />
        </button>
      )}
    </>
  );
};
