import { urls } from "app/constants";
// import { dtoFormikServerErr } from "app/utils/dto";
import { axiosSecure } from "./_api";

export const apiNotifyList = (page: number, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.notifyList,
    method: "get",
    params: { per_page: 30, page },
  })
    .then(success)
    .catch(unsuccess);
};

export const apiNotifyRead = (params: any, success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.notifyRead,
    method: "post",
    data: params,
  })
    .then(success)
    .catch(unsuccess);
};

export const apiNotifyCheckAll = (success: any, unsuccess: any) => {
  axiosSecure({
    url: urls.notifyCheckAll,
    method: "post",
  })
    .then(success)
    .catch(unsuccess);
};
