import React from "react";
import { ReactComponent as IconSmile } from "assets/images/icon-smile.svg";
import { ReactComponent as IconPlus } from "assets/images/icon-plus.svg";

export const AddSmile = ({ addSmile }: any) => (
  <>
    <button
      type="button"
      className="btn-reset chat-footer__smile-button"
      onClick={() => {
        const button = document.querySelector(
          ".chat-footer__smile-button"
        ) as HTMLElement;
        if (button) button.focus();
      }}
    >
      <IconSmile />
    </button>
    <div className="chat-footer__smiles">
      <div className="chat-footer__smiles-wrapper">
        {[
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
        ].map((sm: string, id: number) => (
          <button
            className="btn-reset"
            type="button"
            onClick={() => addSmile(sm)}
            key={id}
          >
            <img src={`/assets/images/smile${sm}.png`} alt="" />
          </button>
        ))}
      </div>
    </div>
  </>
);

export const AddMedia = ({ addImage, addFile }: any) => (
  <>
    <button
      type="button"
      className="btn-reset chat-footer__add-button"
      onClick={() => {
        const button = document.querySelector(
          ".chat-footer__add-button"
        ) as HTMLElement;
        if (button) button.focus();
      }}
    >
      <IconPlus />
    </button>
    <div className="chat-footer__files">
      <div className="chat-footer__files-wrapper">
        <label key="21453413">
          Фото
          <input
            type="file"
            id="chatInputImage"
            key="21453414"
            onInput={(event: any) => {
              // console.log("ON INPUT PHOTO");
              addImage(event);
              // eslint-disable-next-line no-param-reassign
              event.target.value = null;
            }}
            accept="image/jpg, image/jpeg, image/png, .heic"
          />
        </label>
        <label key="21453415">
          Файл
          <input
            type="file"
            id="chatInputFile"
            key="21453416"
            onInput={(event: any) => {
              // console.log("ON INPUT FILE");
              addFile(event);
              // eslint-disable-next-line no-param-reassign
              event.target.value = null;
            }}
            accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf"
          />
        </label>
      </div>
    </div>
  </>
);
