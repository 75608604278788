import React from "react";
import RSelect, { components } from "react-select";
import { Scrollbar } from "components/scrollbar/";
import { ReactComponent as IconDefault } from "assets/images/icon-select-arrow.svg";

type props = {
  prefix?: string;
  name: string;
  value?: any;
  defaultValue?: any;
  placeholder: string;
  options: any[];
  onChange?: any;
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <IconDefault />
  </components.DropdownIndicator>
);

const Menu = (props: any) => {
  const count = props.options.length;
  return (
    <>
      {/* {count < 4 && ( */}
      <components.Menu className="autoheight" {...props}>
        {props.children}
      </components.Menu>
      {/* )} */}
      {/* {count > 3 && (
        <components.Menu {...props}>
          <Scrollbar>{props.children}</Scrollbar>
        </components.Menu>
      )} */}
    </>
  );
};

export const SelectFilter = ({
  prefix = "form-select",
  name,
  placeholder,
  options,
  onChange,
  defaultValue,
}: props) => (
  <div className="form-group">
    <RSelect
      // menuIsOpen={true}
      name={name}
      placeholder={placeholder}
      className="form-select-filter-container"
      classNamePrefix={prefix}
      options={options}
      defaultValue={defaultValue}
      components={{ DropdownIndicator, Menu }}
      captureMenuScroll={false}
      menuShouldScrollIntoView={false}
      isSearchable={false}
      onChange={(e: any) => {
        console.log("--- FILTER E", e);
        if (onChange) onChange(e);
      }}
    />
  </div>
);
