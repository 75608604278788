import React from "react";
import { store } from "app/store";
import { Form, useFormikContext } from "components/form";
import { InputText, SelectEditCity, RedactDate } from "components/input";
import clsx from "clsx";

type props = {
  isCityEditable: boolean;
  isDateEditable: boolean;
  isDescEditable: boolean;
  isPhoneEditable: boolean;
  isTelegaEditable: boolean;
  //
  onCityEditClick: any;
  onDateEditClick: any;
  onDescEditClick: any;
  onPhoneEditClick: any;
  onTelegaEditClick: any;
};

export const FormProfileUser = ({
  isCityEditable,
  isDateEditable,
  isDescEditable,
  isPhoneEditable,
  isTelegaEditable,
  //
  onCityEditClick,
  onDateEditClick,
  onDescEditClick,
  onPhoneEditClick,
  onTelegaEditClick,
}: props) => {
  const { submitForm } = useFormikContext();

  const onCityClick = () => {
    if (isCityEditable) {
      console.log("ON CITY SUBMIT");
      setTimeout(() => submitForm(), 100);
    }
    onCityEditClick();
  };

  const onDateClick = () => {
    if (isDateEditable) {
      console.log("ON DATE SUBMIT");
      setTimeout(() => submitForm(), 100);
    }
    onDateEditClick();
  };

  const onDescClick = () => {
    if (isDescEditable) submitForm();
    else onDescEditClick();
  };

  // const onPhoneClick = () => {
  //   if (isPhoneEditable) submitForm();
  //   else onPhoneEditClick();
  // };

  // const onTelegaClick = () => {
  //   if (isPhoneEditable) submitForm();
  //   else onTelegaEditClick();
  // };

  const { user }: any = store.getState();

  return (
    <Form className="form-profile-user" role="formProfileUser">
      <div className="row mt-4">
        <div className="col-6">
          <SelectEditCity
            cssPrefix="form-select-edit"
            // label="Город"
            placeholder="Укажи город"
            name="city"
            id="formProfileUserCity"
            variant="edit"
            defaultValue={{
              value: user.city.uuid,
              label: user.city.name,
            }}
            disabled={!isCityEditable}
            onEditClick={onCityEditClick}
            onBlur={onCityClick}
          />
        </div>
        <div className="col-6">
          <RedactDate
            name="date"
            placeholder="День рождения"
            // label="День рождения"
            className="form-control form-profile-user__form-control"
            variant="edit"
            disabled={!isDateEditable}
            onEditClick={onDateClick}
            onBlur={onDateClick}
          />
        </div>
      </div>

      {/* <div className="row mt-4">
        <div className="col-6">
          <InputText
            variant="edit"
            className="form-control form-profile-user__form-control form-control--phone"
            placeholder="+7(XXX)XXX-XX-XX"
            mask="phone"
            name="phone"
            role="formProfileUserPhone"
            disabled={!isPhoneEditable}
            onEditClick={onPhoneClick}
            onBlur={submitForm}
          />
        </div>
        <div className="col-6">
          <InputText
            variant="edit"
            className="form-control form-profile-user__form-control form-control--telega"
            placeholder="@telegramName"
            name="telega"
            role="formProfileUserPhone"
            disabled={!isTelegaEditable}
            onEditClick={onTelegaClick}
            onBlur={submitForm}
          />
        </div>
      </div> */}

      <div className={clsx("row mt-3", isDescEditable && "--editable")}>
        <InputText
          as="textscroll"
          variant="edit"
          className="form-profile-user__textarea"
          // label="О себе"
          placeholder="Напиши несколько слов о себе: свои увлечения, что любишь, какие у тебя интересы."
          name="description"
          role="formProfileUserNumber"
          disabled={!isDescEditable}
          onEditClick={onDescClick}
          onBlur={submitForm}
        />
      </div>
    </Form>
  );
};
