import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";

export const ModalInfo = () => {
  const dispatch = useDsp();
  const data = useSlc((state: RootState) => state.mInfoData);
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mInfo)}
      onHide={() => dispatch(action.mInfoHide())}
      title={data.title}
    >
      <div className="modal-info__message">{data.message}</div>
    </ModalBasic>
  );
};
