export const avatarTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/heif",
];
export const avatarExts = [".jpeg", ".png", ".jpg", ".heic"];

export const galleryImgTypes = ["image/jpeg", "image/png", "image/jpg"];
export const galleryImgExts = ["jpeg", "png", "jpg", "heic"];
export const galleryFileSize = 10500000;

export const chatImgTypes = ["image/jpeg", "image/png", "image/jpg"];
export const chatImgExsts = ["jpeg", "png", "jpg", "heic"];
export const chatImgSize = 5300000;

export const chatFileExsts = [
  "jpg",
  "jpeg",
  "png",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "pdf",
];
export const chatFileSize = 5300000;

export const photoTypes = ["image/jpeg", "image/png", "image/jpg"];
export const photoExts = [".jpeg", ".png", ".jpg"];
export const photoFileSize = 20 * 1024 * 1024;
