export const messages = {
  name: {
    required: "Введи имя",
    format: "Используй кирилицу",
    toShort: "Минимальная длинна 2 символа",
    toLong: "Максимальная длинна 20 символов",
  },
  surname: {
    required: "Введи фамилию",
  },
  email: {
    exist: "E-mail уже зарегистрирован",
    required: "Введи личный e-mail",
    format: "Используй формат xxxxxxx@xxxxx.xx",
    forbidden: "Введи личный e-mail",
    toLong: "Максимальная длинна 128 символов",
  },
  prNumber: {
    required: "Введи номер",
    format: "Введи от 1 до 8 цифр",
  },
  citySelect: {
    required: "Выбери город",
    maxLimit: "Выбери не более 10 городов",
  },
  select: {
    minOne: "Выбери минимум 1 вариант",
    required: "Выбери из списка",
  },
  date: {
    required: "Введи дату",
    format: "Используй формат 1995-05-25",
  },
  password: {
    required: "Введи пароль",
    format: "Используй заглавные и строчные буквы и цифры",
    toShort: "Минимальная длинна 8 символов",
    toLong: "Максимальная длинна 40 символов",
    notMatch: "Пароли не совпадают",
  },
  passConfirm: {
    required: "Введи пароль еще раз",
  },
  description: {
    toShort: "Минимальная длинна 10 символов",
    toLong: "Максимальная длинна 500 символов",
    required: "Поле обязательно для заполнениея",
  },
  avatar: {
    overSize: "Картинка превышает 100кб",
    noType: "Используй картинку формата JPG или PNG",
    modalMessage:
      "Используй формат JPG, PNG, HEIF. Максимальный размер 5 Мегабайт",
  },
  wishName: {
    required: "Введи имя для дела",
    toShort: "Минимальная длинна 5 символов",
    toLong: "Максимальная длинна 50 символов",
  },
  wishDesc: {
    required: "Введи описание для дела",
    toShort: "Минимальная длинна 5 символов",
    toLong: "Максимальная длинна 600 символов",
  },
  wishTag: {
    required: "Введи хештег для дела",
    format: "Используй кирилицу",
    count: "Длинна тега от 3 до 30 букв",
    minOne: "Выбери минимум 1 хештег",
    maxLimit: "Выбери не более 10 хештегов",
  },
  phone: {
    format: "Формат +7(000)000-00-00",
  },
  telega: {
    format: "Формат @telegramName",
  },
  gallery: {
    toBig: "Размер каждого файла не более 5 МБ",
  },
  personal: {
    required: "Подтверди согласие на обработку персональных данных",
  },
  agreement: {
    required: "Подтверди согласие с пользовательским соглашением",
  },
  rules: {
    required: "Подтверди сщгласие с правилами платформы",
  },
  // OLD MESSAGES
  time: {
    format: "Use format 17:25",
  },
  user: {
    wrong: "Incorrect login or password",
  },
  file: {
    more10mb: "Your files have more then 10MB",
  },
  message: {
    required: "Опиши проблему",
    toBig: "Используй не более 1000 символов",
  },
  recaptcha: {
    required: "Пройди капчу",
  },

  photo: {
    error: "Фото не соответствует требованиям!",
  },

  required: "Это поле является обязательным!",
  requiredFirstName: "Введи имя",
  requiredLastName: "Введи фамилию",
  requiredEmail: "Введи личный e-mail",
  requiredPhone: "Введи номер телефона",
  requiredTheme: "Select one item",
  requiredMessage: "Введи сообщение",
  requiredPassword: "Введи пароль",

  toShort2: "Minimum 2 characters",
  enter16digits: "Check ID has 16 digits",
  toLong50: "Maximum 50 characters",
  useRussLetters: "Use letters aA - zZ",
};

export const galleryInfo =
  "Загрузи фото, используюя форматы JPG, PNG, HIEC. Общий размер загружаемых за раз фото не должен превышать 10 МБ. Если вы хотите загрузить больше фото, просто повторите действие.";
export const galleryError =
  "Загружай только JPG, PNG, HEIF файлы. Размер каждого файла до 10 МБ";

export const info = {
  reg: {
    title: "Письмо отправлено",
    message: "Для завершения регистрации пройди по ссылке в письме.",
  },
};

export const wishNotPerform = {
  title: "Ошибка!",
  message:
    "Невозможно исполнить этото дело. Он уже у тебя на исполнении или лимит исполнителей превышен.",
};
