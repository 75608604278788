import React, { useEffect } from "react";
import { action, useDsp } from "app/store";
import { apiProfilePerformers } from "app/api";
import { SectionProfilePerformers } from "blocks/profile";

export const WrapperProfilePerformers = () => {
  const dispatch = useDsp();

  const apiSuccess = ({ status, data }: any) => {
    console.log("API PROFILE PERFORMERS SUCCESS", data);
    if (status === 200 && data) {
      const wishes = data.results || [];
      dispatch(action.dataProfilePrfsSet(wishes));
      dispatch(action.loaderProfilePrfsSet(false));
    }
  };

  const apiError = (err: any) => {
    console.log("API PROFILE PERFORMERS ERROR", err);
    dispatch(action.loaderProfilePrfsSet(false));
  };

  useEffect(() => {
    apiProfilePerformers(apiSuccess, apiError);
    dispatch(action.loaderProfilePrfsSet(true));
  }, []);

  return <SectionProfilePerformers />;
};
