import React, { FC } from 'react'
import clsx from 'clsx'

type props = {
  className?: string,
  title: string,
  descr?: string,
}

const CardContent: FC<props> = (props) => {
  const classes = clsx('card--content', props.className);
  const classesTitle = clsx('card--content__title', {
    'card--content__title_sm': !props.descr
  });

  return (
    <div className={classes}>
      <div className={classesTitle}>{props.title}</div>
      {props.descr &&
        <div className="card--content__descr" dangerouslySetInnerHTML={{ __html: props.descr }}></div>
      }
    </div>
  )
}

export default CardContent;
