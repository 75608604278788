import React from "react";
import { ModalBasic } from "components/modal";
import type { RootState } from "app/store";
import { action, useDsp, useSlc } from "app/store";
import { Button } from "components/button";
import imageKruzokDesktop from "assets/images/image-kruzok-desktop@2x.jpg";
import imageKruzokMobile from "assets/images/image-kruzok-mobile@2x.jpg";

export const ModalKruzok = () => {
  const dispatch = useDsp();
  return (
    <ModalBasic
      show={useSlc((state: RootState) => state.mKruzok)}
      onHide={() => dispatch(action.mKruzokHide())}
      className="modal--intro"
      dialogClassName="modal-dialog--intro"
      contentClassName="modal-content--intro"
      bodyClassName="modal-body--intro"
      backdrop="static"
    >
      <>
        <picture>
          <source srcSet={imageKruzokDesktop} media="(min-width: 769px)" />
          <source srcSet={imageKruzokMobile} media="(min-width: 100px)" />
          <img
            className="swiper-intro__slide-top-image"
            src={imageKruzokDesktop}
            alt=""
          />
        </picture>
        <div className="modal-kruzok__data">
          <p>
            <b>«Кружок»</b> – это волонтерский проект МегаФона.
          </p>
          <p>
            Пространство, где каждый может помогать другим, делать добрые дела,
            поддерживать коллег, общаться и узнавать новое.
          </p>

          <p>
            Мы создали эту платформу, чтобы развивать кросс-функциональные
            связи, и с их помощью не только продуктивно работать, но и
            участвовать в жизни общества. Здесь каждый может помочь коллегам
            реализовать доброе дело или исполнить заветное желание. Возможности
            Кружка не ограничены: волонтерство, экология, обучение, общение или
            хобби – предлагай то, что важно и интересно тебе. Но помни: мы
            радуем друг друга в «Кружке» безвозмездно.
          </p>

          <p>
            Присоединяйся к «Кружку» и становись участником большого рейтинга.
            За каждое доброе дело мы начислим тебе баллы.
          </p>

          <p>
            <b>Социальная активность:</b>
            <br />
            2 балла – за создание доброго дела и участие в нем коллег
            <br />1 балл – за участие в добрых делах коллег
          </p>

          <p>
            <b>События Кружка:</b> <br />
            От 0,5 до 1 балла
          </p>

          <p>
            <b>Корпоративное волонтёрство:</b> <br />
            0,5 балла – за выполненное желание коллеги
          </p>

          <p>
            В конце каждого месяца топ-3 лидера проекта получат небольшие, но
            приятные подарки. А до 20 апреля 2025 года у тебя есть возможность
            побороться за право поехать в Сочи на финал футбольного турнира
            МегаФона «Будущее зависит от тебя». Все подробности конкурса –{" "}
            <a
              href="./assets/documents/rules.pdf"
              target="_blank"
              rel="noreferrer"
            >
              здесь.
            </a>
          </p>

          <p>
            Кружок – это не только название проекта, но и имя нашего главного
            героя. Он сам с радостью делает добрые дела и приглашает вас ему
            помочь.
          </p>
        </div>
      </>
    </ModalBasic>
  );
};
