import React from "react";
import RedirectTo from "components/RedirectTo/RedirectTo";
import type { RootState } from "app/store";
import { useSlc } from "app/store";
import { Link, useParams } from "react-router-dom";
import { FormProfileSomeUser } from "components/form";
import { useApiUserProfile, useApiFriendGallery } from "app/hooks";
import { ReactComponent as IconStaf } from "assets/images/32pt_staf.svg";
import { ReactComponent as IconHands } from "assets/images/32pt_hands.svg";
import { ReactComponent as IconGallery } from "assets/images/icon-gallery.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icon-arrow-right.svg";
import avatarDef from "assets/images/avatar-default-big.svg";
import { path } from "ramda";
import PhotoAlbum from "react-photo-album";
import { RenderUserPhoto } from "../../gallery/_renderPhoto";

const baseurl = process.env.REACT_APP_API_BASE_URL;

export const UserProfile = () => {
  const { isAuth } = useSlc((state: RootState) => state.user);
  const { id }: any = useParams() || "";
  const { data }: any = useApiUserProfile(id);
  let photos: any = useApiFriendGallery(id);
  photos = path(["data", "data"], photos) || [];
  if (photos.length > 3) photos = photos.slice(0, 3);
  const smallPhotos = photos.map(({ original, small }: any) => ({
    src: baseurl + original.url,
    width: original.width,
    height: original.height,
    title: baseurl + small.url,
  }));

  const name: string = path(["data", "name"], data) || "Аноним";
  const description: string =
    path(["data", "description"], data) ||
    "Твой коллега еще не указал иформацию о себе.";
  const city: string = path(["data", "city", "name"], data) || "не указан";
  const birthDay: string = path(["data", "date_birthday"], data) || "не указан";
  const wishesDone: number = path(["data", "completed_wishes"], data) || 0;
  const number: string =
    `${path(["data", "personnel_number", "number"], data)}` || "";
  const avatar: string = path(["data", "image"], data) || "";

  const birthDayMonth: string = birthDay
    .split("-")
    .slice(1)
    .reverse()
    .join(".");

  if (isAuth)
    return (
      <section className="profile bg-decoration" role="sectionProfile">
        <div className="bg-decoration__wrapper bg-decoration__wrapper_profile" />
        <div className="profile__wrapper">
          <div className="profile__user">
            <div className="row">
              <div className="col-4">
                <div className="profile__user-avatar">
                  <img src={avatar ? baseurl + avatar : avatarDef} alt="" />
                </div>
              </div>
              <div className="col-8">
                <div className="profile__user-name">{name}</div>
                {number && <p className="profile__user-number">№ {number}</p>}
              </div>
            </div>
            <div className="row">
              <FormProfileSomeUser
                city={city}
                birthDay={birthDayMonth}
                description={description}
              />
            </div>
          </div>

          <div className="profile__experience">
            <div className="profile__wishes">
              <div className="profile__card-icon">
                <IconStaf />
              </div>
              <h2 className="profile__card-title">Дела коллеги</h2>
              <p className="profile__card-subtitle">
                Посмотри, чем можно помочь!
              </p>
              <button
                className="btn-reset button-action-forward profile__card-button"
                type="button"
              >
                <Link to={`/user/wishlists/${id}`}>
                  <span>Смотреть дела</span>
                </Link>
                <IconArrowRight />
              </button>
            </div>

            <div className="profile__perform">
              <div className="profile__card-icon">
                <IconHands />
              </div>
              <h2 className="profile__card-title">Исполнил дел</h2>
              <p className="profile__card-subtitle">
                Коллега исполнил дел: <span>{wishesDone}</span>
              </p>
              <button
                className="btn-reset button-action-forward profile__card-button"
                type="button"
              >
                <Link to={`/user/wishlists_done/${id}`}>
                  <span>Смотреть дела</span>
                </Link>
                <IconArrowRight />
              </button>
            </div>

            <div className="profile__gallery">
              <div className="profile__gallery-row">
                <div className="profile__gallery-icon">
                  <IconGallery />
                </div>
                <div className="profile__gallery-header">
                  <h2 className="profile__gallery-title">Галерея</h2>
                  <p className="profile__gallery-subtitle">
                    Посмотри фотографии коллеги
                  </p>
                </div>
                <div className="btn-reset button-action-forward profile__gallery-button">
                  <Link to={`/gallery/${id}`}>
                    <span>Перейти в галерею</span>
                  </Link>
                  <IconArrowRight />
                </div>
              </div>
              {smallPhotos.length > 0 && (
                <div className="gallery__images gallery__images--profile">
                  <PhotoAlbum
                    layout="rows"
                    photos={smallPhotos}
                    renderPhoto={RenderUserPhoto}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  return <RedirectTo path="/sign-in" />;
};
